import React, { Component } from 'react';
import PropType from 'prop-types';
import ReasonForm from '../../components/ReasonForm';

class EditReasonScreen extends Component {
  componentDidMount() {
    const {
      fetchReasonById,
      match: {
        params: { reasonId },
      },
    } = this.props;
    fetchReasonById(reasonId);
  }

  render() {
    const { reasonById } = this.props;
    return <ReasonForm reason={reasonById} />;
  }
}

EditReasonScreen.propTypes = {
  match: PropType.shape({}).isRequired,
  reasonById: PropType.string.isRequired,
  fetchReasonById: PropType.func.isRequired,
};

export default EditReasonScreen;
