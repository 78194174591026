// Modules
import React from 'react';
import PropTypes from 'prop-types';

// Commons
import { Fields, Field, Label, ArrowOrder, FieldContent } from './styles';

import Input from '../../../Input';

// Icons
import ArrowIcon from './img/Path.svg';

const Header = ({ fields, order, orderBy, onChangeState, onSearch }) => (
  <Fields>
    {fields.map((field, index) => {
      if (field.key === 'actions') {
        return (
          <Field key={index.toString()} disabled alignment="right">
            <FieldContent>
              <Label focus={false}>{field.label}</Label>
            </FieldContent>
          </Field>
        );
      }
      if (field.key === 'actionsSearch') {
        return (
          <Field key={index.toString()} disabled alignment="right">
            <FieldContent>
              <Input
                type="text"
                placeholder="Buscar..."
                width="175"
                marginRight
                onChange={onSearch}
              />
              <Label focus={false}>{field.label}</Label>
            </FieldContent>
          </Field>
        );
      }
      if (field.key === 'search') {
        return (
          <Field key={index.toString()} disabled alignment="right">
            <FieldContent>
              <Input
                type="text"
                placeholder="Buscar..."
                width="175"
                marginRight
                onChange={onSearch}
              />
            </FieldContent>
          </Field>
        );
      }
      return (
        <Field
          key={index.toString()}
          onClick={() => {
            // First click to focus.
            onChangeState('orderBy', field.key);

            // Second click to sort.
            if (orderBy === field.key) {
              onChangeState('order', order === 'asc' ? 'desc' : 'asc');
            }
          }}
        >
          <FieldContent>
            <ArrowOrder
              focus={orderBy === field.key || false}
              direction={
                order === 'asc' && orderBy === field.key ? 'down' : 'up'
              }
            >
              <img
                alt="order-arrow"
                width="12px"
                height="12px"
                src={ArrowIcon}
              />
            </ArrowOrder>
            <Label focus={orderBy === field.key || false}>{field.label}</Label>
          </FieldContent>
        </Field>
      );
    })}
  </Fields>
);

Header.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
      format: PropTypes.oneOf([
        'default',
        'email',
        'number',
        'date',
        'phone',
        'bool',
        'embed',
      ]).isRequired,
    })
  ).isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  onChangeState: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};

export default Header;
