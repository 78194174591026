import styled from 'styled-components';

export const Layout = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #e8e8e8;
`;

export const Pagination = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Counter = styled.div`
  font-family: 'SF UI Display Medium';
  font-size: 14px;
  color: #243847;
  padding-right: 45px;
`;

export const Actions = styled.div``;

export const Page = styled.button`
  border: none;
  background: none;
  display: inline-flex;
  ${props => `
    transform: rotate(${props.direction === 'left' ? '0deg' : '180deg'});
    ${
      !props.disabled
        ? `
      cursor: pointer;
      opacity: 0.5;
    `
        : `
      cursor: default;
      opacity: 0.2;
    `
    }
  `}
`;

export const Image = styled.img`
  border: none;
  background: none;
  width: 16px;
  height: 16px;
`;
