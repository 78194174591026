import { handleActions } from 'redux-actions';

import { setChartData, setMapData } from './actions';

const defaultState = {
  list: [],
};

export default handleActions(
  {
    [setChartData]: (state, action) => ({
      ...state,
      list: action.payload.charts,
    }),
    [setMapData]: (state, action) => ({
      ...state,
      lisMap: action.payload.maps,
    }),
  },
  defaultState
);
