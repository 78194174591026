import styled from 'styled-components';

export const ModalInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${props => `
    ${props.padding ? `padding: ${props.padding}px` : 'padding: 0 0 130px 0'}
    ${props.textOnly ? 'padding: 35px 0 130px 0' : ''}
    ${props.paddingBottom ? `padding-bottom: ${props.paddingBottom}px` : ''}
  `};
`;
