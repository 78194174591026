// Dependencies
import React from 'react';
import PropTypes from 'prop-types';

import { Layout } from './styles';

const Loader = ({ size, color }) => (
  <Layout>
    <svg
      className="spinner"
      stroke={color}
      width={size}
      height={size}
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className="path"
        fill="none"
        strokeWidth="6"
        strokeLinecap="round"
        cx="33"
        cy="33"
        r="30"
      />
    </svg>
  </Layout>
);

Loader.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};

Loader.defaultProps = {
  size: '14px',
  color: 'white',
};

export default Loader;
