import { handleActions } from 'redux-actions';

import {
  setAgents,
  setAgentById,
  fetchAgentAudiosSuccess,
  fetchAgentReasonsSuccess,
  fetchAgentByPhoneSuccess,
  createAgentSuccess,
  createAgentFailed,
  getAgentRole,
} from './actions';

const defaultState = {
  list: [],
  role: '',
};

export default handleActions(
  {
    [setAgents]: (state, action) => ({
      ...state,
      list: action.payload.agents,
    }),
    [setAgentById]: (state, action) => ({
      ...state,
      agentById: action.payload.agent,
    }),
    [getAgentRole]: (state, action) => ({
      ...state,
      role: action.payload.role,
    }),
    [fetchAgentByPhoneSuccess]: (state, { payload }) => ({
      ...state,
      agentById: payload.agent,
    }),
    [fetchAgentAudiosSuccess]: (state, { payload }) => ({
      ...state,
      agentAudios: payload,
    }),
    [fetchAgentReasonsSuccess]: (state, { payload }) => ({
      ...state,
      agentReasons: payload,
    }),
    [createAgentSuccess]: state => ({ ...state, error: null }),
    [createAgentFailed]: (state, { payload }) => ({
      ...state,
      error: payload.error,
    }),
  },
  defaultState
);
