import styled from 'styled-components';

export const Fields = styled.tr``;

export const Field = styled.th`
  padding: 18px 0;
  user-select: none;
  ${props => `
    cursor: ${props.disabled ? 'default' : 'pointer'};
    text-align: ${props.alignment === 'right' ? 'right' : 'left'};
  `}
`;

export const ArrowOrder = styled.i`
  ${props => `
    width: 12px;
    heigth: 12px;
    transition: all .2s ease-in-out;
    position: relative;
    display: inline-flex;
    transform: rotate(${props.direction === 'up' ? '-90deg' : '90deg'});
    opacity: ${props.focus ? 1 : 0.2};
  `}
`;

export const Label = styled.span`
  color: #243847;
  ${props => `
      font-family: ${
        props.focus ? 'SF UI Display Bold' : 'SF UI Display Medium'
      };
  `}
  text-transform: uppercase;
  font-size: 12px;
  padding-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FieldContent = styled.div`
  padding: 0 28px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
