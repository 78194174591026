// Modules
import { connect } from 'react-redux';

// Screen Layout
import View from './view';

// Users
import { deleteUser, fetchUsers } from '../../modules/users/actions';

// Modal
import { createModal } from '../../modules/ui/actions';

// Redux Connector
export default connect(
  state => ({
    users: state.users.list,
  }),
  {
    // Users
    fetchUsers,
    deleteUser,

    // Modal
    createModal,
  }
)(View);
