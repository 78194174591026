import firebase from 'firebase/app';
import config from '../../config';

require('firebase/auth');

let INSTANCE = null;
class Firebase {
  constructor() {
    if (!INSTANCE) {
      INSTANCE = this;
      firebase.initializeApp(config.FIREBASE);
      this.auth = firebase.auth();
    }
    return INSTANCE;
  }

  getIdToken = user => user.getIdToken();

  onAuthChange = () =>
    new Promise(resolve => {
      this.auth.onAuthStateChanged(user => {
        if (user) {
          resolve({ isLoggedIn: true, user });
          console.log(`User ${user.uid} is logged in`);
        } else {
          resolve({ isLoggedIn: false });
          console.log('User is logged out');
        }
      });
    });

  createUserWithEmailAndPassword = ({ email, password }) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  signInWithEmailAndPassword = ({ email, password }) =>
    this.auth.signInWithEmailAndPassword(email, password);

  signOut = () => this.auth.signOut();

  passwordReset = email => this.auth.sendPasswordResetEmail(email);

  passwordUpdate = password => this.auth.currentUser.updatePassword(password);
}

export default Firebase;
