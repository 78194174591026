// Modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

import {
  Body,
  Field,
  Label,
  Value,
  Option,
  Layout,
  Column,
  Navigator,
  Container,
  LinkElement,
  SubLinkElement,
  SubLinkContainer,
  SubOption,
  BehaviourIcon,
  LocutionsIcon,
  IntegrationIcon,
  EntitiesIcon,
  CallsIcon,
} from './styles';

import Headline from '../../components/Headline';

// Screens
// import ReasonsScreen from '../ReasonsScreen';
// import AudiosScreen from '../AudiosScreen';
import PopulationsScreen from '../PopulationsScreen';
import StopsScreen from '../StopsScreen';
import PathsScreen from '../PathsScreen';
import IntegrationsScreen from '../IntegrationsScreen';
import DestinationsScreen from '../DestinationsScreen';
import LocutionsScreen from '../LocutionsScreen';
import BehavioursScreen from '../BehavioursScreen';
import SchedulesScreen from '../SchedulesScreen';

class DetailClientAgentsScreen extends Component {
  componentDidMount() {
    const {
      fetchAgents,
      fetchAgentByPhone,
      match: {
        params: { phone },
      },
    } = this.props;
    fetchAgents();
    fetchAgentByPhone({ phone });
  }

  render() {
    const {
      match: {
        params: { phone: agentPhone },
      },
      agent: { id, name, identifier, phone, authorName },
      location: { pathname },
    } = this.props;
    const focused = pathname.split('/')[5];
    const subFocused = pathname.split('/')[6];
    const showEntitiesSubMenu = focused === 'entities';
    const showCallFlowSubMenu = focused === 'call-flow';
    return (
      <Layout>
        <Navigator>
          <Headline title={name} />
          <Container>
            <Column separator padding="20px 20px 0  20px">
              <Field>
                <Label>ID</Label>
                <Value>{identifier}</Value>
              </Field>
              <Field>
                <Label>Teléfono</Label>
                <Value>{agentPhone}</Value>
              </Field>
              <Field>
                <Label>Creado por</Label>
                <Value color="#66CCCC">{authorName}</Value>
              </Field>
            </Column>
            <Column>
              <Option selected={focused === 'behaviours'}>
                <LinkElement
                  to={`/client/agents/${id}/${agentPhone}/behaviours`}
                >
                  <BehaviourIcon selected={focused === 'behaviours'} />
                  Comportamiento
                </LinkElement>
              </Option>
              <Option selected={focused === 'locutions'}>
                <LinkElement
                  to={`/client/agents/${id}/${agentPhone}/locutions`}
                >
                  <LocutionsIcon selected={focused === 'locutions'} />
                  Locuciones
                </LinkElement>
              </Option>
              <Option selected={focused === 'entities'}>
                <LinkElement to={`/client/agents/${id}/${agentPhone}/entities`}>
                  <EntitiesIcon selected={focused === 'entities'} />
                  Entidades
                </LinkElement>
              </Option>
              {showEntitiesSubMenu ? (
                <SubLinkContainer>
                  <SubOption selected={subFocused === 'populations'}>
                    <SubLinkElement
                      to={`/client/agents/${id}/${agentPhone ||
                        phone}/entities/populations`}
                    >
                      Poblaciones
                    </SubLinkElement>
                  </SubOption>
                  <SubOption selected={subFocused === 'stops'}>
                    <SubLinkElement
                      to={`/client/agents/${id}/${agentPhone ||
                        phone}/entities/stops`}
                    >
                      Paradas
                    </SubLinkElement>
                  </SubOption>
                  <SubOption selected={subFocused === 'paths'}>
                    <SubLinkElement
                      to={`/client/agents/${id}/${agentPhone ||
                        phone}/entities/paths`}
                    >
                      Trayectos
                    </SubLinkElement>
                  </SubOption>
                </SubLinkContainer>
              ) : null}
              <Option selected={focused === 'call-flow'}>
                <LinkElement
                  to={`/client/agents/${id}/${agentPhone}/call-flow`}
                >
                  {' '}
                  <CallsIcon selected={focused === 'call-flow'} />
                  Flujo de llamadas
                </LinkElement>
              </Option>
              {showCallFlowSubMenu ? (
                <SubLinkContainer>
                  <SubOption selected={subFocused === 'schedules'}>
                    <SubLinkElement
                      to={`/client/agents/${id}/${agentPhone ||
                        phone}/call-flow/schedules`}
                    >
                      Horarios
                    </SubLinkElement>
                  </SubOption>
                  <SubOption selected={subFocused === 'destinations'}>
                    <SubLinkElement
                      to={`/client/agents/${id}/${agentPhone ||
                        phone}/call-flow/destinations`}
                    >
                      Destinos
                    </SubLinkElement>
                  </SubOption>
                </SubLinkContainer>
              ) : null}
              <Option selected={focused === 'integration'}>
                <LinkElement
                  to={`/client/agents/${id}/${agentPhone}/integration`}
                >
                  <IntegrationIcon selected={focused === 'integration'} />
                  Integración
                </LinkElement>
              </Option>
            </Column>
          </Container>
        </Navigator>
        <Body>
          <Switch>
            <Route
              path="/client/agents/:id/:phone/behaviours"
              component={BehavioursScreen}
            />
            <Route
              path="/client/agents/:id/:phone/locutions"
              component={LocutionsScreen}
            />

            {/* Entities */}
            <Route
              path="/client/agents/:id/:phone/entities/populations"
              component={PopulationsScreen}
            />
            <Route
              path="/client/agents/:id/:phone/entities/stops"
              component={StopsScreen}
            />
            <Route
              path="/client/agents/:id/:phone/entities/paths"
              component={PathsScreen}
            />

            <Route
              path="/client/agents/:id/:phone/integration"
              component={IntegrationsScreen}
            />
            <Route
              path="/client/agents/:id/:phone/call-flow/destinations"
              component={DestinationsScreen}
            />
            <Route
              path="/client/agents/:id/:phone/call-flow/schedules"
              component={SchedulesScreen}
            />
            {id && (
              <>
                <Route
                  path="/client/agents/:id/:phone/call-flow"
                  exact
                  render={() => (
                    <Redirect
                      to={`/client/agents/${id}/${agentPhone ||
                        phone}/call-flow/schedules`}
                    />
                  )}
                />
                <Route
                  path="/client/agents/:id"
                  exact
                  render={() => (
                    <Redirect
                      to={`/client/agents/${id}/${agentPhone ||
                        phone}/behaviours`}
                    />
                  )}
                />
                <Route
                  exact
                  path="/client/agents/:id/:phone/entities"
                  render={() => (
                    <Redirect
                      to={`/client/agents/${id}/${agentPhone ||
                        phone}/entities/populations`}
                    />
                  )}
                />
              </>
            )}
          </Switch>
        </Body>
      </Layout>
    );
  }
}

DetailClientAgentsScreen.propTypes = {
  agent: PropTypes.shape({}),
  match: PropTypes.shape({}).isRequired,
  fetchAgentByPhone: PropTypes.func.isRequired,
  location: PropTypes.shape({}).isRequired,
};

DetailClientAgentsScreen.defaultProps = {
  agent: {},
};

export default DetailClientAgentsScreen;
