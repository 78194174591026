export const FETCH_DESTINATIONS = 'FETCH_DESTINATIONS';
export const FETCH_DESTINATIONS_SUCCESS = 'FETCH_DESTINATIONS_SUCCESS';
export const FETCH_DESTINATIONS_FAILED = 'FETCH_DESTINATIONS_FAILED';
export const FETCH_DESTINATION_BY_ID = 'FETCH_DESTINATION_BY_ID';
export const FETCH_DESTINATION_BY_ID_SUCCESS =
  'FETCH_DESTINATION_BY_ID_SUCCESS';
export const FETCH_DESTINATION_BY_ID_FAILED = 'FETCH_DESTINATION_BY_ID_FAILED';
export const CREATE_DESTINATION = 'CREATE_DESTINATION';
export const CREATE_DESTINATION_SUCCESS = 'CREATE_DESTINATION_SUCCESS';
export const CREATE_DESTINATION_FAILED = 'CREATE_DESTINATION_FAILED';
export const UPDATE_DESTINATION = 'UPDATE_DESTINATION';
export const UPDATE_DESTINATION_SUCCESS = 'UPDATE_DESTINATION_SUCCESS';
export const UPDATE_DESTINATION_FAILED = 'UPDATE_DESTINATION_FAILED';
export const DELETE_DESTINATION = 'DELETE_DESTINATION';
export const DELETE_DESTINATION_SUCCESS = 'DELETE_DESTINATION_SUCCESS';
export const DELETE_DESTINATION_FAILED = 'DELETE_DESTINATION_FAILED';
export const DELETE_DESTINATION_BY_ID = 'DELETE_DESTINATION_BY_ID';
