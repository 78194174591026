import { handleActions } from 'redux-actions';

import { setBehaviours, setBehaviourById } from './actions';

const defaultState = {
  list: [],
};

export default handleActions(
  {
    [setBehaviours]: (state, action) => ({
      ...state,
      list: action.payload.behaviours,
    }),
    [setBehaviourById]: (state, action) => ({
      ...state,
      behaviourById: action.payload.behaviour,
    }),
  },
  defaultState
);
