import { takeLatest, put, call } from 'redux-saga/effects';

import {
  FETCH_AUDIOS,
  CREATE_AUDIO,
  DELETE_AUDIO,
  FETCH_AUDIO_BY_ID,
} from './actionTypes';
import { setAudios, setAudioById } from './actions';
import { fetchAgentAudios } from '../agents/actions';
import * as api from './api';
import { deleteModal, setIsSubmitting } from '../ui/actions';

function* fetchAudios() {
  try {
    const response = yield call(api.fetchAudios);
    yield put(setAudios({ audios: response.data.data }));
  } catch (e) {
    console.error({ e });
  }
}

export function* watchFetchAudios() {
  yield takeLatest(FETCH_AUDIOS, fetchAudios);
}

function* fetchAudioById({ payload: { id } }) {
  try {
    const response = yield call(api.fetchAudioById, id);
    yield put(setAudioById({ audio: response.data }));
  } catch (e) {
    console.error({ e });
  }
}

export function* watchFetchAudioById() {
  yield takeLatest(FETCH_AUDIO_BY_ID, fetchAudioById);
}

function* createAudio({ payload }) {
  try {
    yield put(setIsSubmitting(true));
    yield call(api.createAudio, payload.audio);
    yield put(fetchAgentAudios({ id: payload.audio.agentId }));
    yield put(deleteModal());
    yield put(setIsSubmitting(false));
  } catch (e) {
    yield put(setIsSubmitting(false));
    console.error({ e });
  }
}

export function* watchCreateAudio() {
  yield takeLatest(CREATE_AUDIO, createAudio);
}

function* deleteAudio({ payload }) {
  try {
    yield call(api.deleteAudio, payload.audio.item.id);
    yield put(fetchAgentAudios({ id: payload.audio.item.agentId }));
  } catch (e) {
    console.error({ e });
  }
}

export function* watchDeleteAudio() {
  yield takeLatest(DELETE_AUDIO, deleteAudio);
}
