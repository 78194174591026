import { put, call, takeLatest } from 'redux-saga/effects';

import {
  fetchSubaccountsByIdSuccess,
  fetchSubaccountsByIdFailed,
  createSubaccountFailed,
  createSubaccountSuccess,
} from './actions';

import { fetchAccountClients } from '../accounts/actions';

import { FETCH_SUBACCOUNTS_BY_ID, CREATE_SUBACCOUNT } from './actionTypes';

import * as api from './api';
import { deleteModal, setIsSubmitting } from '../ui/actions';

function* fetchSubaccountsById({ payload }) {
  try {
    const response = yield call(
      api.fetchSubaccountById,
      payload.accountId,
      payload.id
    );
    yield put(fetchSubaccountsByIdSuccess(response.data));
  } catch (e) {
    console.error({ e });
    yield put(fetchSubaccountsByIdFailed());
  }
}

export function* watchFetchSubaccountsById() {
  yield takeLatest(FETCH_SUBACCOUNTS_BY_ID, fetchSubaccountsById);
}

function* createSubaccount({ payload }) {
  try {
    yield put(setIsSubmitting(true));
    yield call(api.createSubaccount, payload.id, payload.data);
    yield put(createSubaccountSuccess());
    yield put(deleteModal());
    yield put(setIsSubmitting(false));
    yield put(fetchAccountClients({ id: payload.id }));
  } catch (e) {
    console.error({ e });
    yield put(createSubaccountFailed());
  }
}

export function* watchCreateSubaccount() {
  yield takeLatest(CREATE_SUBACCOUNT, createSubaccount);
}
