import styled from 'styled-components';

export const Layout = styled.div`
  display: flex;
  padding: 48px 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 46px;
`;

export const Title = styled.h1`
  margin: 0;
  color: #243847;
  font-size: 24px;
  font-weight: normal;
  font-family: 'SF UI Display Medium', sans-serif;
`;
