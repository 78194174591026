// Modules
import React from 'react';
import PropType from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Actions from '../UI/Modal/components/Actions';

import { FlexColumn, Button } from '../../theme/styles';
import { Input } from '../Input/styles';
import { Label } from '../Label/styles';
import { ModalInputsContainer } from '../ModalInputsContainer/styles';
import { ModalInputsRowContainer } from '../ModalInputsRowContainer/styles';

const validation = Yup.object().shape({
  type: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
  identifier: Yup.string(),
  businessName: Yup.string(),
  direction: Yup.string(),
  nif: Yup.string(),
  billingContact: Yup.string(),
  email: Yup.string().email(),
  phone: Yup.number(),
  businessContact: Yup.string(),
  emailContactBusiness: Yup.string().email(),
  phoneContactBusiness: Yup.number(),
});

const AccountsForm = ({
  createSubaccount,
  deleteModal,
  account,
  accountId,
  isSubmitting,
}) => (
  <Formik
    initialValues={account}
    enableReinitialize
    onSubmit={values => {
      createSubaccount({ id: accountId, data: values });
    }}
    validationSchema={validation}
  >
    {formProps => {
      const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
      } = formProps;

      return (
        <form>
          <ModalInputsContainer padding={20}>
            <ModalInputsRowContainer spaceBetween>
              <FlexColumn>
                <Label htmlFor="name" style={{ display: 'block' }}>
                  Nombre
                </Label>
                <Input
                  marginRight
                  id="name"
                  placeholder="Enter your account name"
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.name && touched.name
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                {errors.name && touched.name && (
                  <div className="input-feedback">{errors.name}</div>
                )}
              </FlexColumn>

              <FlexColumn>
                <Label htmlFor="businessName" style={{ display: 'block' }}>
                  Razón social
                </Label>
                <Input
                  id="businessName"
                  placeholder="Enter your businessName"
                  type="text"
                  value={values.businessName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.businessName && touched.businessName
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                {errors.businessName && touched.businessName && (
                  <div className="input-feedback">{errors.businessName}</div>
                )}
              </FlexColumn>
            </ModalInputsRowContainer>

            <ModalInputsRowContainer>
              <FlexColumn>
                <Label htmlFor="direction" style={{ display: 'block' }}>
                  Dirección
                </Label>
                <Input
                  marginRight
                  width={350}
                  id="direction"
                  placeholder="Enter your account direction"
                  type="text"
                  value={values.direction}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.direction && touched.direction
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                {errors.direction && touched.direction && (
                  <div className="input-feedback">{errors.nif}</div>
                )}
              </FlexColumn>

              <FlexColumn>
                <Label htmlFor="nif" style={{ display: 'block' }}>
                  Id
                </Label>
                <Input
                  width={150}
                  id="nif"
                  placeholder="Enter your account nif"
                  type="text"
                  value={values.nif}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.nif && touched.nif
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                {errors.nif && touched.nif && (
                  <div className="input-feedback">{errors.nif}</div>
                )}
              </FlexColumn>
            </ModalInputsRowContainer>

            <ModalInputsRowContainer>
              <FlexColumn>
                <Label htmlFor="billingContact" style={{ display: 'block' }}>
                  Contacto negocio
                </Label>
                <Input
                  marginRight
                  id="billingContact"
                  placeholder="Enter your account billingContact"
                  type="text"
                  value={values.billingContact}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.billingContact && touched.billingContact
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                {errors.billingContact && touched.billingContact && (
                  <div className="input-feedback">{errors.billingContact}</div>
                )}
              </FlexColumn>

              <FlexColumn>
                <Label htmlFor="email" style={{ display: 'block' }}>
                  Email
                </Label>
                <Input
                  id="email"
                  placeholder="Enter your account email"
                  type="text"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.email && touched.email
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                {errors.email && touched.email && (
                  <div className="input-feedback">{errors.email}</div>
                )}
              </FlexColumn>
            </ModalInputsRowContainer>

            <ModalInputsRowContainer>
              <FlexColumn>
                <Label htmlFor="phone" style={{ display: 'block' }}>
                  Teléfono
                </Label>
                <Input
                  marginRight
                  id="phone"
                  placeholder="Enter your account phone"
                  type="text"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.phone && touched.phone
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                {errors.phone && touched.phone && (
                  <div className="input-feedback">{errors.phone}</div>
                )}
              </FlexColumn>
            </ModalInputsRowContainer>
          </ModalInputsContainer>
          <Actions>
            <Button type="button" disabled={isSubmitting} onClick={deleteModal}>
              Cancelar
            </Button>
            <Button
              primary
              type="submit"
              disabled={isSubmitting}
              onClick={handleSubmit}
            >
              Crear Cuenta
            </Button>
          </Actions>
        </form>
      );
    }}
  </Formik>
);

AccountsForm.propTypes = {
  createSubaccount: PropType.func.isRequired,
  values: PropType.shape({}),
  touched: PropType.shape({}),
  errors: PropType.shape({}),
  account: PropType.shape({}),
  deleteModal: PropType.func.isRequired,
  accountId: PropType.string.isRequired,
};

AccountsForm.defaultProps = {
  values: {},
  touched: {},
  errors: {},
  account: {
    type: 'CLIENT',
    name: '',
    businessName: '',
    direction: '',
    nif: '',
    billingContact: '',
    email: '',
    phone: '',
    businessContact: '',
    emailContactBusiness: '',
    phoneContactBusiness: '',
  },
};

export default AccountsForm;
