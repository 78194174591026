// Modules
import { connect } from 'react-redux';

// Layout Screen
import View from './view';

// Actions
import {
  updateDestination,
  fetchDestinationById,
} from '../../modules/destinations/actions';
import { deleteModal } from '../../modules/ui/actions';

// Redux Connector
export default connect(
  state => ({
    destination: state.destinations.byId,
    isSubmitting: state.ui.isSubmitting,
  }),
  {
    updateDestination,
    fetchDestinationById,
    deleteModal,
  }
)(View);
