import React from 'react';
import PropType from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

const validation = Yup.object().shape({
  name: Yup.string().required('Required'),
  account: Yup.string().required('Required'),
  subaccount: Yup.string().required('Required'),
  role: Yup.string().required('Required'),
  email: Yup.string()
    .email()
    .required('Required'),
});

const UsersFormScreen = props => {
  const accounts = [
    { name: 'Account1', value: 'ACCOUNT1' },
    { name: 'Account2', value: 'ACCOUNT2' },
    { name: 'Account3', value: 'ACCOUNT3' },
    { name: 'Account4', value: 'ACCOUNT4' },
  ];

  const subAccounts = [
    { name: 'Subaccount1', value: 'SUBACCOUNT1' },
    { name: 'Subaccount2', value: 'SUBACCOUNT2' },
    { name: 'Subaccount3', value: 'SUBACCOUNT3' },
    { name: 'Subaccount4', value: 'SUBACCOUNT4' },
  ];

  const roles = [
    { name: 'admin', value: 'ADMIN' },
    { name: 'client', value: 'CLIENT' },
  ];

  const { createUser, isSubmitting } = props;
  return (
    <Formik
      initialValues={{
        name: '',
        account: accounts[0].value,
        subaccount: subAccounts[0].value,
        email: '',
        role: roles[0].value,
      }}
      onSubmit={values => {
        createUser(values);
      }}
      validationSchema={validation}
    >
      {formProps => {
        const {
          values,
          touched,
          errors,
          dirty,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
        } = formProps;
        return (
          <form>
            <label htmlFor="Name" style={{ display: 'block' }}>
              First name
            </label>
            <input
              id="Name"
              placeholder="Enter your Name"
              type="text"
              value={values.Name}
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                errors.Name && touched.Name ? 'text-input error' : 'text-input'
              }
            />
            {errors.Name && touched.Name && (
              <div className="input-feedback">{errors.Name}</div>
            )}

            <label htmlFor="account" style={{ display: 'block' }}>
              Account
            </label>
            <select id="account" onChange={handleChange}>
              {accounts.map(account => (
                <option key={account.value} value={account.value}>
                  {account.name}
                </option>
              ))}
            </select>
            {errors.account && touched.account && (
              <div className="input-feedback">{errors.account}</div>
            )}

            <label htmlFor="subaccount" style={{ display: 'block' }}>
              Subaccount
            </label>
            <select id="subaccount" onChange={handleChange}>
              {subAccounts.map(subAccount => (
                <option key={subAccount.value} value={subAccount.value}>
                  {subAccount.name}
                </option>
              ))}
            </select>
            {errors.subaccount && touched.subaccount && (
              <div className="input-feedback">{errors.subaccount}</div>
            )}

            <label htmlFor="email" style={{ display: 'block' }}>
              Email
            </label>
            <input
              id="email"
              placeholder="Enter your email"
              type="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                errors.email && touched.email
                  ? 'text-input error'
                  : 'text-input'
              }
            />
            {errors.email && touched.email && (
              <div className="input-feedback">{errors.email}</div>
            )}

            <label htmlFor="role" style={{ display: 'block' }}>
              Role
            </label>
            <select id="role" onChange={handleChange}>
              {roles.map(role => (
                <option key={role.value} value={role.value}>
                  {role.name}
                </option>
              ))}
            </select>
            {errors.role && touched.role && (
              <div className="input-feedback">{errors.role}</div>
            )}
            <button
              type="button"
              className="outline"
              onClick={handleReset}
              disabled={!dirty || isSubmitting}
            >
              Reset
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              onClick={handleSubmit}
            >
              Submit
            </button>
          </form>
        );
      }}
    </Formik>
  );
};

UsersFormScreen.propTypes = {
  createUser: PropType.func.isRequired,
  values: PropType.shape({}),
  touched: PropType.shape({}),
  errors: PropType.shape({}),
};

UsersFormScreen.defaultProps = {
  values: {},
  touched: {},
  errors: {},
};

export default UsersFormScreen;
