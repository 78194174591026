// Modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';

import {
  setToken as setTokenAction,
  fetchMe as fetchMeAction,
} from './modules/auth/actions';

import { isAuthenticated } from './lib/sessionStorage';

// Screens
import ErrorScreen from './screens/ErrorScreen';

import LoginScreen from './screens/LoginScreen';

// import DashboardScreen from './screens/DashboardScreen';

import AgentsScreen from './screens/AgentsScreen';
import AgentsFormScreen from './screens/AgentsFormScreen';
import AudiosFormScreen from './screens/AudiosFormScreen';
import EditAudiosScreen from './screens/EditAudiosScreen';
import ReasonsFormScreen from './screens/ReasonsFormScreen';
import EditReasonsScreen from './screens/EditReasonsScreen';
import DetailAgentScreen from './screens/DetailAgentScreen';

import DetailClientAgentScreen from './screens/DetailClientAgentsScreen';
import ClientAgentsScreen from './screens/ClientAgentsScreen';
import AccountsScreen from './screens/AccountsScreen';
import AccountsFormScreen from './screens/AccountsFormScreen';
import EditAccountsScreen from './screens/EditAccountsScreen';
import AccountsConfigureScreen from './screens/AccountsConfigureScreen';

import SubaccountsScreen from './screens/SubaccountsScreen';

import UsersScreen from './screens/UsersScreen';
import UsersFormScreen from './screens/UsersFormScreen';
import EditUsersScreen from './screens/EditUsersScreen';
import DetailUserScreen from './screens/DetailUserScreen';

// Commons
import UI from './components/UI';
// import PrivateRoute from './components/PrivateRoute';
import RoleRoute from './components/RoleRoute';
import NewDashboardScreen from './screens/NewDashboardScreen';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingUser: true,
    };
  }

  componentDidMount() {
    const { user, fetchMe } = this.props;

    if (isAuthenticated()) {
      fetchMe();
      this.setState({
        isLoadingUser: false,
      });
    }

    if (user) {
      this.setState({
        isLoadingUser: false,
      });
    }

    this.setState({
      isLoadingUser: false,
    });
  }

  renderLoggedRoutes = () => (
    <Switch>
      {/* Dashboard */}
      <Route exact path="/dashboard" component={NewDashboardScreen} />

      {/* Agents */}
      <RoleRoute
        path="/client/agents/:agentId/:phone"
        component={DetailClientAgentScreen}
      />
      <RoleRoute path="/client/agents/" component={ClientAgentsScreen} />
      <RoleRoute
        path="/agents/:agentsId/audios/:audioId/edit"
        component={EditAudiosScreen}
      />
      <RoleRoute path="/agents/:id/audios/new" component={AudiosFormScreen} />
      <RoleRoute
        path="/agents/:agentsId/reasons/:reasonId/edit"
        component={EditReasonsScreen}
      />
      <RoleRoute path="/agents/:id/reasons/new" component={ReasonsFormScreen} />
      <RoleRoute path="/agents/:id" component={DetailAgentScreen} />
      <RoleRoute path="/agents/new" component={AgentsFormScreen} />
      <RoleRoute path="/agents" component={AgentsScreen} />

      {/* Accounts */}
      <RoleRoute path="/accounts/new" component={AccountsFormScreen} />
      <RoleRoute path="/accounts/:id/edit" component={EditAccountsScreen} />
      <RoleRoute
        path="/accounts/:id/subaccounts"
        component={SubaccountsScreen}
      />
      <RoleRoute path="/accounts/:id" component={AccountsConfigureScreen} />
      <RoleRoute path="/accounts" component={AccountsScreen} />

      {/* Users */}
      <RoleRoute path="/users/:id/edit" component={EditUsersScreen} />
      <RoleRoute path="/users/new" component={UsersFormScreen} />
      <RoleRoute path="/users/:id" component={DetailUserScreen} />
      <RoleRoute path="/users" component={UsersScreen} />

      {/* Root */}
      <Route path="/" exact render={() => <Redirect to="/dashboard" />} />

      <Route
        component={() => (
          <ErrorScreen
            title="Página no encontrada"
            headline="Puede que la página a la que intentas acceder no esté disponible o el enlace sea incorrecto."
          />
        )}
      />
    </Switch>
  );

  renderLoggedOutRoutes = () => (
    <Switch>
      <Route path="/:id/set-password" component={LoginScreen} />
      <Route path="/reset-password/:token" component={LoginScreen} />
      <Route exact path="/login" component={LoginScreen} />
      <Route exact path="/" component={LoginScreen} />
    </Switch>
  );

  render() {
    const { isLoadingUser } = this.state;
    const { loginStatus } = this.props;
    const {
      location: { pathname },
    } = this.props;
    return (
      <UI overlay={loginStatus} path={pathname}>
        {isLoadingUser && <span>Loading....</span>}
        {!isLoadingUser &&
          loginStatus === 'logged' &&
          this.renderLoggedRoutes()}
        {!isLoadingUser &&
          !isAuthenticated() &&
          (loginStatus === 'not-logged' ||
            loginStatus === 'not-checked' ||
            loginStatus === 'logged-error') &&
          this.renderLoggedOutRoutes()}
      </UI>
    );
  }
}

App.defaultProps = {
  user: {},
};

App.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  loginStatus: PropTypes.string.isRequired,
  fetchMe: PropTypes.func.isRequired,
  user: PropTypes.shape({}),
};

export default withRouter(
  connect(
    state => ({
      loginStatus: state.auth.status,
    }),
    {
      setToken: setTokenAction,
      fetchMe: fetchMeAction,
    }
  )(App)
);
