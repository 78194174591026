import styled from 'styled-components';

export const Field = styled.td`
  padding: 10px 0px;
  align-items: center;
  position: relative;
  ${props => `
    color: ${props.color || '#44444D'};
    ${
      props.alignment === 'left'
        ? `
      text-align: left;
    `
        : `
      text-align: right;
    `
    }
  `};
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

export const Value = styled.span`
  font-family: 'SF UI Display Medium';
  font-size: 13px;
  margin-right: ${props => (props.marginRight ? props.marginRight : 0)}px;
`;

export const Button = styled.button`
  border: none;
  font-size: 11px;
  margin-left: 8px;
  padding: 6px 17px;
  border-radius: 18px;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  text-transform: uppercase;
  font-family: 'SF UI Display Bold';
  opacity: 0;

  span {
    top: 1px;
    position: relative;
    padding-left: 10px;
  }

  cursor: pointer;
  transition: all 0.1s ease-in-out;

  ${props => `
    ${
      props.type === 'red'
        ? `
      background: #FFEBEE;

      span {
        color: #F44336;
      }
    `
        : `
      ${props.type === 'green' &&
        `
        background: #F1F8E9;

        span {
          color: #4CAF50;
        }
      `}
    `
    }
  `};
`;

export const Fields = styled.tr`
  background: #fff;
  border-top: 1px solid #ededed;
  background-color: #fff;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0);
  transition: opacity 0.1s ease-in-out;
  position: relative;
  &:hover {
    background: #fafafa;
    ${Button} {
      opacity: 1;
    }
  }
`;

export const FieldContent = styled.div`
  padding: 0 28px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
