// Modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Commons
import Headline from '../../components/Headline';
import Table from '../../components/TableV2';
import AccountForm from '../../components/AccountForm';
import DeleteModal from '../../components/DeleteModal';
import { SectionContainer, Button } from '../../theme/styles';

// Assets
import ConfigImg from './img/tune.svg';
import TrashImg from './img/trash-can-outline.svg';

const getType = type => {
  switch (type) {
    case 'CLIENT':
      return 'Cliente';
    case 'PARTNER':
      return 'Socio';
    case 'MASTER':
      return 'Master';
  }
  return '';
};

class AccountsScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table: {
        page: 1,
        order: 'asc',
        orderBy: 'name',
      },
    };
  }

  componentDidMount() {
    const { fetchAccounts } = this.props;
    fetchAccounts();
  }

  createAccount = () => {
    const { createModal } = this.props;
    createModal({
      title: 'Cuenta Nueva',
      width: 560,
      body: <AccountForm />,
    });
  };

  render() {
    const {
      table: { page, order, orderBy },
    } = this.state;
    const { history, accounts, deleteAccount, createModal } = this.props;

    // ==================
    return (
      <SectionContainer>
        <Headline
          title="Cuentas"
          actions={
            <Button primary onClick={this.createAccount}>
              Nueva Cuenta
            </Button>
          }
        />
        <Table
          page={page}
          order={order}
          orderBy={orderBy}
          rowsPerPage={12}
          fields={[
            {
              key: 'name',
              label: 'Nombre',
              format: 'default',
            },
            {
              key: 'type',
              label: 'Tipo',
              format: 'default',
              color: '#ABABB8',
            },
            {
              key: 'id',
              label: 'ID',
              format: 'default',
              color: '#ABABB8',
            },
            {
              key: 'date',
              label: 'Fecha de creación',
              format: 'date',
              color: '#ABABB8',
            },
            {
              key: 'author',
              label: 'Creado por',
              format: 'default',
              color: '#66CCCC',
            },
            {
              key: 'actions',
              label: 'Acciones',
              format: 'embed',
            },
          ]}
          data={accounts.map((account, index) => ({
            key: index,
            name: account.name,
            id: account.id,
            date: account.createdAt,
            type: getType(account.type),
            author: account.authorName || '-',
            actions: [
              {
                icon: (
                  <img
                    alt="config-icon"
                    src={ConfigImg}
                    width="18px"
                    height="18px"
                  />
                ),
                color: 'green',
                text: 'Configurar',
                handler: () => history.push(`/accounts/${account.id}`),
              },
              {
                disabled: account.type === 'MASTER',
                icon: (
                  <img
                    alt="trash-icon"
                    src={TrashImg}
                    width="13px"
                    height="15px"
                  />
                ),
                color: 'red',
                text: 'Eliminar',
                handler: () =>
                  createModal({
                    title: 'Eliminar Cuenta',
                    width: 480,
                    body: (
                      <DeleteModal
                        item={account}
                        title="¿Estas seguro que deseas eliminar esta Cuenta?"
                        removeText="Eliminar Cuenta"
                        deleteAction={deleteAccount}
                      />
                    ),
                  }),
              },
            ],
          }))}
          onChangeField={(key, value) => {
            this.setState(prevState => ({
              ...prevState,
              table: {
                ...prevState.table,
                [key]: value,
              },
            }));
          }}
        />
      </SectionContainer>
    );
  }
}

AccountsScreen.propTypes = {
  match: PropTypes.shape({}).isRequired,
  accounts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  history: PropTypes.shape({}).isRequired,
  fetchAccounts: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  createModal: PropTypes.func.isRequired,
};

export default AccountsScreen;
