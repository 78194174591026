import { handleActions } from 'redux-actions';

import { setAudios, setAudioById } from './actions';

const defaultState = {
  list: [],
};

export default handleActions(
  {
    [setAudios]: (state, action) => ({
      ...state,
      list: action.payload.audios,
    }),
    [setAudioById]: (state, action) => ({
      ...state,
      audioById: action.payload.audio,
    }),
  },
  defaultState
);
