// Modules
import { combineReducers } from 'redux';

// Reducers
import ui from './ui/reducers';
import auth from './auth/reducers';
import agents from './agents/reducers';
import dashboard from './dashboard/reducers';
import accounts from './accounts/reducers';
import subaccounts from './subaccounts/reducers';
import users from './users/reducers';
import reasons from './reasons/reducers';
import audios from './audios/reducers';
import locutions from './locutions/reducers';
import behaviours from './behaviours/reducers';
import populations from './populations/reducers';
import stops from './stops/reducers';
import paths from './paths/reducers';
import integrations from './integrations/reducers';
import schedules from './schedules/reducers';
import destinations from './destinations/reducers';
import resetPassword from './reset-password/reducers';

// Combined Reducers
export default combineReducers({
  ui,
  auth,
  agents,
  accounts,
  subaccounts,
  users,
  reasons,
  audios,
  locutions,
  behaviours,
  populations,
  stops,
  paths,
  integrations,
  schedules,
  destinations,
  resetPassword,
  dashboard,
});
