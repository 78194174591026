import { get } from '../../lib/restClient';

export const fetchData = async (agentPhone = '', dateFirst, dateLast) => {
  let query =
    // '/query/client/filter-dates/agent?dateFirst=2021-01-01T00:00:00.000Z&dateLast=2022-01-01T00:00:00.000Z';
    `/query/client/filter-dates/agent?dateFirst=${dateFirst}&dateLast=${dateLast}`;
  if (agentPhone) {
    query += `&agentPhone=${agentPhone}`;
  }
  return get(query);
};
