import React, { Component } from 'react';
import PropType from 'prop-types';
import AccountForm from '../../components/AccountForm';

class EditAccountScreen extends Component {
  componentDidMount() {
    const {
      fetchAccountById,
      match: {
        params: { id },
      },
    } = this.props;
    fetchAccountById(id);
  }

  render() {
    const { accountById } = this.props;
    return <AccountForm account={accountById} />;
  }
}

EditAccountScreen.propTypes = {
  match: PropType.shape({}).isRequired,
  accountById: PropType.string.isRequired,
  fetchAccountById: PropType.func.isRequired,
};

export default EditAccountScreen;
