// Modules
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// Commons
import {
  Fields,
  Field,
  Value,
  Button,
  FieldContent,
  ListContainer,
} from './styles';

class Body extends React.Component {
  // Action
  formatField = (key, format, value) => {
    if (key === 'actions') {
      return value.map((action, i) => (
        <Button
          key={i.toString()}
          type={action.color}
          onClick={action.handler}
          disabled={action.disabled}
        >
          {action.icon && action.icon}
          <span>{action.text}</span>
        </Button>
      ));
    }

    if (key === 'actionsSearch') {
      return value.map((action, i) => (
        <Button key={i.toString()} type={action.color} onClick={action.handler}>
          {action.icon && action.icon}
          <span>{action.text}</span>
        </Button>
      ));
    }

    if (key === 'audio') {
      return (
        <audio src={value} preload="auto" controls>
          <track kind="captions" />
        </audio>
      );
    }

    // Date
    if (format === 'date') {
      return <Value>{moment(value).format('DD/MM/YYYY')}</Value>;
    }

    if (format === 'list') {
      // timeConditions
      return value.some(item => item.startHour !== null)
        ? value.map(item => (
            <ListContainer>
              <Value>
                {item.startDay}-{item.endDay} {item.startHour} a {item.endHour}{' '}
                hs
              </Value>
            </ListContainer>
          ))
        : null;
    }

    if (format === 'embed') {
      return null;
    }

    if (typeof value === 'undefined') {
      return <Value>-</Value>;
    }

    // Key
    return <Value>{value || ''}</Value>;
  };

  render() {
    const { data, fields } = this.props;
    return data.map((field, i) => (
      <Fields key={i.toString()}>
        {fields.map((f, index) => (
          <Field
            color={f.color}
            key={index.toString()}
            flex={f.key === 'actions' ? 1 : 0.5}
            alignment={
              f.key === 'actions' || f.key === 'actionsSearch'
                ? 'right'
                : 'left'
            }
          >
            <FieldContent>
              {this.formatField(f.key, f.format, field[f.key])}
            </FieldContent>
          </Field>
        ))}
      </Fields>
    ));
  }
}

Body.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
      color: PropTypes.string,
      format: PropTypes.oneOf([
        'default',
        'email',
        'number',
        'date',
        'phone',
        'bool',
        'embed',
      ]).isRequired,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Body;
