// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Commons
import Table from '../../components/TableV2';
import Headline from '../../components/Headline';
import { SectionContainer } from '../../theme/styles';

// Assets
import ConfigImg from './img/tune.svg';
import TrashImg from './img/trash-can-outline.svg';

class ClientAgentsScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table: {
        page: 1,
        order: 'desc',
        orderBy: 'name',
      },
    };
  }

  componentDidMount() {
    const { fetchAccountAgents, me } = this.props;
    fetchAccountAgents({ id: me.account });
  }

  render() {
    const {
      table: { page, order, orderBy },
    } = this.state;
    const { agents, history, deleteAgent } = this.props;
    // ===========================
    return (
      <SectionContainer>
        <Headline title="Agentes" />
        <Table
          page={page}
          order={order}
          orderBy={orderBy}
          rowsPerPage={12}
          fields={[
            {
              key: 'name',
              label: 'Nombre',
              format: 'default',
            },
            {
              key: 'phone',
              label: 'Teléfono',
              format: 'number',
            },
            {
              key: 'id',
              label: 'ID',
              format: 'number',
              color: '#ABABB8',
            },
            {
              key: 'actionsSearch',
              label: 'Acciones',
              format: 'embed',
            },
          ]}
          data={agents.map((agent, index) => ({
            key: index,
            id: agent.identifier,
            name: agent.name,
            phone: agent.phone,
            author: 'Admin',
            actionsSearch: [
              {
                icon: (
                  <img
                    alt="config-icon"
                    src={ConfigImg}
                    width="18px"
                    height="18px"
                  />
                ),
                color: 'green',
                text: 'Configurar',
                handler: () =>
                  history.push(`/client/agents/${agent.id}/${agent.phone}`),
              },
              {
                icon: (
                  <img
                    alt="trash-icon"
                    src={TrashImg}
                    width="13px"
                    height="15px"
                  />
                ),
                color: 'red',
                text: 'Eliminar',
                handler: () => deleteAgent({ id: agent.id }),
              },
            ],
          }))}
          onChangeField={(key, value) => {
            this.setState(prevState => ({
              ...prevState,
              table: {
                ...prevState.table,
                [key]: value,
              },
            }));
          }}
          hasFilter
        />
      </SectionContainer>
    );
  }
}

ClientAgentsScreen.propTypes = {
  agents: PropTypes.arrayOf(PropTypes.shape({})),
  match: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  fetchAccountAgents: PropTypes.func.isRequired,
  deleteAgent: PropTypes.func.isRequired,
  me: PropTypes.shape({
    account: PropTypes.string,
  }).isRequired,
};

ClientAgentsScreen.defaultProps = {
  agents: [],
};

export default ClientAgentsScreen;
