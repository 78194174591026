// Modules
import { connect } from 'react-redux';

// Screen Layout
import View from './view';

// Actions
import { fetchStops } from '../../modules/stops/actions';

// Redux Connector
export default connect(
  state => ({
    stops: state.stops.list,
  }),
  {
    fetchStops,
  }
)(View);
