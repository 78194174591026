import { takeLatest, put } from 'redux-saga/effects';
import axios from 'axios';
import { FETCH_CHART_DATA } from './actionTypes';
import { setChartData, setMapData } from './actions';

const responseMotivos = require('../../datamockup/dataUno.json');
const responseLlamadasTiempo = require('../../datamockup/dataLlamadas.json');

async function postLlamdasDatos() {
  try {
    const response = await axios({
      method: 'get',
      url: 'https://europe-west1-atibus-dev.cloudfunctions.net/consumo',
      params: {
        telefono: 911112233,
        fecha: 1119,
      },
    });
    console.log(response);
  } catch (error) {
    console.error(error);
  }
}
function* fetchChartData() {
  try {
    // const responseDatosLlamada = yield call(postLlamdasDatos);

    yield put(setChartData({ charts: responseLlamadasTiempo }));
    yield put(setMapData({ maps: responseMotivos[0] }));
  } catch (e) {
    console.error(e.stack);
  }
}

export function* watchFetchChartData() {
  yield takeLatest(FETCH_CHART_DATA, fetchChartData);
}
