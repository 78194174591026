/* eslint-disable */
// Dependencies
import styled from 'styled-components';

export const Layout = styled.div`
	.spinner {
	  -webkit-animation: rotator 1.4s linear infinite;
	          animation: rotator 1.4s linear infinite;
	}
	
	@-webkit-keyframes rotator {
	  0% {
	    -webkit-transform: rotate(0deg);
	            transform: rotate(0deg);
	  }
	  100% {
	    -webkit-transform: rotate(270deg);
	            transform: rotate(270deg);
	  }
	}
	
	@keyframes rotator {
	  0% {
	    -webkit-transform: rotate(0deg);
	            transform: rotate(0deg);
	  }
	  100% {
	    -webkit-transform: rotate(270deg);
	            transform: rotate(270deg);
	  }
	}
	.path {
	  stroke-dasharray: 187;
	  stroke-dashoffset: 0;
	  -webkit-transform-origin: center;
	          transform-origin: center;
	  -webkit-animation: dash 1.4s ease-in-out infinite;
	          animation: dash 1.4s ease-in-out infinite;
	}
	
	@-webkit-keyframes dash {
	  0% {
	    stroke-dashoffset: 187;
	  }
	  50% {
	    stroke-dashoffset: 46.75;
	    -webkit-transform: rotate(135deg);
	            transform: rotate(135deg);
	  }
	  100% {
	    stroke-dashoffset: 187;
	    -webkit-transform: rotate(450deg);
	            transform: rotate(450deg);
	  }
	}
	@keyframes dash {
	  0% {
	    stroke-dashoffset: 187;
	  }
	  50% {
	    stroke-dashoffset: 46.75;
	    -webkit-transform: rotate(135deg);
	            transform: rotate(135deg);
	  }
	  100% {
	    stroke-dashoffset: 187;
	    -webkit-transform: rotate(450deg);
	            transform: rotate(450deg);
	  }
	}
`;
