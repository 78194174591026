// Modules
import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

// Commons
import { Layout, ErrorContainer, ErrorText } from './styles';

import Actions from '../UI/Modal/components/Actions';
import { Button } from '../../theme/styles';

import { Input } from '../Input/styles';
import { Label } from '../Label/styles';

// Validation
const validation = Yup.object().shape({
  name: Yup.string().required('Required'),
  identifier: Yup.string().required('Required'),
});

const AgentsForm = ({
  createAgent,
  deleteModal,
  agent,
  error,
  me,
  getAgentRole,
  isSubmitting,
}) => (
  <Formik
    enableReinitialize
    initialValues={agent}
    validationSchema={validation}
    onSubmit={values => {
      const newAgent = values;
      newAgent.author = me.email;
      newAgent.authorName = me.name;
      getAgentRole({ role: me.role });
      createAgent(values);
    }}
  >
    {formProps => {
      const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
      } = formProps;

      return (
        <form>
          <Layout>
            <div>
              <Label htmlFor="name" style={{ display: 'block' }}>
                Nombre
              </Label>
              <Input
                marginBottom
                id="name"
                placeholder="Introduce el nombre del agente"
                type="text"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.name && touched.name
                    ? 'text-input error'
                    : 'text-input'
                }
              />
              {errors.name && touched.name && (
                <div className="input-feedback">{errors.name}</div>
              )}

              <Label htmlFor="identifier" style={{ display: 'block' }}>
                Identificador
              </Label>
              <Input
                id="identifier"
                placeholder="Introduce el indentificador"
                type="text"
                value={values.identifier}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.identifier && touched.identifier
                    ? 'text-input error'
                    : 'text-input'
                }
              />
              {errors.identifier && touched.identifier && (
                <div className="input-feedback">{errors.identifier}</div>
              )}
              {error && (
                <ErrorContainer>
                  <ErrorText>{error}</ErrorText>
                </ErrorContainer>
              )}
            </div>
          </Layout>
          <Actions>
            <Button
              type="button"
              disabled={isSubmitting}
              onClick={() => deleteModal()}
            >
              Cancelar
            </Button>
            <Button
              primary
              type="submit"
              disabled={isSubmitting}
              onClick={handleSubmit}
            >
              Crear Agente
            </Button>
          </Actions>
        </form>
      );
    }}
  </Formik>
);

AgentsForm.propTypes = {
  values: PropTypes.shape({}),
  touched: PropTypes.shape({}),
  errors: PropTypes.shape({}),
  agent: PropTypes.shape({}),
  createAgent: PropTypes.func.isRequired,
  deleteModal: PropTypes.func.isRequired,
  error: PropTypes.string,
  me: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

AgentsForm.defaultProps = {
  values: {},
  touched: {},
  errors: {},
  agent: {},
  error: null,
};

export default AgentsForm;
