import { get, post, put, del } from '../../lib/restClient';

export const fetchSchedules = phone => get(`/schedules?phone=${phone}`);

export const fetchScheduleById = id => get(`/schedules/${id}`);

export const create = data => post('/schedules', data);

export const update = (id, data) => put(`/schedules/${id}`, data);

export const deleteSchedule = id => del(`/schedules/${id}`);
