// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Commons
import Headline from '../../components/Headline';
import Table from '../../components/TableV2';
import BehaviourForm from '../../components/BehaviourForm';
import DeleteModal from '../../components/DeleteModal';
import { SectionContainer, Button } from '../../theme/styles';

// Assets
import TrashImg from './img/trash-can-outline.svg';

class BehavioursScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table: {
        page: 1,
        order: 'desc',
        orderBy: 'name',
      },
    };
  }

  componentDidMount() {
    const {
      fetchBehaviours,
      match: {
        params: { phone },
      },
    } = this.props;
    fetchBehaviours({ phone });
  }

  createBehaviour = id => {
    const {
      createModal,
      match: {
        params: { phone },
      },
    } = this.props;
    createModal({
      title: 'Nuevo Comportamiento',
      width: 650,
      body: <BehaviourForm id={id} agentPhone={phone} />,
    });
  };

  render() {
    const {
      table: { page, order, orderBy },
    } = this.state;
    const {
      match: {
        params: { id, phone },
      },
      behaviours,
      history,
      deleteBehaviour,
      createModal,
    } = this.props;
    const mapActionName = {
      DIALOG: 'DIÁLOGO',
      LOCUTION: 'LOCUCIÓN',
      TRANSFER: 'TRANSFER',
    };
    return (
      <SectionContainer>
        <Headline
          title="Comportamiento Agentes"
          actions={
            <div>
              <Button
                secondary
                style={{ marginRight: '8px' }}
                onClick={() => history.push('/client/agents')}
              >
                Volver a la lista de agentes
              </Button>
              <Button primary onClick={() => this.createBehaviour(id)}>
                Nuevo Comportamiento
              </Button>
            </div>
          }
        />
        <Table
          page={page}
          order={order}
          orderBy={orderBy}
          rowsPerPage={12}
          fields={[
            {
              key: 'id',
              label: 'ID',
              format: 'number',
            },
            {
              key: 'reason',
              label: 'Motivo',
              format: 'default',
              color: '#ABABB8',
            },
            {
              key: 'schedule',
              label: 'Horario',
              format: 'default',
              color: '#ABABB8',
            },
            {
              key: 'action',
              label: 'Acción',
              format: 'default',
              color: '#ABABB8',
            },
            {
              key: 'destination',
              label: 'Destinos',
              format: 'default',
              color: '#ABABB8',
            },
            {
              key: 'actions',
              label: 'Acciones',
              format: 'embed',
            },
          ]}
          data={behaviours.map((behaviour, index) => ({
            key: index,
            id: behaviour.id,
            reason: behaviour.reason.reason,
            schedule: behaviour.schedule.name,
            action: mapActionName[behaviour.action],
            destination: behaviour.destination.name
              ? behaviour.destination.name
              : '',
            actions: [
              {
                icon: (
                  <img
                    alt="trash-icon"
                    src={TrashImg}
                    width="13px"
                    height="15px"
                  />
                ),
                color: 'red',
                text: 'Eliminar',
                handler: () =>
                  createModal({
                    title: 'Eliminar Comportamiento',
                    width: 480,
                    body: (
                      <DeleteModal
                        item={{ id: behaviour.id }}
                        phone={phone}
                        title="¿Estas seguro que deseas eliminar este Comportamiento?"
                        removeText="Eliminar Comportamiento"
                        deleteAction={deleteBehaviour}
                      />
                    ),
                  }),
              },
            ],
          }))}
          onChangeField={(key, value) => {
            this.setState(prevState => ({
              ...prevState,
              table: {
                ...prevState.table,
                [key]: value,
              },
            }));
          }}
        />
      </SectionContainer>
    );
  }
}

BehavioursScreen.propTypes = {
  behaviours: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  agent: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  fetchBehaviours: PropTypes.func.isRequired,
  deleteBehaviour: PropTypes.func.isRequired,
  createModal: PropTypes.func.isRequired,
};

export default BehavioursScreen;
