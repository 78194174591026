import React from 'react';
import PropType from 'prop-types';

import {
  HeaderContainer,
  RightContainer,
  LeftContainer,
  Picture,
  Profile,
  Account,
  Menu,
  Action,
  MenuContainer,
  MenuItem,
  Arrow,
} from './styles';
import Logo from '../Logo';

class Header extends React.Component {
  state = {
    isMenuActive: false,
  };

  toggleMenu = () => {
    this.setState(prevState => ({
      isMenuActive: !prevState.isMenuActive,
    }));
  };

  render() {
    const { logout, me } = this.props;
    const { isMenuActive } = this.state;

    return (
      <HeaderContainer onMouseLeave={isMenuActive ? this.toggleMenu : null}>
        <LeftContainer>
          <Logo />
        </LeftContainer>
        <RightContainer>
          {/* Profile Account */}
          <Profile>
            {/* <Picture src="https://abs.twimg.com/sticky/default_profile_images/default_profile_400x400.png" /> */}
            <Account>{me.name}</Account>
            <Menu>
              <Action onClick={this.toggleMenu}>
                <Arrow />
              </Action>
              <MenuContainer active={!!isMenuActive}>
                <MenuItem onClick={logout}>Logout</MenuItem>
              </MenuContainer>
            </Menu>
          </Profile>
        </RightContainer>
      </HeaderContainer>
    );
  }
}

Header.propTypes = {
  logout: PropType.func.isRequired,
  me: PropType.shape({}),
};

Header.defaultProps = {
  me: {},
};

export default Header;
