// Modules
import React, { useState, useEffect } from 'react';
import PropType from 'prop-types';
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';

import { Row, Col } from 'antd';
import Actions from '../UI/Modal/components/Actions';

import { FlexColumn, Button } from '../../theme/styles';
import { Input } from '../Input/styles';
import { Label } from '../Label/styles';
import { ModalInputsContainer } from '../ModalInputsContainer/styles';
import { ModalInputsRowContainer } from '../ModalInputsRowContainer/styles';
import RenderTimeConditions from './component';
import { timeFormat, dateFormat } from './utils';

const timeTableMock = [
  {
    startDay: '',
    endDay: '',
    startHour: '',
    endHour: '',
  },
];
const errorMessageRequired = 'Este campo es obligatorio';

const validation = Yup.object().shape({
  name: Yup.string().required(errorMessageRequired),
  timeConditions: Yup.array()
    .of(
      Yup.object().shape({
        startDay: Yup.string().required(errorMessageRequired),
        endDay: Yup.string().required(errorMessageRequired),
        startHour: Yup.string().required(errorMessageRequired),
        endHour: Yup.string().required(errorMessageRequired),
      })
    )
    .required(),
});
const changeTimeTable = (index, values, setFieldValue) => (value, key) => {
  const timeConditions = values.timeConditions ? values.timeConditions : [];
  const timeTable = timeConditions.map((day, timeTableIndex) => {
    if (timeTableIndex !== index) {
      return day;
    }
    if (!value) {
      const newDay = {
        ...day,
        [key]: value,
      };
      return newDay;
    }
    return {
      ...day,
      [key]: value,
    };
  });
  setFieldValue('timeConditions', [...timeTable]);
};

const ScheduleForm = ({
  createSchedule,
  deleteModal,
  schedule,
  agentPhone,
  isSubmitting,
}) => {
  const [timeList, setTimeList] = useState([]);
  const [errorToAdd, setErrorToAdd] = useState(false);

  // const scheduleInitialValues = {
  //   name: values && values.timeConditions.length > 0? values.name : '',
  //   timeConditions: values && values.timeConditions.length > 0 ? values.timeConditions : [],
  // }
  return (
    <Formik
      initialValues={schedule}
      enableReinitialize
      onSubmit={values => {
        const data = {
          name: values.name,
          timeConditions:
            timeList && timeList.length > 0
              ? timeList.map(time => {
                  return {
                    endDay: time.endDay,
                    startDay: time.startDay,
                    startHour: time.startHour,
                    endHour: time.endHour,
                  };
                })
              : values.timeConditions.map(time => {
                  return {
                    endDay: time.endDay,
                    startDay: time.startDay,
                    startHour: time.startHour,
                    endHour: time.endHour,
                  };
                }),
          agent: agentPhone,
        };
        createSchedule({ data });
      }}
      validationSchema={validation}
    >
      {formProps => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        } = formProps;
        return (
          <form>
            <ModalInputsContainer padding={20}>
              <ModalInputsRowContainer spaceBetween>
                <FlexColumn>
                  <Label htmlFor="name" style={{ display: 'block' }}>
                    Nombre
                  </Label>
                  <Input
                    id="name"
                    placeholder="Nombre"
                    type="text"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.name && touched.name
                        ? 'text-input error'
                        : 'text-input'
                    }
                  />
                  {errors.name && touched.name && (
                    <div className="input-feedback">{errors.name}</div>
                  )}
                </FlexColumn>
              </ModalInputsRowContainer>
              <div style={{ width: '100%' }}>
                {timeTableMock.map((timeCondition, index) => {
                  return (
                    <div style={{ width: '100%' }}>
                      <Actions borderless>
                        <Button
                          primary
                          type="button"
                          onClick={() => {
                            if (
                              values.timeConditions[index] &&
                              values.timeConditions[index].startDay !== '' &&
                              values.timeConditions[index].endDay !== '' &&
                              values.timeConditions[index].startHour !== '' &&
                              values.timeConditions[index].endHour !== ''
                            ) {
                              setErrorToAdd(false);
                              setTimeList([
                                ...timeList,
                                values.timeConditions[index],
                              ]);
                              return;
                            }
                            setErrorToAdd(true);
                          }}
                        >
                          Añadir horario
                        </Button>
                        {errorToAdd ? (
                          <div styles={{ color: 'red' }}>
                            {' '}
                            No puede haber ningun campo vacío
                          </div>
                        ) : (
                          ''
                        )}
                        {timeList && timeList.length > 0
                          ? timeList.map(times => {
                              return (
                                <Row style={{ width: '100%' }}>
                                  <Col style={{ padding: '10px' }}>
                                    {times.startDay}
                                  </Col>
                                  <Col style={{ padding: '10px' }}>
                                    {times.endDay}
                                  </Col>
                                  <Col style={{ padding: '10px' }}>
                                    {times.startHour}
                                  </Col>
                                  <Col style={{ padding: '10px' }}>
                                    {times.endHour}
                                  </Col>
                                </Row>
                              );
                            })
                          : ''}
                      </Actions>
                      <RenderTimeConditions
                        timeCondition={timeCondition}
                        index={index}
                        onChange={changeTimeTable(index, values, setFieldValue)}
                        value={values ? values.timeConditions[index] : ''}
                        errors={errors}
                        touched={touched}
                        onFocus={() => setErrorToAdd(false)}
                      />
                    </div>
                  );
                })}
              </div>
            </ModalInputsContainer>
            <Actions>
              <Button
                type="button"
                onClick={deleteModal}
                disabled={isSubmitting}
              >
                Cancelar
              </Button>
              <Button
                primary
                type="submit"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                Guardar horarios
              </Button>
            </Actions>
          </form>
        );
      }}
    </Formik>
  );
};

ScheduleForm.propTypes = {
  createSchedule: PropType.func.isRequired,
  values: PropType.shape({}),
  touched: PropType.shape({}),
  errors: PropType.shape({}),
  schedule: PropType.shape({}),
  deleteModal: PropType.func.isRequired,
};

ScheduleForm.defaultProps = {
  values: {},
  touched: {},
  errors: {},
  schedule: {
    name: '',
    timeConditions: [
      {
        startDay: '',
        endDay: '',
        startHour: '',
        endHour: '',
      },
    ],
  },
};

export default ScheduleForm;
