import axios from 'axios';
import config from '../config';
import { SET_TOKEN } from '../modules/auth/actionTypes';
import { isAuthenticated, getSession } from './sessionStorage';

let api = null;
let storedToken;

export const tokenMiddleware = () => next => action => {
  const { type } = action;
  if (type === SET_TOKEN) {
    const {
      payload: { token },
    } = action;
    storedToken = token;
  }
  return next(action);
};

const getHeaders = async () => {
  let headers = {
    'Content-Type': 'application/json',
    accept: 'application/json',
  };
  if (storedToken || isAuthenticated()) {
    headers = {
      ...headers,
      authorization: storedToken || getSession().token,
    };
  }
  return headers;
};

const getInitializedApi = () => {
  if (api) {
    return api;
  }
  api = axios.create({
    baseURL: config.ENDPOINT,
    responseType: 'json',
  });
  return api;
};

export const get = async url => {
  const headers = await getHeaders();
  return getInitializedApi().get(url, { headers });
};

export const post = async (url, data) => {
  const headers = await getHeaders();
  return getInitializedApi().post(url, data, { headers });
};

const uploadAudio = async data => {
  const bodyFormData = new FormData();
  bodyFormData.append('group', data.group);
  bodyFormData.append('identifier', data.identifier);
  bodyFormData.append('agentId', data.agentId);
  bodyFormData.append('agentName', data.agentName);
  bodyFormData.append('agentPhone', data.agentPhone);
  bodyFormData.append('accountRole', data.accountRole);
  bodyFormData.append('name', data.name);
  bodyFormData.append('audio', data.audio);
  return bodyFormData;
};

export const postAudio = async (url, data) => {
  const headers = await getHeaders();
  const audio = await uploadAudio(data);
  return getInitializedApi().post(url, audio, { headers });
};

const uploadLocutionAudio = async data => {
  const bodyFormData = new FormData();
  bodyFormData.append('name', data.name);
  bodyFormData.append('audio', data.audio);
  bodyFormData.append('agentId', data.agentId);
  bodyFormData.append('agentName', data.agentName);
  bodyFormData.append('agentPhone', data.agentPhone);
  bodyFormData.append('accountRole', data.accountRole);
  return bodyFormData;
};

export const postLocutionAudio = async (url, data) => {
  const headers = await getHeaders();
  const locutionAudio = await uploadLocutionAudio(data);
  return getInitializedApi().post(url, locutionAudio, { headers });
};

export const put = async (url, data) => {
  const headers = await getHeaders();
  return getInitializedApi().put(url, data, { headers });
};

export const del = async url => {
  const headers = await getHeaders();
  return getInitializedApi().delete(url, { headers });
};
