import { takeLatest, put, call, select } from 'redux-saga/effects';

import {
  FETCH_ACCOUNTS,
  CREATE_ACCOUNT,
  DELETE_ACCOUNT,
  FETCH_ACCOUNT_BY_ID,
  FETCH_ACCOUNT_AGENTS,
  ADD_ACCOUNT_AGENT,
  UPDATE_ACCOUNT,
  FETCH_ACCOUNT_CLIENTS,
  REMOVE_ACCOUNT_AGENT,
} from './actionTypes';
import {
  fetchAccounts as getAccounts,
  fetchAccountAgents,
  fetchAccountClients as getAccountClients,
  setAccounts,
  setAccountById,
  fetchAccountAgentsSuccess,
  addAccountAgentSuccess,
  updateAccountSuccess,
  fetchAccountClientsSuccess,
  removeAccountAgentFailed,
  removeAccountAgentSuccess,
  addAccountAgentFailed,
} from './actions';
import * as api from './api';
import { deleteModal, setIsSubmitting } from '../ui/actions';

function* fetchAccounts() {
  try {
    const response = yield call(api.fetchAccounts);
    yield put(setAccounts({ accounts: response.data }));
  } catch (e) {
    console.error({ e });
  }
}

export function* watchFetchAccounts() {
  yield takeLatest(FETCH_ACCOUNTS, fetchAccounts);
}

function* fetchAccountById({ payload: { id } }) {
  try {
    const response = yield call(api.fetchAccountById, id);
    yield put(setAccountById({ account: response.data }));
  } catch (e) {
    console.error({ e });
  }
}

export function* watchFetchAccountById() {
  yield takeLatest(FETCH_ACCOUNT_BY_ID, fetchAccountById);
}

function* getAccountAgents({ payload: { id } }) {
  try {
    const response = yield call(api.fetchAccountAgents, id);
    yield put(fetchAccountAgentsSuccess({ agents: response.data }));
  } catch (e) {
    console.error({ e });
  }
}

export function* watchFetchAccountAgents() {
  yield takeLatest(FETCH_ACCOUNT_AGENTS, getAccountAgents);
}

function* fetchAccountClients({ payload: { id } }) {
  try {
    const response = yield call(api.fetchAccountClients, id);
    yield put(fetchAccountClientsSuccess(response.data));
  } catch (e) {
    console.error({ e });
  }
}

export function* watchFetchAccountClients() {
  yield takeLatest(FETCH_ACCOUNT_CLIENTS, fetchAccountClients);
}

function* createAccount({ payload }) {
  try {
    yield put(setIsSubmitting(true));
    const response = yield call(api.createAccount, payload.account);
    if (response && response.data) {
      yield put(getAccounts());
      yield put(deleteModal());
      yield put(setIsSubmitting(false));
    }
  } catch (e) {
    yield put(setIsSubmitting(false));
    console.error({ e });
  }
}

export function* watchCreateAccount() {
  yield takeLatest(CREATE_ACCOUNT, createAccount);
}

function* addAgent({ payload }) {
  try {
    const id = payload.accountId;
    yield call(api.addAgentToAccount, id, payload.data);
    yield put(addAccountAgentSuccess());
    const { info } = yield select(state => state.accounts);
    yield put(fetchAccountAgents({ id }));
    yield call(api.createLocution, info);
  } catch (e) {
    console.error({ e });
    if (
      e.response.data === 'There is already an agent with this phone number'
    ) {
      yield put(
        addAccountAgentFailed({
          message: e.response.data
            ? 'Ya existe un agente asignado a ese teléfono'
            : '',
        })
      );
    }
  }
}

export function* watchAddAgent() {
  yield takeLatest(ADD_ACCOUNT_AGENT, addAgent);
}

function* updateAccount({ payload }) {
  try {
    yield put(setIsSubmitting(true));
    yield call(api.updateAccount, payload.id, payload.data);
    yield put(updateAccountSuccess());
    yield put(setIsSubmitting(false));
  } catch (e) {
    yield put(setIsSubmitting(false));
    console.error({ e });
  }
}

export function* watchUpdateAccount() {
  yield takeLatest(UPDATE_ACCOUNT, updateAccount);
}

function* deleteAccount({ payload }) {
  try {
    yield call(api.deleteAccount, payload.account.item.id);
    yield put(getAccounts());
    if (payload.account.accountId) {
      yield put(getAccountClients({ id: payload.account.item.accountId }));
    }
  } catch (e) {
    console.error({ e });
  }
}

export function* watchDeleteAccount() {
  yield takeLatest(DELETE_ACCOUNT, deleteAccount);
}

function* removeAccountAgent({ payload }) {
  try {
    yield call(api.removeAccountAgent, payload.id, payload.agentId);
    yield put(removeAccountAgentSuccess());
    yield put(fetchAccountAgents({ id: payload.id }));
  } catch (e) {
    console.error(e);
    yield put(removeAccountAgentFailed());
  }
}

export function* watchRemoveAccountAgent() {
  yield takeLatest(REMOVE_ACCOUNT_AGENT, removeAccountAgent);
}
