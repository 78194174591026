// Modules
import { connect } from 'react-redux';

// Layout Screen
import View from './view';

// Actions
import { createBehaviour } from '../../modules/behaviours/actions';
import { createAccount } from '../../modules/accounts/actions';
import { fetchAgentById } from '../../modules/agents/actions';
import { fetchReasons } from '../../modules/reasons/actions';
import { fetchDestinations } from '../../modules/destinations/actions';
import { fetchLocutions } from '../../modules/locutions/actions';
import { fetchSchedules } from '../../modules/schedules/actions';
import { deleteModal } from '../../modules/ui/actions';

export default connect(
  state => ({
    agent: state.agents.agentById,
    locutions: state.locutions.list,
    destinations: state.destinations.list,
    reasons: state.reasons.list,
    schedules: state.schedules.list,
    isSubmitting: state.ui.isSubmitting,
  }),
  {
    createBehaviour,
    createAccount,
    fetchReasons,
    fetchDestinations,
    fetchLocutions,
    fetchSchedules,
    fetchAgentById,
    deleteModal,
  }
)(View);
