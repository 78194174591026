import { takeLatest, call, put } from 'redux-saga/effects';

import { fetchPopulationsSuccess, fetchPopulationsFailed } from './actions';

import { FETCH_POPULATIONS } from './actionTypes';

import * as api from './api';

function* fetchPopulations() {
  try {
    const response = yield call(api.fetchPopulations);
    yield put(fetchPopulationsSuccess(response.data));
  } catch (e) {
    console.error({ e });
    yield put(fetchPopulationsFailed());
  }
}

export function* watchFetchPopulations() {
  yield takeLatest(FETCH_POPULATIONS, fetchPopulations);
}
