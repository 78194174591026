import { connect } from 'react-redux';

import View from './view';

import {
  fetchAccountById,
  fetchAccountAgents,
  addAccountAgent,
  updateAccount,
  removeAccountAgent,
  getAgentAccountInfo,
} from '../../modules/accounts/actions';

import { fetchAgents } from '../../modules/agents/actions';
import { fetchUserById, fetchUsers } from '../../modules/users/actions';

export default connect(
  state => ({
    account: state.accounts.accountById,
    accountAgents: state.accounts.agents,
    errorMessage: state.accounts.errorMessage,
    agents: state.agents.list,
    userById: state.users.userById,
    me: state.auth.me,
    isSubmitting: state.ui.isSubmitting,
  }),
  {
    fetchAccountById,
    fetchAccountAgents,
    addAccountAgent,
    updateAccount,
    fetchAgents,
    removeAccountAgent,
    fetchUserById,
    fetchUsers,
    getAgentAccountInfo,
  }
)(View);
