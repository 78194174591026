export const FETCH_REASONS = 'FETCH_REASONS';
export const FETCH_REASON_BY_ID = 'FETCH_REASON_BY_ID';
export const SET_REASONS = 'SET_REASONS';
export const SET_REASON_BY_ID = 'SET_REASON_BY_ID';
export const FETCH_REASONS_FAILED = 'FETCH_REASONS_FAILED';
export const CREATE_REASON = 'CREATE_REASON';
export const DELETE_REASON = 'DELETE_REASON';
export const UPDATE_REASON = 'UPDATE_REASON';
export const UPDATE_REASON_SUCCESS = 'UPDATE_REASON_SUCCESS';
export const UPDATE_REASON_FAILED = 'UPDATE_REASON_FAILED';
