import { takeLatest, call, put, select } from 'redux-saga/effects';

import {
  createIntegrationSuccess,
  createIntegrationFailed,
  fetchIntegrationSuccess,
  fetchIntegrationFailed,
  editIntegrationSuccess,
  editIntegrationFailed,
} from './actions';

import {
  CREATE_INTEGRATION,
  FETCH_INTEGRATION,
  EDIT_INTEGRATION,
} from './actionTypes';

import * as api from './api';
import { setIsSubmitting } from '../ui/actions';

/**
 * CreateIntegration
 * @param payload
 * @returns {IterableIterator<*>}
 */
function* createIntegration({ payload }) {
  try {
    const { id } = yield select(state => state.auth.me);
    const integrationData = payload.data;
    integrationData.clientId = id;
    yield put(setIsSubmitting(true));
    yield call(api.createIntegration, integrationData);
    yield put(createIntegrationSuccess());
    yield put(setIsSubmitting(false));
  } catch (e) {
    yield put(setIsSubmitting(false));
    console.error({ e });
    yield put(createIntegrationFailed());
  }
}

export function* watchCreateIntegration() {
  yield takeLatest(CREATE_INTEGRATION, createIntegration);
}

/**
 * Fetch Integration Info
 * @param payload
 * @returns {IterableIterator<*>}
 */
function* fetchIntegration({ payload }) {
  try {
    const response = yield call(api.fetchIntegration, payload);

    if (response && response.status === 200) {
      yield put(fetchIntegrationSuccess(response.data[0]));
    }
  } catch (e) {
    console.error({ e });
    yield put(fetchIntegrationFailed());
  }
}

export function* watchFetchIntegrationById() {
  yield takeLatest(FETCH_INTEGRATION, fetchIntegration);
}

function* editIntegration({ payload: { data } }) {
  try {
    const values = {
      scheduleRequestUrl: data.scheduleRequestUrl,
      schedulePriceType: data.schedulePriceType,
      scheduleAddressUrl: data.scheduleAddressUrl,
      scheduleAddressType: data.scheduleAddressType,
      schedulePriceUrl: data.schedulePriceUrl,
      scheduleRequestType: data.scheduleRequestType,
    };

    yield put(setIsSubmitting(true));
    const response = yield call(api.editIntegration, { id: data.id, values });
    if (response && response.status === 200) {
      yield put(editIntegrationSuccess());
      yield put(setIsSubmitting(false));
    }
  } catch (e) {
    yield put(setIsSubmitting(false));
    console.error({ e });
    yield put(editIntegrationFailed());
  }
}

export function* watchEditIntegration() {
  yield takeLatest(EDIT_INTEGRATION, editIntegration);
}
