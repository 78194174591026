import { connect } from 'react-redux';
import View from './view';

import { logout } from '../../modules/auth/actions';

export default connect(
  state => ({
    me: state.auth.me,
  }),
  {
    logout,
  }
)(View);
