// Modules
import { connect } from 'react-redux';

// UI Layout
import UI from './view';

// Redux Connector
export default connect(
  state => ({
    modal: state.ui.modal,
    user: state.auth.me,
  }),
  {}
)(UI);
