import { handleActions } from 'redux-actions';

import { fetchSchedulesSuccess, fetchScheduleByIdSuccess } from './actions';

const defaultState = {
  list: [],
};

export default handleActions(
  {
    [fetchSchedulesSuccess]: (state, { payload }) => ({
      ...state,
      list: payload.data,
    }),
    [fetchScheduleByIdSuccess]: (state, { payload }) => ({
      ...state,
      byId: payload,
    }),
  },
  defaultState
);
