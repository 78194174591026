import { get, post } from '../../lib/restClient';

export const checkLogin = async () => get('/users/me');

export const login = async data => post('/auth/login', data);

export const verificateEmail = async data =>
  post('/users/verificate-email', data);

export const me = async data => get('/users/me', data);
