// Modules
import { connect } from 'react-redux';

// Layout Screen
import View from './view';

// Actions
import { createAccount } from '../../modules/accounts/actions';
import { deleteModal } from '../../modules/ui/actions';

export default connect(
  state => ({
    me: state.auth.me,
    isSubmitting: state.ui.isSubmitting,
  }),
  {
    createAccount,
    deleteModal,
  }
)(View);
