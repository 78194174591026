// Dependencies
import styled from 'styled-components';

export const ReSubmittingLabel = styled.div`
  color: #93dadb;
  font-size: 12px;
`;

export const FormRow = styled.div`
  display: flex;
  ${props =>
    props.margin &&
    `
    margin: ${props.margin};
  `};
`;

export const GoBackContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
`;

export const GoBackLink = styled.span`
  color: #3c3e49;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  text-decoration: none;
  border-bottom: 2px solid black;
  cursor: pointer;
`;
