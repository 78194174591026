import { takeLatest, put, call } from 'redux-saga/effects';

import {
  FETCH_LOCUTIONS,
  CREATE_LOCUTION,
  DELETE_LOCUTION,
  FETCH_LOCUTION_BY_ID,
  UPDATE_LOCUTION,
} from './actionTypes';
import {
  fetchLocutions as getLocutions,
  setLocutions,
  setLocutionById,
  updateLocution,
} from './actions';
import * as api from './api';
import { deleteModal, setIsSubmitting } from '../ui/actions';

function* fetchLocutions({ payload: { phone } }) {
  try {
    const response = yield call(api.fetchLocutions, phone);
    yield put(setLocutions({ locutions: response.data.data }));
  } catch (e) {
    console.error({ e });
  }
}

export function* watchFetchLocutions() {
  yield takeLatest(FETCH_LOCUTIONS, fetchLocutions);
}

function* fetchLocutionById({ payload: { id } }) {
  try {
    const response = yield call(api.fetchLocutionById, id);
    yield put(setLocutionById({ locution: response.data }));
  } catch (e) {
    console.error({ e });
  }
}
export function* watchFetchLocutionById() {
  yield takeLatest(FETCH_LOCUTION_BY_ID, fetchLocutionById);
}

function* createLocution({ payload }) {
  try {
    yield put(setIsSubmitting(true));
    const response = yield call(api.createLocution, payload.locution);
    if (response && response.data) {
      yield put(getLocutions({ phone: payload.locution.agentPhone }));
      yield put(deleteModal());
      yield put(setIsSubmitting(false));
    }
  } catch (e) {
    yield put(setIsSubmitting(false));
    console.error({ e });
  }
}

export function* watchCreateLocution() {
  yield takeLatest(CREATE_LOCUTION, createLocution);
}

function* update({ payload }) {
  try {
    yield put(setIsSubmitting(true));
    yield call(api.updateLocution, payload.id, payload.locution);
    yield put(updateLocution());
    yield put(deleteModal());
    yield put(setIsSubmitting(false));
  } catch (e) {
    yield put(setIsSubmitting(false));
    console.error(e);
  }
}

export function* watchUpdateLocution() {
  yield takeLatest(UPDATE_LOCUTION, update);
}

function* deleteLocution({ payload }) {
  try {
    const response = yield call(api.deleteLocution, payload.locution.item.id);
    if (response && response.data) {
      yield put(getLocutions({ phone: payload.locution.phone }));
    }
  } catch (e) {
    console.error({ e });
  }
}

export function* watchDeleteLocution() {
  yield takeLatest(DELETE_LOCUTION, deleteLocution);
}
