// Modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Commons
import Headline from '../../components/Headline';
import { Container } from '../../theme/styles';

import Table from '../../components/TableV2';

class PathsScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table: {
        page: 1,
        order: 'desc',
        orderBy: 'name',
      },
    };
  }

  componentDidMount() {
    const { fetchPaths } = this.props;
    fetchPaths();
  }

  render() {
    const {
      table: { page, order, orderBy },
    } = this.state;
    const { paths } = this.props;
    return (
      <Container>
        <Headline title="Entidades - Trayectos" />
        <Table
          page={page}
          order={order}
          orderBy={orderBy}
          rowsPerPage={12}
          fields={[
            {
              key: 'phone',
              label: 'Teléfono',
              format: 'default',
            },
            {
              key: 'stopOrigin',
              label: 'Origen',
              format: 'default',
              color: '#ABABB8',
            },
            {
              key: 'stopDestination',
              label: 'Destino',
              format: 'default',
            },
            {
              key: 'company',
              label: 'Empresa',
              format: 'default',
            },
            {
              key: 'search',
              format: 'embed',
            },
          ]}
          data={paths.map((path, index) => ({
            key: index,
            phone: path.phone,
            stopOrigin: path.stopOriginCode,
            stopDestination: path.stopDestinationCode,
            company: path.company,
          }))}
          onChangeField={(key, value) => {
            this.setState(prevState => ({
              ...prevState,
              table: {
                ...prevState.table,
                [key]: value,
              },
            }));
          }}
          hasFilter
          filterBy="company"
        />
      </Container>
    );
  }
}

PathsScreen.propTypes = {
  match: PropTypes.shape({}).isRequired,
  fetchPaths: PropTypes.func.isRequired,
  paths: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default PathsScreen;
