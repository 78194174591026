// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Commons
import Headline from '../../components/Headline';
import Table from '../../components/TableV2';
import LocutionForm from '../../components/LocutionForm';
import EditLocutionForm from '../../components/EditLocutionForm';
import DeleteModal from '../../components/DeleteModal';
import { SectionContainer, Button } from '../../theme/styles';

// Assets
import ConfigImg from './img/tune.svg';
import TrashImg from './img/trash-can-outline.svg';

class LocutionsScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table: {
        page: 1,
        order: 'desc',
        orderBy: 'name',
      },
    };
  }

  componentDidMount() {
    const {
      fetchLocutions,
      match: {
        params: { phone },
      },
    } = this.props;
    fetchLocutions({ phone });
  }

  createLocution = () => {
    const {
      createModal,
      match: {
        params: { id, phone },
      },
      agent: { name },
      me: { role },
    } = this.props;
    createModal({
      title: 'Nueva Locución',
      width: 400,
      body: (
        <LocutionForm
          agentId={id}
          agentName={name}
          accountRole={role}
          agentPhone={phone}
        />
      ),
    });
  };

  updateLocution = ({ id }) => {
    const { createModal } = this.props;
    createModal({
      title: 'Editar Locución',
      width: 400,
      body: <EditLocutionForm id={id} />,
    });
  };

  render() {
    const {
      table: { page, order, orderBy },
    } = this.state;
    const {
      locutions,
      deleteLocution,
      createModal,
      match: {
        params: { phone },
      },
    } = this.props;
    // ===========================
    return (
      <SectionContainer>
        <Headline
          title="Locuciones Agente"
          actions={
            <Button primary onClick={this.createLocution}>
              Nuevo Locución
            </Button>
          }
        />
        <Table
          page={page}
          order={order}
          orderBy={orderBy}
          rowsPerPage={12}
          fields={[
            {
              key: 'name',
              label: 'Nombre',
              format: 'default',
            },
            {
              key: 'audio',
              label: 'Archivo',
              format: 'default',
            },
            {
              key: 'actionsSearch',
              label: 'Acciones',
              format: 'embed',
            },
          ]}
          data={locutions.map((locution, index) => ({
            key: index,
            name: locution.name,
            audio: locution.audio,
            actionsSearch: [
              {
                icon: (
                  <img
                    alt="config-icon"
                    src={ConfigImg}
                    width="18px"
                    height="18px"
                  />
                ),
                color: 'green',
                text: 'Editar',
                handler: () => this.updateLocution({ id: locution.id }),
              },
              {
                icon: (
                  <img
                    alt="trash-icon"
                    src={TrashImg}
                    width="13px"
                    height="15px"
                  />
                ),
                color: 'red',
                text: 'Eliminar',
                handler: () =>
                  createModal({
                    title: 'Eliminar Locución',
                    width: 480,
                    body: (
                      <DeleteModal
                        item={{ id: locution.id }}
                        phone={phone}
                        title="¿Estas seguro que deseas eliminar esta Locución?"
                        removeText="Eliminar Locución"
                        deleteAction={deleteLocution}
                      />
                    ),
                  }),
              },
            ],
          }))}
          onChangeField={(key, value) => {
            this.setState(prevState => ({
              ...prevState,
              table: {
                ...prevState.table,
                [key]: value,
              },
            }));
          }}
          hasFilter
        />
      </SectionContainer>
    );
  }
}

LocutionsScreen.propTypes = {
  locutions: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired,
  fetchLocutions: PropTypes.func.isRequired,
  deleteLocution: PropTypes.func.isRequired,
  createModal: PropTypes.func.isRequired,
};

export default LocutionsScreen;
