import { handleActions } from 'redux-actions';

import {
  fetchDestinationsSuccess,
  fetchDestinationByIdSuccess,
} from './actions';

const defaultState = {
  list: [],
};

export default handleActions(
  {
    [fetchDestinationsSuccess]: (state, { payload }) => ({
      ...state,
      list: payload.data,
    }),
    [fetchDestinationByIdSuccess]: (state, { payload }) => ({
      ...state,
      byId: payload,
    }),
  },
  defaultState
);
