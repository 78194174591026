// Modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Commons
import Headline from '../../components/Headline';
import Table from '../../components/TableV2';
import SubaccountForm from '../../components/SubaccountForm';
import DeleteModal from '../../components/DeleteModal';
import { SectionContainer, Button } from '../../theme/styles';

// Assets
import ConfigImg from './img/tune.svg';
import TrashImg from './img/trash-can-outline.svg';

class SubaccountsScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table: {
        page: 1,
        order: 'asc',
        orderBy: 'name',
      },
    };
  }

  componentDidMount() {
    const {
      fetchAccountClients,
      match: {
        params: { id },
      },
    } = this.props;
    fetchAccountClients({ id });
  }

  createAccount = () => {
    const {
      createModal,
      match: {
        params: { id },
      },
    } = this.props;
    createModal({
      title: 'Subcuenta Nueva',
      width: 560,
      body: <SubaccountForm accountId={id} />,
    });
  };

  render() {
    const {
      table: { page, order, orderBy },
    } = this.state;
    const {
      history,
      accounts,
      deleteAccount,
      createModal,
      match: {
        params: { id },
      },
    } = this.props;
    // ==================
    return (
      <SectionContainer>
        <Headline
          title="Subcuentas"
          actions={
            <Button primary onClick={this.createAccount}>
              Nueva Subcuenta
            </Button>
          }
        />
        <Table
          page={page}
          order={order}
          orderBy={orderBy}
          rowsPerPage={12}
          fields={[
            {
              key: 'name',
              label: 'Nombre',
              format: 'default',
            },
            {
              key: 'type',
              label: 'Type',
              format: 'default',
              color: '#ABABB8',
            },
            {
              key: 'date',
              label: 'Fecha de creación',
              format: 'date',
              color: '#ABABB8',
            },
            {
              key: 'author',
              label: 'Creado por',
              format: 'default',
              color: '#66CCCC',
            },
            {
              key: 'actions',
              label: 'Acciones',
              format: 'embed',
            },
          ]}
          data={accounts.map((account, index) => ({
            key: index,
            name: account.name,
            type: account.type,
            author: 'Admin',
            actions: [
              {
                icon: (
                  <img
                    alt="config-icon"
                    src={ConfigImg}
                    width="18px"
                    height="18px"
                  />
                ),
                color: 'green',
                text: 'Configurar',
                handler: () =>
                  history.push(`/accounts/${account.id}`, { accountId: id }),
              },
              {
                icon: (
                  <img
                    alt="trash-icon"
                    src={TrashImg}
                    width="13px"
                    height="15px"
                  />
                ),
                color: 'red',
                text: 'Eliminar',
                handler: () =>
                  createModal({
                    title: 'Eliminar Subcuenta',
                    width: 480,
                    body: (
                      <DeleteModal
                        item={{ id: account.id, accountId: id }}
                        title="¿Estas seguro que deseas eliminar este Subcuenta?"
                        removeText="Eliminar Subcuenta"
                        deleteAction={deleteAccount}
                      />
                    ),
                  }),
              },
            ],
          }))}
          onChangeField={(key, value) => {
            this.setState(prevState => ({
              ...prevState,
              table: {
                ...prevState.table,
                [key]: value,
              },
            }));
          }}
        />
      </SectionContainer>
    );
  }
}

SubaccountsScreen.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  accounts: PropTypes.arrayOf(PropTypes.shape({})),
  history: PropTypes.shape({}).isRequired,
  fetchAccountClients: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  createModal: PropTypes.func.isRequired,
};

SubaccountsScreen.defaultProps = {
  accounts: [],
};

export default SubaccountsScreen;
