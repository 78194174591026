import styled from 'styled-components';

export const Layout = styled.table`
  width: 100%;
`;

export const OutlineWrapper = styled.div`
  width: 100%;
  overflow: auto;
`;

export const Container = styled.div`
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
`;
