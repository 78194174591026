import styled from 'styled-components';

export const RememberPasswordLink = styled.span`
  color: #3c3e49;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
  text-decoration: none;
  border-bottom: 2px solid black;
  cursor: pointer;
`;
