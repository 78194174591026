// Modules
import { connect } from 'react-redux';

// Screen Layout
import View from './view';

// Actions
import { fetchPopulations } from '../../modules/populations/actions';

// Redux Connector
export default connect(
  state => ({
    populations: state.populations.list,
  }),
  {
    fetchPopulations,
  }
)(View);
