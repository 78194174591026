import React, { Component, useState } from 'react';
import { Redirect } from 'react-router-dom';
import PropType from 'prop-types';
import LoginForm from './LoginForm';
import ResetPasswordForm from './ResetPasswordForm';
import NewPasswordForm from './NewPasswordForm';
import SetPasswordForm from './SetPasswordForm';
import { Container, Copyright, FooterContainer } from './styles';
import { FormRow } from '../../theme/styles';
import LoginFormColumn from '../../components/LoginFormColumn';
import BackgroundImageLoginColumn from '../../components/BackgroundLoginColumn';
import BackgroundImage from '../../components/BackgroundImage';
import RememberPasswordLink from '../../components/RememberPasswordLink';

const MODE_LOGIN = 'login';
const MODE_RESET = 'reset';
const MODE_NEW_PASSWORD = 'new-password';
const MODE_SET_PASSWORD = 'set-password';

/*
const totalLlamadas = 1000;
const totalMotivo1 = 30;
const totalMotivo2 = 20;

const data = [
  { angle: 1, color: '#00aced' },
  { angle: 2, color: '#dd4b39' },
  { angle: 3, color: '#007bb6' },
];

const StackedRadialChart = () => {
  const total = data.reduce((sum, { angle }) => sum + angle, 0);
  let currentAngle = 0;

  return (
    <RadialChart
      data={data.map(({ angle, color }, index) => {
        const percentage = angle / total;
        const startAngle = currentAngle;
        const endAngle = currentAngle + percentage * Math.PI * 2;

        currentAngle = endAngle;

        return { angle, color, percentage, startAngle, endAngle };
      })}
      width={300}
      height={300}
      showLabels
      labelsRadiusMultiplier={1.2}
      labelsStyle={{ fontSize: 12, fill: '#222' }}
      colorType="literal"
      colorDomain={[0, 1, 2]}
      colorRange={['#00aced', '#dd4b39', '#007bb6']}
      innerRadius={80}
      radius={100}
      padAngle={0.04}
      style={{ stroke: '#fff', strokeWidth: 2 }}
    />
  );
};

export default StackedRadialChart;

/*const data = [
  {x: 'Llamadas que pasan a un agente', y: 75},
  {x: 'Llamadas que no pasan a un agente', y: 25},
  {x: 'AI no detecta motivo de llamada', y: 40},
  {x: 'Sin reintento', y: 35},
];

const total = data.reduce((acc, d) => acc + d.y, 0);

const formattedData = data.map(d => ({
  ...d,
  percentage: ((d.y / total) * 100).toFixed(2) + '%',
}));

const barLabelStyles = {
  fontSize: '14px',
  textAnchor: 'middle',
  fill: '#fff',
};

const valueLabelStyles = {
  fontSize: '12px',
  textAnchor: 'middle',
  fill: '#000',
};

const Dashboard = () => (
  <XYPlot xType="ordinal" width={500} height={300} xDistance={100}>
    <XAxis />
    <YAxis />
    <VerticalBarSeries data={formattedData.slice(0, 2)} color="#008000" />
    <VerticalBarSeries data={formattedData.slice(2)} color="#ff0000" />
    <LabelSeries
      data={formattedData.slice(0, 2)}
      labelAnchorX="middle"
      labelAnchorY="text-before-edge"
      style={barLabelStyles}
      getLabel={d => d.y}
    />
    <LabelSeries
      data={formattedData.slice(2)}
      labelAnchorX="middle"
      labelAnchorY="text-before-edge"
      style={barLabelStyles}
      getLabel={d => d.y}
    />
    <LabelSeries
      data={formattedData.slice(0, 2)}
      labelAnchorX="middle"
      labelAnchorY="text-after-edge"
      style={valueLabelStyles}
      getLabel={d => d.percentage}
    />
    <LabelSeries
      data={formattedData.slice(2)}
      labelAnchorX="middle"
      labelAnchorY="text-after-edge"
      style={valueLabelStyles}
      getLabel={d => d.percentage}
    />
  </XYPlot>
);

export default Dashboard; */

/*const data = [
  {
    x: 'Motivo 1',
    y: totalMotivo1,
    porcentaje: (totalMotivo1 / totalLlamadas) * 100,
  },
  {
    x: 'Motivo 2',
    y: totalMotivo2,
    porcentaje: (totalMotivo2 / totalLlamadas) * 100,
  },
  // Agregar aquí los demás motivos
];

const BarChart = () => {
  const [hoveredValue, setHoveredValue] = React.useState(null);

  const onMouseLeave = () => {
    setHoveredValue(null);
  };

  const onValueMouseOver = value => {
    setHoveredValue(value);
  };

  return (
    <FlexibleXYPlot
      xType="ordinal"
      height={300}
      margin={{ left: 70, right: 50, top: 20, bottom: 70 }}
    >
      <XAxis title="Motivos de llamada" tickLabelAngle={-45} />
      <YAxis title="Número de llamadas" />
      <VerticalBarSeries
        data={data}
        onValueMouseOver={onValueMouseOver}
        onValueMouseOut={onMouseLeave}
        color="#1a75ff"
      />
      {hoveredValue && (
        <Hint value={hoveredValue}>
          <div
            style={{
              background: 'white',
              padding: '5px',
              border: '1px solid #ccc',
            }}
          >
            <div style={{ fontWeight: 'bold' }}>{hoveredValue.x}</div>
            <div>Total: {hoveredValue.y}</div>
            <div>Porcentaje: {hoveredValue.porcentaje.toFixed(2)}%</div>
          </div>
        </Hint>
      )}
      <VerticalBarSeries
        data={data.map(({ x, y }) => ({ x, y: totalLlamadas }))}
        opacity={0.2}
        color="#ccc"
      />
    </FlexibleXYPlot>
  );
};

export default BarChart; 

const ChartExample = () => {
  const data = [
    { x: 'Llamadas', y: 100 },
    { x: 'Duración Total', y: 500 },
    { x: 'Duración Media', y: 250 },
  ];

  const retryData = [
    { y: 10, x: 'Reintento' },
    { y: 90, x: 'No Reintento' },
  ];

  const radialData = [
    { angle: 10, label: 'Reintento' },
    { angle: 90, label: 'No Reintento' },
  ];

  return (
    <div>
      <XYPlot width={300} height={300} xType="ordinal">
        <VerticalBarSeries data={data} />
      </XYPlot>

      <XYPlot width={300} height={300} xType="ordinal">
        <HorizontalBarSeries data={retryData} />
      </XYPlot>

      <RadialChart
        data={radialData}
        width={300}
        height={300}
        showLabels={true}
        labelsRadiusMultiplier={0.8}
      />

      <DiscreteColorLegend
        items={['Reintento', 'No Reintento']}
        orientation="horizontal"
      />
    </div>
  );
};

// export default ChartExample; */

class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: MODE_LOGIN,
      token: null,
    };
  }

  componentDidMount() {
    const {
      match: {
        path,
        params: { token = null, id = null },
      },
    } = this.props;

    if (path.indexOf('/reset-password/:token') !== -1) {
      this.setState({
        mode: MODE_NEW_PASSWORD,
        token,
      });
    }
    if (path.indexOf('/:id/set-password') !== -1) {
      this.setState({
        mode: MODE_SET_PASSWORD,
        userId: id,
      });
    }
  }

  goBack = () => this.setState({ mode: MODE_LOGIN });

  render() {
    const { mode, token, userId } = this.state;
    const { userProfile, history } = this.props;
    if (userProfile.email) {
      return <Redirect to="/agents" />;
    }
    return (
      <Container>
        <LoginFormColumn>
          {mode === MODE_LOGIN && (
            <div>
              <LoginForm history={history} />
              <FormRow>
                <RememberPasswordLink
                  onClick={() =>
                    this.setState({
                      mode: MODE_RESET,
                    })
                  }
                >
                  ¿Olvidaste tu contraseña?
                </RememberPasswordLink>
              </FormRow>
            </div>
          )}
          {mode === MODE_RESET && <ResetPasswordForm goBack={this.goBack} />}
          {mode === MODE_NEW_PASSWORD && <NewPasswordForm token={token} />}
          {mode === MODE_SET_PASSWORD && (
            <SetPasswordForm goBack={this.goBack} userId={userId} />
          )}
        </LoginFormColumn>
        <BackgroundImageLoginColumn>
          <BackgroundImage />
        </BackgroundImageLoginColumn>
        <FooterContainer>
          {/* <Copyright>
            &copy; Nettivoz S.L.
            {new Date().getFullYear()}
            &#8212; Todos los derechos reservados
          </Copyright> */}
          {/* <Copyright alignment="right">Política de privacidad</Copyright>
          <Copyright alignment="right">Terminos de uso</Copyright> */}
        </FooterContainer>
      </Container>
    );
  }
}

LoginScreen.defaultProps = {
  userProfile: {},
};

LoginScreen.propTypes = {
  userProfile: PropType.shape({}),
  history: PropType.shape({}).isRequired,
  match: PropType.shape({}).isRequired,
};

export default LoginScreen;
