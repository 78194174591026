import React from 'react';

import { Container, Input, Checkmark } from './styles';

const InputCheckbox = ({ label, checked = false, ...props }) => (
  <Container>
    {!!label && label}
    <Input {...props} />
    <Checkmark checked={checked} />
  </Container>
);

export default InputCheckbox;
