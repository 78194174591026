// Modules
import React from 'react';
import PropTypes from 'prop-types';

// Commons
import { Layout, Title, Headline } from './styles';

const ErrorScreen = ({ title, headline }) => (
  <Layout>
    <Title>{title}</Title>
    <Headline>{headline}</Headline>
  </Layout>
);

ErrorScreen.propTypes = {
  title: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
};

export default ErrorScreen;
