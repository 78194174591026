import styled from 'styled-components';
import Select from '../../components/Select';

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 300px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 0 20px;
  margin-bottom: 40px;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 4px 10px 0 rgb(0 0 0 / 5%);
`;

export const FormRow = styled.div`
  display: flex;
  padding-right: 20px;
  margin-bottom: 12px;

  ${props => props.marginR && `margin-right: ${props.marginR}px;`}
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  flex: 1;

  ${props => props.flexValue && `flex: ${props.flexValue}`}
  ${props => props.flexEnd && `align-items: flex-end;`}
`;

export const AddText = styled.label`
  color: #243847;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
`;

export const AddAgentSelect = styled(Select)`
  margin: 0 10px;
`;

export const ErrorMessageContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

export const ErrorMessageText = styled.p`
  text-align: end;
  margin-top: 0;
  padding-right: 3rem;
`;
