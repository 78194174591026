// Modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Commons
import Headline from '../../components/Headline';
import { Container, Button } from '../../theme/styles';

import ReasonsList from './components/ReasonsList';
import ReasonForm from '../../components/ReasonForm';

class ReasonsScreen extends Component {
  createReason = id => {
    const { createModal } = this.props;
    createModal({
      title: 'Nuevo Motivo',
      width: 520,
      body: <ReasonForm agentId={id} />,
    });
  };

  render() {
    const {
      match: {
        params: { id },
      },
      history: { push },
      me: { role },
    } = this.props;

    return (
      <Container>
        <Headline
          title="Motivos"
          actions={
            <div>
              <Button
                secondary
                style={{ marginRight: '8px' }}
                onClick={() => push('/agents')}
              >
                Volver a la lista de agentes
              </Button>
              <Button
                disabled={role !== 'SUPERADMIN' && role !== 'ADMIN'}
                primary
                onClick={() => this.createReason(id)}
              >
                Nuevo Motivo
              </Button>
            </div>
          }
        />
        <ReasonsList agentId={id} />
      </Container>
    );
  }
}

ReasonsScreen.propTypes = {
  match: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  createModal: PropTypes.func.isRequired,
};

export default ReasonsScreen;
