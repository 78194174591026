import { connect } from 'react-redux';

import View from './view';

import { deleteAudio } from '../../modules/audios/actions';
import { fetchAgentAudios } from '../../modules/agents/actions';

import { createModal } from '../../modules/ui/actions';

export default connect(
  state => ({
    me: state.auth.me,
    agent: state.agents.agentById,
    audios: state.agents.agentAudios,
  }),
  {
    fetchAgentAudios,
    deleteAudio,
    createModal,
  }
)(View);
