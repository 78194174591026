import { createActions } from 'redux-actions';

import {
  FETCH_AUDIOS,
  FETCH_AUDIO_BY_ID,
  SET_AUDIOS,
  SET_AUDIO_BY_ID,
  FETCH_AUDIOS_FAILED,
  CREATE_AUDIO,
  DELETE_AUDIO,
} from './actionTypes';

export const { fetchAudios, setAudios, setAudioById, setError } = createActions(
  FETCH_AUDIOS,
  SET_AUDIOS,
  SET_AUDIO_BY_ID,
  FETCH_AUDIOS_FAILED
);

export const { createAudio, deleteAudio, fetchAudioById } = createActions({
  [CREATE_AUDIO]: audio => ({ audio }),
  [DELETE_AUDIO]: audio => ({ audio }),
  [FETCH_AUDIO_BY_ID]: id => ({ id }),
});
