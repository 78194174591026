import { createActions } from 'redux-actions';

import {
  FETCH_SCHEDULES,
  FETCH_SCHEDULES_SUCCESS,
  FETCH_SCHEDULES_FAILED,
  FETCH_SCHEDULE_BY_ID,
  FETCH_SCHEDULE_BY_ID_SUCCESS,
  FETCH_SCHEDULE_BY_ID_FAILED,
  CREATE_SCHEDULE,
  CREATE_SCHEDULE_SUCCESS,
  CREATE_SCHEDULE_FAILED,
  UPDATE_SCHEDULE,
  UPDATE_SCHEDULE_SUCCESS,
  UPDATE_SCHEDULE_FAILED,
  DELETE_SCHEDULE,
  DELETE_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_FAILED,
} from './actionTypes';

export const {
  fetchSchedules,
  fetchSchedulesSuccess,
  fetchSchedulesFailed,
  fetchScheduleById,
  fetchScheduleByIdSuccess,
  fetchScheduleByIdFailed,
  createSchedule,
  createScheduleSuccess,
  createScheduleFailed,
  updateSchedule,
  updateScheduleSuccess,
  updateScheduleFailed,
  deleteSchedule,
  deleteScheduleSuccess,
  deleteScheduleFailed,
} = createActions(
  FETCH_SCHEDULES,
  FETCH_SCHEDULES_SUCCESS,
  FETCH_SCHEDULES_FAILED,
  FETCH_SCHEDULE_BY_ID,
  FETCH_SCHEDULE_BY_ID_SUCCESS,
  FETCH_SCHEDULE_BY_ID_FAILED,
  CREATE_SCHEDULE,
  CREATE_SCHEDULE_SUCCESS,
  CREATE_SCHEDULE_FAILED,
  UPDATE_SCHEDULE,
  UPDATE_SCHEDULE_SUCCESS,
  UPDATE_SCHEDULE_FAILED,
  DELETE_SCHEDULE,
  DELETE_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_FAILED
);
