import { connect } from 'react-redux';

import LoginScreen from './view';

export default connect(
  state => ({
    userProfile: state.users.userProfile,
  }),
  {}
)(LoginScreen);
