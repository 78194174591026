import { get, postLocutionAudio, del, put } from '../../lib/restClient';

export const fetchLocutions = async phone => get(`/locutions?phone=${phone}`);

export const fetchLocutionById = async id => get(`/locutions/${id}`);

export const createLocution = async data =>
  postLocutionAudio('/locutions', data);

export const updateLocution = async (id, data) => put(`/locutions/${id}`, data);

export const deleteLocution = async id => del(`/locutions/${id}`);
