import styled from 'styled-components';
import { Link } from 'react-router-dom';

import ArrowImage from '../TableV2/components/Footer/img/Path.svg';

export const TableNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 108px;
`;

export const Title = styled.h2`
  margin: 0;
  color: #243847;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  flex: 1;
`;

export const ColRight = styled.div`
  display: flex;
`;

export const LinkContainer = styled(Link)`
  display: flex;
  align-items: center;
`;

export const ArrowIcon = styled.div`
  height: 16px;
  width: 16px;
  background-image: url(${ArrowImage});
  background-size: cover;
  margin-right: 10px;
  opacity: 0.5;
`;

export const TextInfo = styled.span`
  font-size: 14px;
  margin-left: 20px;
  justify-self: center;
  align-self: center;
`;
