import { takeLatest, put, call } from 'redux-saga/effects';

import { CHECK_LOGIN, LOGIN, LOGOUT, FETCH_ME } from './actionTypes';
import {
  checkLoginSuccess,
  checkLoginFailed,
  setMe,
  setToken,
  loginFailed,
} from './actions';
import * as api from './api';

import {
  getSession,
  setSession,
  logout as sessionLogout,
} from '../../lib/sessionStorage';
import { setUser } from '../users/actions';
import { setIsSubmitting } from '../ui/actions';

function* checkLogin() {
  try {
    const token = yield getSession();

    if (token) {
      const response = yield call(api.checkLogin);
      if (response && response.data) {
        // yield put(setUser({ user: response.data }));
        yield put(checkLoginSuccess());
        window.location.href = '/';
      } else {
        yield put(checkLoginFailed());
      }
    } else {
      yield put(checkLoginFailed());
    }
  } catch (e) {
    console.error({ e });
    yield put(checkLoginFailed());
  }
}

export function* watchCheckLogin() {
  yield takeLatest(CHECK_LOGIN, checkLogin);
}

function* fetchMe() {
  try {
    const token = yield getSession();
    const response = yield call(api.me, token);
    const user = response.data;
    yield put(setMe({ me: user }));
  } catch (error) {
    console.error(`error while log out ${error}`);
  }
}

export function* watchFetchMe() {
  yield takeLatest(FETCH_ME, fetchMe);
}

function* login({ payload: { email, password, history } }) {
  try {
    yield put(setIsSubmitting(true));
    const response = yield call(api.login, {
      email,
      password,
    });

    const {
      data: { user, token },
    } = response;
    if (user && !user.emailVerified) {
      console.log('email not verified. Sending email');
    } else {
      console.log('email already verified');
      setSession(token);
      yield put(setUser({ user }));
    }
    yield call(api.verificateEmail, { email: user.email });
    yield put(setToken(token));
    yield put(setIsSubmitting(false));
    setSession({ token });
    yield put(setUser({ user }));
    yield call(fetchMe);
    history.push('/');
  } catch (e) {
    yield put(setIsSubmitting(false));
    yield put(loginFailed());
    console.error(e);
  }
}

export function* watchLogin() {
  yield takeLatest(LOGIN, login);
}

function* logout() {
  try {
    sessionLogout();
  } catch (error) {
    console.error(`error while log out ${error}`);
  }
}

export function* watchLogout() {
  yield takeLatest(LOGOUT, logout);
}
