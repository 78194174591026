import { takeLatest, call, put } from 'redux-saga/effects';

import { fetchStopsSuccess, fetchStopsFailed } from './actions';

import { FETCH_STOPS } from './actionTypes';

import * as api from './api';

function* fetchStops() {
  try {
    const response = yield call(api.fetchStops);
    yield put(fetchStopsSuccess(response.data));
  } catch (e) {
    console.error({ e });
    yield put(fetchStopsFailed());
  }
}

export function* watchFetchStops() {
  yield takeLatest(FETCH_STOPS, fetchStops);
}
