import React, { Component } from 'react';
import PropType from 'prop-types';
import UserForm from '../../components/UserForm';

class EditUserScreen extends Component {
  componentDidMount() {
    const {
      fetchUserById,
      match: {
        params: { id },
      },
    } = this.props;
    fetchUserById(id);
  }

  render() {
    const { userById } = this.props;
    return <UserForm user={userById} />;
  }
}

EditUserScreen.propTypes = {
  match: PropType.shape({}).isRequired,
  userById: PropType.string.isRequired,
  fetchUserById: PropType.func.isRequired,
};

export default EditUserScreen;
