import { createActions } from 'redux-actions';

import {
  FETCH_REASONS,
  FETCH_REASON_BY_ID,
  SET_REASONS,
  SET_REASON_BY_ID,
  FETCH_REASONS_FAILED,
  CREATE_REASON,
  DELETE_REASON,
  UPDATE_REASON,
  UPDATE_REASON_SUCCESS,
  UPDATE_REASON_FAILED,
} from './actionTypes';

export const {
  fetchReasons,
  setReasons,
  setReasonById,
  setError,
  updateReason,
  updateReasonSuccess,
  updateReasonFailed,
} = createActions(
  FETCH_REASONS,
  SET_REASONS,
  SET_REASON_BY_ID,
  FETCH_REASONS_FAILED,
  UPDATE_REASON,
  UPDATE_REASON_SUCCESS,
  UPDATE_REASON_FAILED
);

export const { createReason, deleteReason, fetchReasonById } = createActions({
  [CREATE_REASON]: reason => ({ reason }),
  [DELETE_REASON]: reason => ({ reason }),
  [FETCH_REASON_BY_ID]: id => ({ id }),
});
