// Modules
import React, { Component } from 'react';
import PropType from 'prop-types';

// Commons
import Table from '../../../../components/TableV2';

// Assets
import TrashImg from './img/trash-can-outline.svg';
import ConfigImg from './img/tune.svg';
import DeleteModal from '../../../../components/DeleteModal';

class ReasonsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table: {
        page: 1,
        order: 'desc',
        orderBy: 'name',
      },
    };
  }

  componentDidMount() {
    const { fetchAgentReasons, agentId } = this.props;
    fetchAgentReasons({ id: agentId });
  }

  render() {
    const {
      table: { page, order, orderBy },
    } = this.state;
    const {
      me,
      reasons = [],
      updateReason,
      deleteReason,
      createModal,
    } = this.props;
    // ==========================================
    return (
      <Table
        page={page}
        order={order}
        orderBy={orderBy}
        rowsPerPage={12}
        fields={[
          {
            key: 'name',
            label: 'Nombre',
            format: 'default',
          },
          {
            key: 'date',
            label: 'Fecha de creación',
            format: 'date',
            color: '#ABABB8',
          },
          {
            key: 'activated',
            label: 'Activado',
            format: 'default',
            color: '#ABABB8',
          },
          {
            key: 'author',
            label: 'Creado por ',
            format: 'default',
            color: '#66CCCC',
          },
          {
            key: 'actions',
            label: 'Acciones',
            format: 'embed',
          },
        ]}
        data={reasons.map((reason, index) => ({
          key: index,
          name: reason.reason,
          activated: reason.activated ? 'Activado' : 'Desactivado',
          author: 'Admin',
          actions: [
            {
              icon: (
                <img
                  alt="config-icon"
                  src={ConfigImg}
                  width="13px"
                  height="15px"
                />
              ),
              color: reason.activated ? 'red' : 'green',
              text: reason.activated ? 'Desactivar' : 'Activar',
              handler: () => {
                const updatedReason = reason;
                updatedReason.activated = !reason.activated;
                return updateReason({ id: reason.id, reason: updatedReason });
              },
            },
            {
              disabled: me.accountType !== 'MASTER',
              icon: (
                <img
                  alt="trash-icon"
                  src={TrashImg}
                  width="13px"
                  height="15px"
                />
              ),
              color: 'red',
              text: 'Eliminar',
              handler: () =>
                createModal({
                  title: 'Eliminar Motivo',
                  width: 480,
                  body: (
                    <DeleteModal
                      item={reason}
                      title="¿Estas seguro que deseas eliminar este Motivo?"
                      removeText="Eliminar Motivo"
                      deleteAction={deleteReason}
                    />
                  ),
                }),
            },
          ],
        }))}
        onChangeField={(key, value) => {
          this.setState(prevState => ({
            ...prevState,
            table: {
              ...prevState.table,
              [key]: value,
            },
          }));
        }}
      />
    );
  }
}

ReasonsList.propTypes = {
  me: PropType.shape({
    accountType: PropType.string,
  }).isRequired,
  fetchAgentReasons: PropType.func.isRequired,
  reasons: PropType.arrayOf(PropType.shape()).isRequired,
  updateReason: PropType.func.isRequired,
  deleteReason: PropType.func.isRequired,
  agentId: PropType.string.isRequired,
  createModal: PropType.func.isRequired,
};

export default ReasonsList;
