import React from 'react';
import PropTypes from 'prop-types';

import {
  Switch,
  Input,
  RoundedSlider,
  SwitchLabel,
  RoundedSliderBackground,
} from './styles';

const ToggleSwitch = ({ checked, onChange }) => (
  <Switch>
    <Input type="checkbox" checked={checked} onChange={() => onChange()} />
    <SwitchLabel checked={checked}>{checked ? 'Sí' : 'No'}</SwitchLabel>
    <RoundedSliderBackground checked={checked} />
    <RoundedSlider checked={checked} />
  </Switch>
);

ToggleSwitch.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

ToggleSwitch.defaultProps = {
  checked: false,
};

export default ToggleSwitch;
