// Modules
import React from 'react';
import PropTypes from 'prop-types';

// Commons
import { Layout, Container } from './styles';

const Actions = ({ children, borderless }) => (
  <Layout borderless={borderless}>
    <Container>{children}</Container>
  </Layout>
);

Actions.defaultProps = {
  children: null,
};

Actions.propTypes = {
  children: PropTypes.node,
  borderless: PropTypes.bool,
};

Actions.defaultProps = {
  borderless: false,
};

export default Actions;
