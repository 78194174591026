import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Layout = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const Navigator = styled.nav`
  flex: 0.17;
  margin-right: 20px;
`;

export const Container = styled.div`
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
`;

export const Column = styled.ul`
  ${props => `
    ${props.separator &&
      `
      border-bottom: 1px solid #E8E8E8;  
    `}
    ${props.padding &&
      `
      padding: ${props.padding};
    `}
  `}
`;

export const Field = styled.li`
  padding-bottom: 28px;
`;

export const Label = styled.i`
  color: #ababb8;
  font-size: 12px;
  font-style: normal;
  text-transform: uppercase;
  font-family: 'SF UI Display Medium';
`;

export const Value = styled.div`
  font-family: 'SF UI Display Medium';
  font-size: 14px;
  padding-top: 2px;
  color: ${props => props.color || '#243847'};
`;

export const Option = styled.li`
  box-shadow: inset 0px 0 0 transparent;
  font-family: 'SF UI Display Medium';
  font-size: 14px;
  padding-top: 2px;
  color: #dadddf;
  font-size: 14px;
  ${props => `
    ${
      props.selected
        ? `
      color: #000;
      box-shadow: inset 2px 0 0 #a2e1e1;
    `
        : `
      &:hover {
        color: #999;
      }
    `
    }
  `}
  transition: all .15s ease-in-out;
`;

export const LinkElement = styled(Link)`
  color: inherit;
  padding: 0 20px;
  display: flex;
  height: 46px;
  align-items: center;
`;

export const Icon = styled.span``;

export const OptionLabel = styled.span``;

export const Body = styled.div`
  flex: 1;
  width: 100%;
`;
