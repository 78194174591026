import { takeLatest, put, call } from 'redux-saga/effects';

import {
  FETCH_REASONS,
  CREATE_REASON,
  DELETE_REASON,
  FETCH_REASON_BY_ID,
  UPDATE_REASON,
} from './actionTypes';
import { setReasons, setReasonById, updateReasonSuccess } from './actions';
import { fetchAgentReasons } from '../agents/actions';

import * as api from './api';
import { deleteModal, setIsSubmitting } from '../ui/actions';

function* fetchReasons() {
  try {
    const response = yield call(api.fetchReasons);
    yield put(setReasons({ reasons: response.data.data }));
  } catch (e) {
    console.error({ e });
  }
}

export function* watchFetchReasons() {
  yield takeLatest(FETCH_REASONS, fetchReasons);
}

function* fetchReasonById({ payload: { id } }) {
  try {
    const response = yield call(api.fetchReasonById, id);
    yield put(setReasonById({ reason: response.data }));
  } catch (e) {
    console.error({ e });
  }
}
export function* watchFetchReasonById() {
  yield takeLatest(FETCH_REASON_BY_ID, fetchReasonById);
}

function* createReason({ payload }) {
  try {
    yield put(setIsSubmitting(true));
    yield call(api.createReason, payload.reason);
    yield put(fetchAgentReasons({ id: payload.reason.agentId }));
    yield put(deleteModal());
    yield put(setIsSubmitting(false));
  } catch (e) {
    yield put(setIsSubmitting(false));
    console.error({ e });
  }
}

export function* watchCreateReason() {
  yield takeLatest(CREATE_REASON, createReason);
}

function* updateReason({ payload }) {
  try {
    yield put(setIsSubmitting(true));
    yield call(api.updateReason, payload.id, payload.reason);
    yield put(updateReasonSuccess());
    yield put(deleteModal());
    yield put(setIsSubmitting(false));
    yield put(fetchAgentReasons({ id: payload.reason.agentId }));
  } catch (e) {
    yield put(setIsSubmitting(false));
    console.error({ e });
  }
}

export function* watchUpdateReason() {
  yield takeLatest(UPDATE_REASON, updateReason);
}

function* deleteReason({ payload }) {
  try {
    yield call(api.deleteReason, payload.reason.item.id);
    yield put(fetchAgentReasons({ id: payload.reason.item.agentId }));
  } catch (e) {
    console.error({ e });
  }
}

export function* watchDeleteReason() {
  yield takeLatest(DELETE_REASON, deleteReason);
}
