import styled from 'styled-components';

export const Layout = styled.div`
  border: ${({ borderless }) => (borderless ? 0 : '2px solid #E7EBEF')};
  padding: 10px;
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
  border-radius: 0 0 4px 4px;
`;

export const Container = styled.div`
  > button {
    margin-left: 20px;
  }
`;
