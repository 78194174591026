import React from 'react';
import PropType from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { FlexColumn, Button } from '../../theme/styles';
import { Input } from '../Input/styles';
import ToggleSwitch from '../ToggleSwitch';
import { Label } from '../Label/styles';
import Actions from '../UI/Modal/components/Actions';
import { ModalInputsContainer } from '../ModalInputsContainer/styles';
import { ModalInputsRowContainer } from '../ModalInputsRowContainer/styles';

const validation = Yup.object().shape({
  reason: Yup.string().required('Required'),
  activated: Yup.string().required('Required'),
});

const ReasonsForm = ({ createReason, agentId, deleteModal, isSubmitting }) => (
  <Formik
    initialValues={{
      reason: '',
      activated: false,
      agentId,
    }}
    enableReinitialize
    onSubmit={values => {
      createReason(values);
    }}
    validationSchema={validation}
  >
    {formProps => {
      const {
        values,
        touched,
        errors,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
      } = formProps;

      return (
        <form>
          <ModalInputsContainer paddingBottom={20}>
            <ModalInputsRowContainer noPadding center>
              <FlexColumn>
                <Label htmlFor="reason" style={{ display: 'block' }}>
                  Motivo
                </Label>
                <Input
                  marginRight
                  id="reason"
                  placeholder="Motivo"
                  type="text"
                  value={values.reason}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.reason && touched.reason
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                {errors.reason && touched.reason && (
                  <div className="input-feedback">{errors.reason}</div>
                )}
              </FlexColumn>

              <FlexColumn>
                <Label htmlFor="activated" style={{ display: 'block' }}>
                  Activado
                </Label>
                <ToggleSwitch
                  id="activated"
                  onChange={() => setFieldValue('activated', !values.activated)}
                  checked={values.activated}
                />
              </FlexColumn>
            </ModalInputsRowContainer>
          </ModalInputsContainer>
          <Actions>
            <Button type="button" disabled={isSubmitting} onClick={deleteModal}>
              Cancelar
            </Button>
            <Button
              primary
              type="submit"
              disabled={isSubmitting}
              onClick={handleSubmit}
            >
              Crear Motivo
            </Button>
          </Actions>
        </form>
      );
    }}
  </Formik>
);

ReasonsForm.propTypes = {
  createReason: PropType.func.isRequired,
  deleteModal: PropType.func.isRequired,
  values: PropType.shape({}),
  touched: PropType.shape({}),
  errors: PropType.shape({}),
  agentId: PropType.string.isRequired,
};

ReasonsForm.defaultProps = {
  values: {},
  touched: {},
  errors: {},
};

export default ReasonsForm;
