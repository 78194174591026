import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from '../../lib/sessionStorage';

const checkRole = (pathname, role = 'CLIENT') => {
  if (
    pathname.includes('agents') &&
    (role === 'ADMIN' || role === 'SUPERADMIN')
  ) {
    return true;
  }

  if (
    pathname.includes('agents') &&
    pathname.includes('client') &&
    role === 'CLIENT'
  ) {
    return true;
  }

  if (
    pathname.includes('agents') &&
    (role === 'SUPERADMIN' || role === 'ADMIN')
  ) {
    return true;
  }

  if (
    pathname.includes('accounts') &&
    (role === 'ADMIN' || role === 'SUPERADMIN')
  ) {
    return true;
  }

  if (
    pathname.includes('users') &&
    (role === 'ADMIN' || role === 'SUPERADMIN')
  ) {
    return true;
  }

  if (pathname.includes('subaccounts') && role === 'PARTNER') {
    return true;
  }

  return false;
};

export default ({ component: Component, user, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() &&
      checkRole(props.match.path, user ? user.role : 'CLIENT') ? (
        <Component {...props} />
      ) : (
        <Redirect to="/" />
      )
    }
  />
);
