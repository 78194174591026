// Modules
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { ResponsivePie } from '@nivo/pie';
import { Progress, Radio, Tag } from 'antd';

// Commons
import Headline from '../../components/Headline';
import {
  CardComponent,
  ContentContainer,
  ContentPie,
  HeaderDataContainer,
  LoaderContainer,
  MainContainer,
  MainWraper,
  MonthSelector,
  ReasonTableContainer,
  ReasonTableHeading,
  ReasonTableRow,
  ReasonTableRowContainer,
  ReasonTableRowItem,
  ReasonTableRowItemProgress,
} from './styles';
import { useGetData } from '../../modules/newDashboard/queries';

import { AddAgentSelect } from '../AccountsConfigureScreen/styles';
import {
  getDifferentsNumberData,
  getLegendData,
  getPrevMonth,
  getReasonsData,
  getRetryData,
  getRoundValue,
  getScheduleData,
  getThisMonth,
  getToday,
  getYesNoData,
  getYesWithWithoutRetryData,
  sortByValue,
  getDateFormatView,
  getUserDates,
  getAgentsInfoData,
  formatNumber,
} from '../../modules/newDashboard/utils';
import Loader from '../../components/Loader';

function formatTime(totalMinutos) {
  let timeFormat = '';
  const horas = Math.floor(totalMinutos / 60);
  const minutosRestantes = totalMinutos % 60;
  const segundos = Math.floor((totalMinutos % 1) * 60);

  if (horas) {
    timeFormat = `${formatNumber(horas)}h.`;
  }
  if (minutosRestantes) {
    timeFormat = `${timeFormat} ${formatNumber(
      parseInt(minutosRestantes, 10)
    )}min.`;
  }
  if (segundos) {
    timeFormat = `${timeFormat} ${formatNumber(segundos)}seg.`;
  }

  if (!horas && !minutosRestantes) {
    return `${segundos} segundos`;
  }

  return timeFormat;
}

const ReasonTable = ({ reasonsData }) => {
  const filteredReasonsData = reasonsData.filter(reason => !!reason.value);

  if (!filteredReasonsData.length) {
    return (
      <ReasonTableContainer>
        <ReasonTableHeading>
          <ReasonTableRowItem center>Sin datos</ReasonTableRowItem>
        </ReasonTableHeading>
      </ReasonTableContainer>
    );
  }

  return (
    <ReasonTableContainer>
      <ReasonTableHeading>
        <ReasonTableRowItem doble>Motivo</ReasonTableRowItem>
        <ReasonTableRowItem center>Total</ReasonTableRowItem>
        <ReasonTableRowItem>Porcentaje</ReasonTableRowItem>
      </ReasonTableHeading>
      {filteredReasonsData.map(reason => (
        <ReasonTableRowContainer>
          <ReasonTableRow>
            <ReasonTableRowItem doble>{reason.label}</ReasonTableRowItem>
            <ReasonTableRowItem center>
              {formatNumber(reason.value)}
            </ReasonTableRowItem>
            <ReasonTableRowItem>
              <ReasonTableRowItemProgress>
                <Tag color="#66CCCC">{`${formatNumber(
                  reason.percentage
                )}%`}</Tag>
                {/*<Progress
                  percent={parseInt(reason.percentage, 10)}
                  showInfo={false}
                  strokeColor="#66CCCC"
        /> */}
              </ReasonTableRowItemProgress>
            </ReasonTableRowItem>
          </ReasonTableRow>
          <Progress
            percent={parseInt(reason.percentage, 10)}
            showInfo={false}
            strokeColor="#66CCCC"
          />
        </ReasonTableRowContainer>
      ))}
    </ReasonTableContainer>
  );
};

const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
  let total = 0;
  dataWithArc.forEach(datum => {
    total += datum.value;
  });

  return (
    <text
      x={centerX}
      y={centerY}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontSize: '46px',
        fontWeight: 600,
      }}
    >
      {total}
    </text>
  );
};

const SmallCenteredMetric = ({ dataWithArc, centerX, centerY }) => {
  let total = 0;
  dataWithArc.forEach(datum => {
    total += datum.value;
  });

  return (
    <text
      x={centerX}
      y={centerY - 20}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontSize: '30px',
        fontWeight: 600,
      }}
    >
      {formatNumber(total)}
    </text>
  );
};

const MyResponsiveSemiPie = ({ data /* see data tab */ }) => {
  return (
    <ResponsivePie
      data={data}
      colors={datum => datum.data.color}
      margin={{ top: 50, right: 0, bottom: 0, left: 0 }}
      startAngle={-90}
      endAngle={90}
      innerRadius={0.6}
      padAngle={0.7}
      cornerRadius={3}
      fit={false}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.2]],
      }}
      /*       arcLinkLabelsSkipAngle={8}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsOffset={-7}
      arcLinkLabelsDiagonalLength={26}
      arcLinkLabelsStraightLength={26}
      arcLinkLabelsThickness={5}
      arcLinkLabelsColor={{ from: 'color' }} */
      arcLabelsSkipAngle={20}
      arcLabelsRadiusOffset={0.55}
      arcLabelsTextColor={{
        from: 'color',
        modifiers: [['brighter', '3']],
      }}
      arcLabel={d => `${formatNumber(d.data.percentage)}%`}
      // arcLinkLabel={d => `${d.id} (${d.formattedValue})`}
      activeInnerRadiusOffset={8}
      layers={['arcs', 'arcLabels', 'legends', SmallCenteredMetric]}
      legends={[
        {
          data: getLegendData(data),
          anchor: 'top',
          direction: 'row',
          toggleSerie: true,
          translateY: -50,
          itemsSpacing: 0,
          itemWidth: 190,
          itemHeight: 22,
          itemTextColor: '#999',
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolSize: 18,
          // symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000',
              },
            },
          ],
        },
      ]}
    />
  );
};

/*const data = [
  { id: "si", value: 10 },
  { id: "no", value: 25 },
  { id: "puede", value: 3 }
];

const DonutChart = () => (
  <ResponsivePie
    data={data}
    innerRadius={0.6}
    padAngle={0.5}
    cornerRadius={3}
    colors={{ scheme: "pastel1" }}
    borderWidth={1}
    borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
    enableRadialLabels={false}
    enableSliceLabels
    sliceLabel={(slice) => `${slice.value}`}
    tooltip={({ datum: { id, value } }) => (
      <span>
        {id}: {value}
      </span>
    )}
  />
);*/

const HeaderCol = ({ value = '', label = '' }) => (
  <CardComponent>
    <h4>{label}</h4>
    <span>{value}</span>
  </CardComponent>
);

const HeaderData = ({
  totalConversation = 0,
  totalMinutes = 0,
  mediaConver = 0,
}) => {
  return (
    <HeaderDataContainer>
      <HeaderCol
        value={formatNumber(totalConversation)}
        label="Conversaciones"
      />
      <HeaderCol
        value={formatNumber(getRoundValue(totalMinutes))}
        label="Minutos de uso"
      />
      <HeaderCol value={`${formatTime(mediaConver)}`} label="Tiempo promedio" />
    </HeaderDataContainer>
  );
};

const TIME = {
  TODAY: 'TODAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  MONTH_PREV: 'MONTH_PREV',
  BY_USER: 'BY_USER',
};

const Dashboard = ({
  user,
  agents = [],
  fetchAgents,
  accountAgents = [],
  fetchAccountAgents,
}) => {
  const [agentPhone, setAgentPhone] = useState('');
  const [date, setDate] = useState(getToday());
  const [timeSelector, setTimeSelector] = useState(TIME.TODAY);
  const { data, isLoading } = useGetData({ agentPhone, ...date });

  const canSeeAll =
    (user.role === 'SUPERADMIN' || user.role === 'ADMIN') &&
    user.accountType === 'MASTER';

  useEffect(() => {
    if (canSeeAll) {
      fetchAgents();
    } else if (user.accountType !== 'MASTER') {
      // PARTNER??
      fetchAccountAgents({ id: user.account });
    }
  }, [canSeeAll, user]);

  const reasonsData =
    data && agentPhone ? getReasonsData(data).sort(sortByValue) : [];

  const agentsInfoData = data ? getAgentsInfoData(data).sort(sortByValue) : [];

  const setTodayTime = () => {
    setTimeSelector(TIME.TODAY);
    setDate(getToday());
  };

  const setMonthTime = () => {
    setTimeSelector(TIME.MONTH);
    setDate(getThisMonth());
  };

  const setPrevMonthTime = () => {
    setTimeSelector(TIME.MONTH_PREV);
    setDate(getPrevMonth());
  };

  const setMonthDates = (dateMoments, dates) => {
    if (!dateMoments) {
      setTodayTime();
      return;
    }
    setTimeSelector(TIME.BY_USER);
    setDate(getUserDates(dates));
  };

  const agentList = canSeeAll ? agents : accountAgents;

  const disabledDate = current => {
    return (
      current &&
      (current > moment().endOf('day') ||
        current < moment('2024-01-01').startOf('day'))
    );
  };

  const onChangeRadioButton = e => {
    const {
      target: { value },
    } = e;
    if (value === TIME.TODAY) {
      setTodayTime();
      return;
    }
    if (value === TIME.MONTH) {
      setMonthTime();
      return;
    }
    if (value === TIME.MONTH_PREV) {
      setPrevMonthTime();
      return;
    }
  };

  return (
    <MainContainer>
      <MainWraper>
        <Headline className="headline" title="Actividad del AI" />

        <div>
          <Radio.Group onChange={onChangeRadioButton} value={timeSelector}>
            <Radio.Button value={TIME.TODAY}>Hoy</Radio.Button>
            <Radio.Button value={TIME.MONTH}>Este mes</Radio.Button>
            <Radio.Button value={TIME.MONTH_PREV}>Mes anterior</Radio.Button>
          </Radio.Group>

          <MonthSelector
            value={[
              moment(date.dateFirst),
              moment(date.dateLast).subtract(1, 'hours'),
            ]}
            format="DD/MM/YYYY"
            onChange={setMonthDates}
            disabledDate={disabledDate}
          />

          <AddAgentSelect
            value={agentPhone}
            onChange={ev => setAgentPhone(ev.target.value)}
            className="agent-select"
          >
            <option selected value="">
              Todos
            </option>
            {agentList.map(agent => (
              <option key={agent.phone} value={agent.phone}>
                {agent.name}
              </option>
            ))}
          </AddAgentSelect>
          {isLoading && (
            <LoaderContainer>
              <Loader color="#66CCCC" size="60px" />
            </LoaderContainer>
          )}
          {!isLoading && !!data && <HeaderData {...data} />}
          {!isLoading && !!data && (
            <ContentContainer>
              {!!data && (
                <ContentPie semiPie>
                  <CardComponent className="semipie">
                    <h4>Dentro/fuera horario</h4>
                    <MyResponsiveSemiPie data={getScheduleData(data)} />
                  </CardComponent>
                </ContentPie>
              )}
              {!!data && !!agentPhone && (
                <ContentPie semiPie>
                  <CardComponent className="semipie">
                    <h4>Contactos</h4>
                    <MyResponsiveSemiPie data={getDifferentsNumberData(data)} />
                  </CardComponent>
                </ContentPie>
              )}
              {!!data && (
                <ContentPie>
                  <CardComponent className="h-auto">
                    <h4>Motivos paso a persona</h4>
                    <ReasonTable reasonsData={agentsInfoData} />
                  </CardComponent>
                </ContentPie>
              )}
              {!!data && !!agentPhone && (
                <ContentPie>
                  <CardComponent className="h-auto">
                    <h4>Motivos de contacto</h4>
                    <ReasonTable reasonsData={reasonsData} />
                  </CardComponent>
                </ContentPie>
              )}
            </ContentContainer>
          )}
        </div>
      </MainWraper>
    </MainContainer>
  );
};

export default Dashboard;
