import { createActions } from 'redux-actions';

import {
  FETCH_PATHS,
  FETCH_PATHS_SUCCESS,
  FETCH_PATHS_FAILED,
} from './actionTypes';

export const {
  fetchPaths,
  fetchPathsSuccess,
  fetchPathsFailed,
} = createActions(FETCH_PATHS, FETCH_PATHS_SUCCESS, FETCH_PATHS_FAILED);
