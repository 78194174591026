// Dependencies
import { createActions } from 'redux-actions';

import {
  REQUEST_PASSWORD,
  REQUEST_PASSWORD_FAILED,
  REQUEST_PASSWORD_SUCCESS,
  VALIDATE_RESET_PASSWORD,
  VALIDATE_RESET_PASSWORD_FAILED,
  VALIDATE_RESET_PASSWORD_SUCCESS,
} from './actionTypes';

export const {
  requestPassword,
  requestPasswordSuccess,
  requestPasswordFailed,
  validateResetPassword,
  validateResetPasswordSuccess,
  validateResetPasswordFailed,
} = createActions(
  REQUEST_PASSWORD,
  REQUEST_PASSWORD_SUCCESS,
  REQUEST_PASSWORD_FAILED,
  VALIDATE_RESET_PASSWORD,
  VALIDATE_RESET_PASSWORD_SUCCESS,
  VALIDATE_RESET_PASSWORD_FAILED
);
