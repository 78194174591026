import { createActions } from 'redux-actions';

import {
  FETCH_STOPS,
  FETCH_STOPS_SUCCESS,
  FETCH_STOPS_FAILED,
} from './actionTypes';

export const {
  fetchStops,
  fetchStopsSuccess,
  fetchStopsFailed,
} = createActions(FETCH_STOPS, FETCH_STOPS_SUCCESS, FETCH_STOPS_FAILED);
