// Dependencies
import { handleActions, combineActions } from 'redux-actions';

import {
  requestPassword,
  requestPasswordSuccess,
  requestPasswordFailed,
  validateResetPassword,
  validateResetPasswordSuccess,
  validateResetPasswordFailed,
} from './actions';

const defaultState = {
  isLoading: false,
  error: null,
  success: false,
};

export default handleActions(
  {
    [combineActions(requestPassword, validateResetPassword)]: state => ({
      ...state,
      isLoading: true,
    }),
    [combineActions(
      requestPasswordSuccess,
      validateResetPasswordSuccess
    )]: state => ({
      ...state,
      isLoading: false,
      success: true,
    }),
    [combineActions(requestPasswordFailed, validateResetPasswordFailed)]: (
      state,
      payload
    ) => ({
      ...state,
      isLoading: false,
      error: payload.error,
    }),
  },
  defaultState
);
