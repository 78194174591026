import styled from 'styled-components';

export const HeaderContainer = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  background: #ffffff;
  position: fixed;
  box-shadow: 0 1px 5px 0 rgba(30, 30, 30, 0.09);
  z-index: 1000;
`;

export const LeftContainer = styled.div`
  display: flex;
  padding: 0 40px;
  flex: 1;
  align-items: flex-start;
`;

export const RightContainer = styled.div`
  display: flex;
  padding: 0 20px;
  align-items: flex-end;
`;

export const Picture = styled.img`
  display: flex;
  width: 32px;
  margin-right: 1vh;
  border-radius: 100%;
`;

export const Profile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > * {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
`;

export const Account = styled.span`
  padding: 0 1vh;
  color: #2e303c;
  font-size: 14px;
`;

export const Menu = styled.div`
  position: relative;
`;

export const Action = styled.div`
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const MenuContainer = styled.ul`
  right: 8px;
  width: 150px;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.12);
  position: absolute;
  transition: all 0.2s ease-in-out;
  ${props =>
    props.active
      ? `
    top: 36px;
    visibility: visible;
    opacity: 1;
  `
      : `
    top: 42px;
    visibility: hidden;
    opacity: 0;
  `}
`;

export const MenuItem = styled.li`
  padding: 2vh;
  font-size: 14px;
  font-weight: normal;
  color: #2d2f3b;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #fcfdfd;
  }
`;

export const Arrow = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #afb2b7;
`;
