// Modules
import { connect } from 'react-redux';

// Layout
import Modal from './view';

// Actions
import { deleteModal } from '../../../modules/ui/actions';

// Redux Connector
export default connect(() => ({}), { deleteModal })(Modal);
