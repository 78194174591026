// Dependencies
import { connect } from 'react-redux';

// View
import ResetPasswordForm from './stateless';

// Actions
import { requestPassword } from '../../../modules/reset-password/actions';

// Connector
export default connect(
  state => ({
    isLoading: state.resetPassword.isLoading,
    success: state.resetPassword.success,
    isSubmitting: state.ui.isSubmitting,
  }),
  { requestPassword }
)(ResetPasswordForm);
