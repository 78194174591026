import { takeLatest, put, call } from 'redux-saga/effects';

import {
  FETCH_BEHAVIOURS,
  CREATE_BEHAVIOUR,
  DELETE_BEHAVIOUR,
  FETCH_BEHAVIOUR_BY_ID,
} from './actionTypes';
import {
  fetchBehaviours as getBehaviours,
  setBehaviours,
  setBehaviourById,
} from './actions';
import * as api from './api';
import { deleteModal, setIsSubmitting } from '../ui/actions';

function* fetchBehaviours({ payload: { phone } }) {
  try {
    const response = yield call(api.fetchBehaviours, phone);
    yield put(setBehaviours({ behaviours: response.data.data }));
  } catch (e) {
    console.error({ e });
  }
}

export function* watchFetchBehaviours() {
  yield takeLatest(FETCH_BEHAVIOURS, fetchBehaviours);
}

function* fetchBehaviourById({ payload: { id } }) {
  try {
    const response = yield call(api.fetchBehaviourById, id);
    yield put(setBehaviourById({ behaviour: response.data }));
  } catch (e) {
    console.error({ e });
  }
}
export function* watchFetchBehaviourById() {
  yield takeLatest(FETCH_BEHAVIOUR_BY_ID, fetchBehaviourById);
}

function* createBehaviour({ payload }) {
  try {
    yield put(setIsSubmitting(true));
    const response = yield call(api.createBehaviour, payload.behaviour);
    if (response && response.data) {
      yield put(getBehaviours({ phone: payload.behaviour.agent }));
      yield put(deleteModal());
      yield put(setIsSubmitting(false));
    }
  } catch (e) {
    yield put(setIsSubmitting(false));
    console.error({ e });
  }
}

export function* watchCreateBehaviour() {
  yield takeLatest(CREATE_BEHAVIOUR, createBehaviour);
}

function* deleteBehaviour({ payload }) {
  try {
    const response = yield call(api.deleteBehaviour, payload.behaviour.item.id);
    if (response && response.data) {
      yield put(getBehaviours({ phone: payload.behaviour.phone }));
    }
  } catch (e) {
    console.error({ e });
  }
}

export function* watchDeleteBehaviour() {
  yield takeLatest(DELETE_BEHAVIOUR, deleteBehaviour);
}
