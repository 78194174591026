import { get, post, del, put, postLocutionAudio } from '../../lib/restClient';

export const fetchAccounts = async () => get('/accounts');

export const fetchAccountById = async id => get(`/accounts/${id}`);

export const fetchAccountAgents = async id => get(`/accounts/${id}/agents`);

export const fetchAccountClients = async id => get(`/accounts/${id}/accounts`);

export const createAccount = async data => post('/accounts', data);

export const addAgentToAccount = async (accountId, data) =>
  post(`/accounts/${accountId}/add-agent`, data);

export const createLocution = async data =>
  postLocutionAudio('/locutions', data);
// export const createAudio = async data => postAudio('/audios', data);

export const updateAccount = async (id, data) => put(`/accounts/${id}`, data);

export const deleteAccount = async id => del(`/accounts/${id}`);

export const removeAccountAgent = async (id, agentId) =>
  del(`/accounts/${id}/agents/${agentId}`);
