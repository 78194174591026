import React from 'react';
import { Select, DatePicker, TimePicker } from 'antd';
import { Separation } from '../styles';
import 'antd/dist/antd.css';

import { FlexColumn, Button } from '../../../theme/styles';
import { ModalInputsContainer } from '../../ModalInputsContainer/styles';
import { ModalInputsRowContainer } from '../../ModalInputsRowContainer/styles';
import { Label } from '../../Label/styles';
import { dayList, hourList } from './constants';

const { Option } = Select;
const RenderTimeConditions = ({ value, onChange, errors, onFocus }) => (
  <>
    <Separation />
    <ModalInputsRowContainer spaceBetween>
      <FlexColumn>
        <Label htmlFor="startDay" style={{ display: 'block' }}>
          Día semana inicio
        </Label>
        <Select
          style={{ width: 200 }}
          placeholder="Selecciona un día"
          optionFilterProp="children"
          dropdownStyle={{ zIndex: 10500 }}
          defaultValue={
            value && value.startDay ? value.startDay : 'Selecciona un día'
          }
          onChange={event => onChange(event, 'startDay')}
          onFocus={onFocus ? () => onFocus() : null}
        >
          {dayList.map(day => (
            <Option value={day}>{day}</Option>
          ))}
        </Select>
        {errors ? <div>{errors.name}</div> : ''}
      </FlexColumn>
      <FlexColumn>
        <Label htmlFor="endDay" style={{ display: 'block' }}>
          Día semana finalización
        </Label>
        <Select
          style={{ width: 200 }}
          placeholder="Selecciona un día"
          optionFilterProp="children"
          defaultValue={
            value && value.endDay ? value.endDay : 'Selecciona un día'
          }
          dropdownStyle={{ zIndex: 10500, height: 'fit-content' }}
          onChange={event => onChange(event, 'endDay')}
          onFocus={onFocus ? () => onFocus() : null}
        >
          {dayList.map(day => (
            <Option key={day} value={day}>
              {day}
            </Option>
          ))}
        </Select>
        {errors ? <div>{errors.name}</div> : ''}
      </FlexColumn>
    </ModalInputsRowContainer>
    <ModalInputsRowContainer spaceBetween>
      <FlexColumn>
        <Label htmlFor="startHour" style={{ display: 'block' }}>
          Hora inicio
        </Label>
        <Select
          style={{ width: 200 }}
          placeholder="Selecciona una hora"
          defaultValue={
            value && value.startHour ? value.startHour : 'Selecciona una hora'
          }
          dropdownStyle={{ zIndex: 10500, height: 'fit-content' }}
          onChange={event => onChange(event, 'startHour')}
          onFocus={onFocus ? () => onFocus() : null}
        >
          {hourList.map((hour, index) => (
            <Option key={index} value={hour}>
              {hour}
            </Option>
          ))}
        </Select>
        {errors ? <div>{errors.name}</div> : ''}
      </FlexColumn>
      <FlexColumn>
        <Label htmlFor="endHour" style={{ display: 'block' }}>
          Hora finalización
        </Label>
        <Select
          style={{ width: 200 }}
          placeholder="Selecciona una hora"
          defaultValue={
            value && value.endHour ? value.endHour : 'Selecciona una hora'
          }
          dropdownStyle={{ zIndex: 10500, height: 'fit-content' }}
          onChange={event => onChange(event, 'endHour')}
          onFocus={onFocus ? () => onFocus() : null}
        >
          {hourList.map((hour, index) => (
            <Option key={index} value={hour}>
              {hour}
            </Option>
          ))}
        </Select>
        {errors ? <div>{errors.name}</div> : ''}
      </FlexColumn>
    </ModalInputsRowContainer>
  </>
);
export default RenderTimeConditions;
