import { connect } from 'react-redux';

import View from './view';

import { fetchAudioById } from '../../modules/audios/actions';

export default connect(
  state => ({
    audioById: state.audios.audioById,
  }),
  {
    fetchAudioById,
  }
)(View);
