import { connect } from 'react-redux';
import View from './view';

import { createLocution } from '../../modules/locutions/actions';
import { deleteModal } from '../../modules/ui/actions';

export default connect(state => ({ isSubmitting: state.ui.isSubmitting }), {
  createLocution,
  deleteModal,
})(View);
