import styled from 'styled-components';

export const Layout = styled.section`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.7);
  overflow: auto;
`;

export const Outline = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  ${props => `
    width: ${props.width}px;
  `}
  flex-direction: column;
  background: white;
  border-radius: 4px;
  box-shadow: 0 12px 34px 0 rgba(0, 0, 0, 0.5);
`;

export const Header = styled.header`
  display: flex;
  padding: 15px 16px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px solid #e7ebef;
`;

export const Title = styled.h2`
  top: 2px;
  color: #44444d;
  font-size: 18px;
  position: relative;
  font-weight: normal;
  font-family: 'SF UI Display Medium';
`;

export const Close = styled.div`
  width: 25px;
  height: 25px;
`;

export const Icon = styled.img`
  width: 100%;
  height: 100%;
  opacity: 0.15;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`;

export const Body = styled.div``;

export const Actions = styled.div``;
