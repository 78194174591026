import { useQuery } from 'react-query';
import { NEW_DASHBOARD_DATA } from './constants';
import { fetchData } from './api';

export const useGetData = ({
  agentPhone = '',
  dateFirst = '',
  dateLast = '',
}) =>
  useQuery(
    [NEW_DASHBOARD_DATA, agentPhone, dateFirst, dateLast],
    async () => {
      try {
        const response = await fetchData(agentPhone, dateFirst, dateLast);
        return response.data;
      } catch (error) {
        console.log({ error });
        return {};
      }
    },
    {
      onError: error => console.log({ error }),
    }
  );
