import { format, lastDayOfMonth, parse, subMonths } from 'date-fns';

import {
  chartColors,
  chartPrimaryColor,
  chartSecondaryColor,
} from '../../theme/colours';

export const formatNumber = num =>
  new Intl.NumberFormat('es-ES', { useGrouping: true }).format(num);

export const getRoundValue = num =>
  Math.round((num + Number.EPSILON) * 100) / 100;

const getPercentage = (value, total) => {
  if (!total) {
    return 0;
  }
  return getRoundValue((value / total) * 100);
};

export const sortByValue = (a, b) => {
  if (a.value > b.value) return -1;
  if (a.value < b.value) return 1;
  return 0;
};

export const getLegendData = (data = []) =>
  data.map(datum => ({
    ...datum,
    label: `${datum.label}: (${formatNumber(datum.value)}) ${
      datum.percentage ? `${formatNumber(datum.percentage)}%` : ''
    }`,
  }));

export const getRetryData = ({ retry = 0, totalConversation = 0 }) => [
  {
    id: 'Con Reintento',
    label: 'Con Reintento',
    value: retry,
    percentage: getPercentage(retry, totalConversation),
    color: chartPrimaryColor,
  },
  {
    id: 'Sin Reintento',
    label: 'Sin Reintento',
    value: totalConversation - retry,
    percentage: getPercentage(totalConversation - retry, totalConversation),
    color: chartSecondaryColor,
  },
];

export const getScheduleData = ({
  timeIn = 0,
  timeOut = 0,
  totalConversation = 0,
}) => [
  {
    id: 'Dentro Horario',
    label: 'Dentro Horario',
    value: timeIn,
    percentage: getPercentage(timeIn, totalConversation),
    color: chartPrimaryColor,
  },
  {
    id: 'Fuera Horario',
    label: 'Fuera Horario',
    value: timeOut,
    percentage: getPercentage(timeOut, totalConversation),
    color: chartSecondaryColor,
  },
];

export const getReasonsData = ({ reasons = {}, totalConversation = 0 }) =>
  Object.keys(reasons).reduce(
    (acc, key = '', index) => [
      ...acc,
      {
        id: key.replace('motivo.', ''),
        label: key.replace('motivo.', ''),
        value: reasons[key],
        percentage: getPercentage(reasons[key], totalConversation),
        color: chartColors[index],
      },
    ],
    []
  );

export const getAgentsInfoData = ({
  agentNo = 0,
  agentYesWithOperator = 0,
  agentYesWithRetry = 0,
  agentYesWithSilence = 0,
  agentYesWithoutRetry = 0,
  totalConversation = 0,
}) => [
  {
    id: 'No pasan a persona',
    label: 'No pasan a persona',
    value: agentNo,
    percentage: getPercentage(agentNo, totalConversation),
    // color: chartColors[index],
  },
  {
    id: 'Contacto pide hablar con persona',
    label: 'Contacto pide hablar con persona',
    value: agentYesWithOperator,
    percentage: getPercentage(agentYesWithOperator, totalConversation),
    // color: chartColors[index],
  },
  {
    id: 'AI no detecta motivo de llamada',
    label: 'AI no detecta motivo de llamada',
    value: agentYesWithRetry,
    percentage: getPercentage(agentYesWithRetry, totalConversation),
    // color: chartColors[index],
  },
  {
    id: 'Persona que llama no habla',
    label: 'Persona que llama no habla',
    value: agentYesWithSilence,
    percentage: getPercentage(agentYesWithSilence, totalConversation),
    // color: chartColors[index],
  },
  {
    id: 'Motivo de contacto necesita persona',
    label: 'Motivo de contacto necesita persona',
    value: agentYesWithoutRetry,
    percentage: getPercentage(agentYesWithoutRetry, totalConversation),
    // color: chartColors[index],
  },
];

export const getYesNoData = ({
  agentYesTotal = 0,
  agentNo = 0,
  totalConversation = 0,
}) => [
  {
    id: 'Pasan a agente',
    label: 'Pasan a agente',
    value: agentYesTotal,
    percentage: getPercentage(agentYesTotal, totalConversation),
    color: chartPrimaryColor,
  },
  {
    id: 'No pasan a persona',
    label: 'No pasan a persona',
    value: agentNo,
    percentage: getPercentage(agentNo, totalConversation),
    color: chartSecondaryColor,
  },
  {
    id: 'Sin datos',
    label: 'Sin datos',
    value: totalConversation - agentYesTotal - agentNo,
    percentage: getPercentage(
      totalConversation - agentYesTotal - agentNo,
      totalConversation
    ),
    color: chartColors[3],
  },
];

export const getYesWithWithoutRetryData = ({
  agentYesTotal = 0,
  agentYesWithRetry = 0,
  agentYesWithoutRetry = 0,
  agentYesWithSilence = 0,
  agentYesWithOperator = 0,
}) => [
  {
    id: 'Motivo de contacto necesita persona',
    label: 'Motivo de contacto necesita persona',
    value: agentYesWithoutRetry,
    percentage: getPercentage(agentYesWithoutRetry, agentYesTotal),
    color: chartSecondaryColor,
  },
  {
    id: 'AI no detecta motivo de llamada',
    label: 'AI no detecta motivo de llamada',
    value: agentYesWithRetry,
    percentage: getPercentage(agentYesWithRetry, agentYesTotal),
    color: chartPrimaryColor,
  },
  {
    id: 'Persona que llama no habla',
    label: 'Persona que llama no habla',
    value: agentYesWithSilence,
    percentage: getPercentage(agentYesWithSilence, agentYesTotal),
    color: chartColors[2],
  },
  {
    id: 'Contacto pide hablar con persona',
    label: 'Contacto pide hablar con persona',
    value: agentYesWithOperator,
    percentage: getPercentage(agentYesWithOperator, agentYesTotal),
    color: chartColors[3],
  },
];

export const getDifferentsNumberData = ({
  differentPhones = 0,
  totalConversation = 0,
}) => [
  {
    id: 'Únicos',
    label: 'Únicos',
    value: differentPhones,
    percentage: getPercentage(differentPhones, totalConversation),
    color: chartPrimaryColor,
  },
  {
    id: 'Recurrentes',
    label: 'Recurrentes',
    value: totalConversation - differentPhones,
    percentage: getPercentage(
      totalConversation - differentPhones,
      totalConversation
    ),
    color: chartSecondaryColor,
  },
];

const START_DAY = 'T00:00:00.000Z';
const END_DAY = 'T23:59:59.999Z';

const FORMAT_DATE = 'yyyy-MM-dd';
const FORMAT_DATE_VIEW = 'dd/MM/yyyy';

const dateFormat = date => format(date, FORMAT_DATE);

const getDate = (dateFirst, dateLast) => {
  return {
    dateFirst: `${dateFirst}${START_DAY}`,
    dateLast: `${dateLast}${END_DAY}`,
  };
};

const getMonthDate = date => {
  const firstDateOfMonth = format(date, 'yyyy-MM-01');
  const lastDateOfMonth = dateFormat(lastDayOfMonth(date));

  return getDate(firstDateOfMonth, lastDateOfMonth);
};

export const getToday = () =>
  getDate(dateFormat(new Date()), dateFormat(new Date()));
export const getThisMonth = () => {
  const today = new Date();
  return getMonthDate(today);
};
export const getPrevMonth = () => {
  const today = new Date();
  const newDate = subMonths(today, 1);
  return getMonthDate(newDate);
};

export const getUserDates = dates => {
  const date1 = parse(dates[0], 'dd/MM/yyyy', new Date());
  const date2 = parse(dates[1], 'dd/MM/yyyy', new Date());

  return getDate(dateFormat(date1), dateFormat(date2));
};

export const getDateFormatView = ({ dateFirst = '', dateLast = '' }) => {
  const first = new Date(dateFirst);
  const last = new Date(dateLast);

  if (dateFirst.substr(0, 10) === dateLast.substr(0, 10)) {
    return format(first, FORMAT_DATE_VIEW);
  }
  return `${format(first, FORMAT_DATE_VIEW)} - ${format(
    last,
    FORMAT_DATE_VIEW
  )}`;
};
