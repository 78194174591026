// Modules
import { connect } from 'react-redux';

// Layout Screen
import View from './view';

// Actions
import { createSchedule } from '../../modules/schedules/actions';
import { deleteModal } from '../../modules/ui/actions';

export default connect(state => ({ isSubmitting: state.ui.isSubmitting }), {
  createSchedule,
  deleteModal,
})(View);
