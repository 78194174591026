// Modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Commons
import Headline from '../../components/Headline';
import { Container } from '../../theme/styles';

import Table from '../../components/TableV2';

class StopsScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table: {
        page: 1,
        order: 'desc',
        orderBy: 'name',
      },
    };
  }

  componentDidMount() {
    const { fetchStops } = this.props;
    fetchStops();
  }

  render() {
    const {
      page,
      table: { order, orderBy },
    } = this.state;
    const { stops } = this.props;
    return (
      <Container>
        <Headline title="Entidades - Paradas" />
        <Table
          page={page}
          order={order}
          orderBy={orderBy}
          rowsPerPage={12}
          fields={[
            {
              key: 'stopCode',
              label: 'Código Parada',
              format: 'default',
            },
            {
              key: 'stopText',
              label: 'Texto Parada',
              format: 'default',
              color: '#ABABB8',
            },
            {
              key: 'name',
              label: 'Nombre',
              format: 'default',
            },
            {
              key: 'search',
              format: 'embed',
            },
          ]}
          data={stops.map(stop => ({
            stopCode: stop.stopCode,
            stopText: stop.stopText,
            name: stop.name,
          }))}
          onChangeField={(key, value) => {
            this.setState(prevState => ({
              ...prevState,
              table: {
                ...prevState.table,
                [key]: value,
              },
            }));
          }}
          hasFilter
        />
      </Container>
    );
  }
}

StopsScreen.propTypes = {
  match: PropTypes.shape({}).isRequired,
  fetchStops: PropTypes.func.isRequired,
  stops: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default StopsScreen;
