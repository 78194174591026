// Modules
import React from 'react';
import PropTypes from 'prop-types';

// Commons
import { Layout, Item, Icon, Image, Label } from './styles';

// Images
import DashboardIcon from './img/dashboard-icon.svg';
import AgentsIcon from './img/agents-icon.svg';
import AccountIcon from './img/accounts-icon.svg';
import UsersIcon from './img/users-icon.svg';

// Methods
const selectedRow = (pathname, key) => {
  const path = pathname.split('/')[1];
  const row = key.split('/')[1];
  return path === row;
};

const renderItemsByRole = (
  items,
  account,
  accountType = 'CLIENT',
  pathname,
  role = ''
) =>
  items.map((item, index) => {
    const selectedItem = item;
    if (selectedItem.label === 'Subcuentas' && account) {
      selectedItem.href = `/accounts/${account}/subaccounts`;
    }

    if (selectedItem.label === 'Agentes' && accountType === 'CLIENT') {
      selectedItem.href = '/client/agents';
    }

    if (
      selectedItem.label === 'Agentes' &&
      accountType === 'CLIENT' &&
      role !== 'ADMIN'
    ) {
      return null;
    }

    const selected = selectedRow(pathname, selectedItem.href);
    return selectedItem.allowed.includes(accountType) ||
      selectedItem.allowed[0] === 'ALL' ? (
      <Item selected={selected} key={index.toString()} to={selectedItem.href}>
        <Icon>
          <Image src={selectedItem.icon} selected={selected} />
        </Icon>
        <Label selected={selected}>{selectedItem.label}</Label>
      </Item>
    ) : null;
  });

const SideMenu = ({ items, user, pathname }) => (
  <Layout>
    {renderItemsByRole(
      items,
      user.account,
      user.accountType,
      pathname,
      user.role
    )}
  </Layout>
);

SideMenu.defaultProps = {
  items: [
    {
      icon: DashboardIcon,
      label: 'Actividad',
      href: '/dashboard',
      allowed: ['ALL'],
    },
    {
      icon: AgentsIcon,
      label: 'Agentes',
      href: '/agents',
      allowed: ['CLIENT', 'MASTER', 'SUPERADMIN'],
    },
    {
      icon: AccountIcon,
      label: 'Cuentas',
      href: '/accounts',
      allowed: ['MASTER', 'SUPERADMIN'],
    },
    {
      icon: AccountIcon,
      label: 'Subcuentas',
      href: '/accounts/2n9nZDTneAlR3wMi3Mx1/subaccounts',
      allowed: ['PARTNER'],
    },
    {
      icon: UsersIcon,
      label: 'Usuarios',
      href: '/users',
      allowed: ['MASTER', 'SUPERADMIN'],
    },
  ],
  user: {},
};

SideMenu.propTypes = {
  pathname: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    })
  ),
  user: PropTypes.shape({
    account: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
  }),
};

export default SideMenu;
