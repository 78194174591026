import styled from 'styled-components';

export const Layout = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h1`
  margin: 0;
  padding: 0;
  color: #687373;
  font-size: 17px;
  font-weight: normal;
  font-family: 'SF UI Display Bold';
`;

export const Headline = styled.h2`
  font-family: 'SF UI Display Medium';
  font-weight: normal;
  margin: 0;
  color: #879292;
  font-size: 13px;
  margin-top: 4px;
`;
