import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { string, object } from 'yup';

import { FormRow, Button } from '../../../theme/styles';
import Logo from '../../../components/Logo';
import IntroText from '../../../components/IntroText';
import Input from '../../../components/Input';
import FormContainer from '../../../components/FormContainer';
import Loader from '../../../components/Loader';
import RememberPasswordLink from '../../../components/RememberPasswordLink';
import { ReSubmittingLabel, GoBackLink, GoBackContainer } from './styles';

class ResetPasswordForm extends React.PureComponent {
  state = {
    alreadyReSubmit: false,
  };

  lockReSubmitting = () => {
    this.setState({ alreadyReSubmit: true }, () => {
      setTimeout(() => this.unlockReSubmitting(), 30000);
    });
  };

  unlockReSubmitting = () => this.setState({ alreadyReSubmit: false });

  render() {
    const { alreadyReSubmit } = this.state;
    const {
      success,
      isLoading,
      requestPassword,
      goBack,
      isSubmitting,
    } = this.props;
    // = = = = = = = = = = = = = = = = = = = = //
    return (
      <Formik
        initialValues={{ email: '' }}
        validationSchema={object().shape({
          email: string().required(),
        })}
        onSubmit={(values, { setSubmitting }) => {
          requestPassword({ email: values.email });
          setSubmitting(false);
        }}
        render={({ values, handleBlur, handleChange, handleSubmit }) => (
          <FormContainer onSubmit={handleSubmit}>
            <Logo />
            {success ? (
              <div>
                <FormRow>
                  <IntroText>
                    Te enviamos un email con las instrucciones
                    <br />
                    para recuperar tu contraseña.
                  </IntroText>
                </FormRow>
                <FormRow margin="28px 0 0">
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {isLoading ? (
                    <Loader color="#93dadb" size="16px" />
                  ) : alreadyReSubmit ? (
                    <ReSubmittingLabel>
                      El email se ha reenviado correctamente.
                    </ReSubmittingLabel>
                  ) : (
                    <RememberPasswordLink
                      onClick={e => {
                        handleSubmit(e);
                        this.lockReSubmitting();
                      }}
                    >
                      Enviar email de nuevo
                    </RememberPasswordLink>
                  )}
                </FormRow>
              </div>
            ) : (
              <div>
                <FormRow>
                  <IntroText>
                    Introduce el email del usuario
                    <br />
                    para recuperar tu contraseña.
                  </IntroText>
                </FormRow>
                <FormRow>
                  <Input
                    type="text"
                    name="email"
                    placeholder="Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {/* touched.email && errors.email && <span>{errors.email}</span> */}
                </FormRow>
                <FormRow margin="30px 0 10px">
                  <Button
                    type="submit"
                    primary
                    width="190px"
                    disabled={isSubmitting || values.isLoading}
                  >
                    {isLoading ? (
                      <Loader color="#fff" size="16px" />
                    ) : (
                      <span>Recuperar contraseña</span>
                    )}
                  </Button>
                </FormRow>
              </div>
            )}
            <GoBackContainer>
              <GoBackLink onClick={() => goBack()}>Volver al login</GoBackLink>
            </GoBackContainer>
          </FormContainer>
        )}
      />
    );
  }
}

ResetPasswordForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  requestPassword: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
};

export default ResetPasswordForm;
