import { handleActions } from 'redux-actions';

import { setLocutions, setLocutionById } from './actions';

const defaultState = {
  list: [],
};

export default handleActions(
  {
    [setLocutions]: (state, action) => ({
      ...state,
      list: action.payload.locutions,
    }),
    [setLocutionById]: (state, action) => ({
      ...state,
      locutionById: action.payload.locution,
    }),
  },
  defaultState
);
