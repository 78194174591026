// Modules
import React from 'react';
import PropTypes from 'prop-types';

// Commons
import { Layout, Container, OutlineWrapper } from './styles';

import Header from './components/Header';
import Body from './components/Body';
import Footer from './components/Footer';

class Table extends React.Component {
  state = {
    filterValue: '',
    pageSize: 8,
  };

  changeField = (field, value) => {
    const { onChangeField } = this.props;
    onChangeField(field, value);
  };

  onSearch = event => {
    this.setState({ filterValue: event.target.value });
  };

  paginate = data => {
    const { pageSize } = this.state;
    let { page } = this.props;
    page -= 1;
    return data.slice(page * pageSize, (page + 1) * pageSize);
  };

  filterTable = data => {
    const { order, orderBy } = this.props;
    const sortedData = data.sort((a, b) => {
      if (!a[orderBy] || !b[orderBy]) {
        return order === 'asc' ? -1 : 1;
      }

      let fieldA;
      let fieldB;

      if (orderBy === 'date') {
        fieldA = a[orderBy];
        fieldB = b[orderBy];
      } else if (orderBy === 'id') {
        fieldA = typeof a[orderBy] === 'string' ? a[orderBy] : parseInt(a[orderBy], 10);
        fieldB = typeof b[orderBy] === 'string' ? b[orderBy] : parseInt(b[orderBy], 10);
      } else {
        fieldA = a[orderBy].toLowerCase();
        fieldB = b[orderBy].toLowerCase();
      }

      const sortCondition = orderValue => {
        if (fieldA === fieldB) {
          return order !== 'asc';
        }

        return orderValue ? fieldA < fieldB : fieldA > fieldB;
      };

      if (sortCondition(order === 'asc')) {
        return -1;
      }

      if (sortCondition(order === 'desc')) {
        return 1;
      }

      return 0;
    });

    return sortedData;
  };

  filterData = (data, hasFilter, filterBy) => {
    const { filterValue } = this.state;

    // Behaviours
    if (data[0] && data[0].action) {
      return data;
    }
    const filteredData = data.filter(value =>
      value[filterBy] ? value[filterBy].includes(filterValue) : null
    );
    return hasFilter ? this.filterTable(filteredData) : this.filterTable(data);
  };

  render() {
    const {
      fields,
      data,
      page,
      order,
      orderBy,
      hasFilter = false,
      filterBy = 'name',
    } = this.props;
    const { pageSize } = this.state;
    // ===========================
    return (
      <Container>
        <OutlineWrapper>
          <Layout>
            <thead>
              <Header
                fields={fields}
                order={order}
                orderBy={orderBy}
                onChangeState={(key, value) => this.changeField(key, value)}
                onSearch={this.onSearch}
              />
            </thead>
            <tbody>
              <Body
                fields={fields}
                data={this.paginate(this.filterData(data, hasFilter, filterBy))}
              />
            </tbody>
          </Layout>
        </OutlineWrapper>
        <Footer
          page={page}
          pageSize={pageSize}
          data={data}
          onChangePage={value => this.changeField('page', page + value)}
        />
      </Container>
    );
  }
}

Table.defaultProps = {
  data: [],
  page: 1,
};

Table.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
      format: PropTypes.oneOf([
        'default',
        'email',
        'number',
        'date',
        'phone',
        'bool',
        'embed',
      ]).isRequired,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  page: PropTypes.number,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  onChangeField: PropTypes.func.isRequired,
  hasFilter: PropTypes.bool.isRequired,
  filterBy: PropTypes.string.isRequired,
};

export default Table;
