// Dependencies
import { handleActions } from 'redux-actions';

// Actions
import {
  fetchIntegrationSuccess,
  editIntegrationSuccess,
  createIntegrationSuccess,
  setSubmitted,
} from './actions';

// Default State
const defaultState = {
  data: null,
  isSubmitted: false,
};

export default handleActions(
  {
    [fetchIntegrationSuccess]: (state, { payload }) => ({
      ...state,
      data: payload,
    }),
    [editIntegrationSuccess]: (state, { payload }) => {
      return {
        ...state,
        isSubmitted: true,
      };
    },
    [createIntegrationSuccess]: (state, { payload }) => {
      return {
        ...state,
        isSubmitted: true,
      };
    },
    [setSubmitted]: state => {
      return {
        ...state,
        isSubmitted: false,
      };
    },
  },
  defaultState
);
