// Modules
import { connect } from 'react-redux';

// Screen Layout
import View from './view';

// Behaviours
import {
  fetchBehaviours,
  deleteBehaviour,
} from '../../modules/behaviours/actions';

// Modal
import { createModal } from '../../modules/ui/actions';

// Redux Connector
export default connect(
  state => ({
    behaviours: state.behaviours.list,
    agent: state.agents.agentById,
  }),
  {
    fetchBehaviours,
    deleteBehaviour,

    // Modal
    createModal,
  }
)(View);
