import styled from 'styled-components';

export const RadioLabel = styled.label`
  margin-right: 20px;
  color: #2d2f3b;
  font-family: 'SF UI Display';
  font-size: 14px;
  line-height: 14px;
`;

export const RadioInput = styled.input`
  margin-left: 5px;
`;

export const Separation = styled.hr`
  width: 90%;
  color: #e8e8e8;
  opacity: 0.3;
`;
