import React, { Component } from 'react';
import PropType from 'prop-types';

class DetailUserScreen extends Component {
  componentDidMount() {
    const {
      fetchUserById,
      match: {
        params: { id },
      },
    } = this.props;
    fetchUserById(id);
  }

  render() {
    const {
      user: { email, role },
    } = this.props;
    return (
      <>
        <p>{email}</p>
        <p>{role}</p>
        <p>CREADO EL</p>
        <p>CREADO POR</p>
      </>
    );
  }
}

DetailUserScreen.propTypes = {
  user: PropType.shape(),
  match: PropType.shape({}).isRequired,
  fetchUserById: PropType.func.isRequired,
};

DetailUserScreen.defaultProps = {
  user: {},
};

export default DetailUserScreen;
