// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Commons
import Headline from '../../components/Headline';
import Table from '../../components/TableV2';
import AgentForm from '../../components/AgentForm';
import DeleteModal from '../../components/DeleteModal';
import { SectionContainer, Button } from '../../theme/styles';

// Assets
import ConfigImg from './img/tune.svg';
import TrashImg from './img/trash-can-outline.svg';

class AgentsScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table: {
        page: 1,
        order: 'desc',
        orderBy: 'name',
      },
    };
  }

  componentDidMount() {
    const { fetchAgents } = this.props;
    fetchAgents();
  }

  createAgent = () => {
    const { createModal } = this.props;
    createModal({
      title: 'Nuevo Agente',
      width: 520,
      body: <AgentForm />,
    });
  };

  render() {
    const {
      table: { page, order, orderBy },
    } = this.state;
    const { me, agents, history, deleteAgent, createModal } = this.props;

    return (
      <SectionContainer>
        {(me.role === 'SUPERADMIN' || me.accountType === 'MASTER') && (
          <Headline
            title="Agentes"
            actions={
              <Button primary onClick={this.createAgent}>
                Nuevo Agente
              </Button>
            }
          />
        )}
        <Table
          page={page}
          order={order}
          orderBy={orderBy}
          rowsPerPage={12}
          fields={[
            {
              key: 'name',
              label: 'Nombre',
              format: 'default',
            },
            {
              key: 'id',
              label: 'ID',
              format: 'number',
              color: '#ABABB8',
            },
            {
              key: 'date',
              label: 'Fecha de creación',
              format: 'date',
              color: '#ABABB8',
            },
            {
              key: 'author',
              label: 'Creado por ',
              format: 'default',
              color: '#66CCCC',
            },
            {
              key: 'actions',
              label: 'Acciones',
              format: 'embed',
            },
          ]}
          data={agents.map((agent, index) => ({
            key: index,
            id: agent.identifier,
            name: agent.name,
            author: agent.authorName || '-',
            date: agent.createdAt,
            actions: [
              {
                icon: (
                  <img
                    alt="config-icon"
                    src={ConfigImg}
                    width="18px"
                    height="18px"
                  />
                ),
                color: 'green',
                text: 'Configurar',
                handler: () => history.push(`/agents/${agent.id}`),
              },
              {
                icon: (
                  <img
                    alt="trash-icon"
                    src={TrashImg}
                    width="13px"
                    height="15px"
                  />
                ),
                color: 'red',
                text: 'Eliminar',
                handler: () =>
                  createModal({
                    title: 'Eliminar Agente',
                    width: 480,
                    body: (
                      <DeleteModal
                        item={agent}
                        title="¿Estas seguro que deseas eliminar este Agente?"
                        removeText="Eliminar Agente"
                        deleteAction={deleteAgent}
                      />
                    ),
                  }),
              },
            ],
          }))}
          onChangeField={(key, value) => {
            this.setState(prevState => ({
              ...prevState,
              table: {
                ...prevState.table,
                [key]: value,
              },
            }));
          }}
        />
      </SectionContainer>
    );
  }
}

AgentsScreen.propTypes = {
  agents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  match: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  fetchAgents: PropTypes.func.isRequired,
  deleteAgent: PropTypes.func.isRequired,
  createModal: PropTypes.func.isRequired,
};

export default AgentsScreen;
