export const FETCH_ACCOUNTS = 'FETCH_ACCOUNTS';
export const FETCH_ACCOUNT_BY_ID = 'FETCH_ACCOUNT_BY_ID';
export const SET_ACCOUNTS = 'SET_ACCOUNTS';
export const SET_ACCOUNT_BY_ID = 'SET_ACCOUNT_BY_ID';
export const FETCH_ACCOUNTS_FAILED = 'FETCH_ACCOUNTS_FAILED';
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const FETCH_ACCOUNT_AGENTS = 'FETCH_ACCOUNT_AGENTS';
export const FETCH_ACCOUNT_AGENTS_SUCCESS = 'FETCH_ACCOUNT_AGENTS_SUCCESS';
export const FETCH_ACCOUNT_AGENTS_FAILED = 'FETCH_ACCOUNT_AGENTS_FAILED';
export const FETCH_ACCOUNT_CLIENTS = 'FETCH_ACCOUNT_CLIENTS';
export const FETCH_ACCOUNT_CLIENTS_SUCCESS = 'FETCH_ACCOUNT_CLIENTS_SUCCESS';
export const FETCH_ACCOUNT_CLIENTS_FAILED = 'FETCH_ACCOUNT_CLIENTS_FAILED';
export const ADD_ACCOUNT_AGENT = 'ADD_ACCOUNT_AGENT';
export const ADD_ACCOUNT_AGENT_SUCCESS = 'ADD_ACCOUNT_AGENT_SUCCESS';
export const ADD_ACCOUNT_AGENT_FAILED = 'ADD_ACCOUNT_AGENT_FAILED';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_FAILED = 'UPDATE_ACCOUNT_FAILED';
export const REMOVE_ACCOUNT_AGENT = 'REMOVE_ACCOUNT_AGENT';
export const REMOVE_ACCOUNT_AGENT_SUCCESS = 'REMOVE_ACCOUNT_AGENT_SUCCESS';
export const REMOVE_ACCOUNT_AGENT_FAILED = 'REMOVE_ACCOUNT_AGENT_FAILED';
export const GET_AGENT_ACCOUNT_INFO = 'GET_AGENT_ACCOUNT_INFO';
