// Modules
import { handleActions } from 'redux-actions';

// Actions
import {
  createModal,
  editModal,
  deleteModal,
  setIsSubmitting,
} from './actions';

// State
const defaultState = {
  modal: {},
  isSubmitting: false,
};

// Reducer
export default handleActions(
  {
    [createModal]: (state, action) => {
      document.body.style.overflow = 'hidden';
      return {
        ...state,
        modal: action.payload,
      };
    },
    [editModal]: (state, action) => ({
      ...state,
      modal: {
        ...state.modal,
        ...action.payload,
      },
    }),
    [deleteModal]: state => {
      document.body.style.overflow = 'auto';
      return {
        ...state,
        modal: {},
      };
    },
    [setIsSubmitting]: (state, { payload }) => ({
      ...state,
      isSubmitting: payload,
    }),
  },
  defaultState
);
