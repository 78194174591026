export const FETCH_SCHEDULES = 'FETCH_SCHEDULES';
export const FETCH_SCHEDULES_SUCCESS = 'FETCH_SCHEDULES_SUCCESS';
export const FETCH_SCHEDULES_FAILED = 'FETCH_SCHEDULES_FAILED';
export const FETCH_SCHEDULE_BY_ID = 'FETCH_SCHEDULE_BY_ID';
export const FETCH_SCHEDULE_BY_ID_SUCCESS = 'FETCH_SCHEDULE_BY_ID_SUCCESS';
export const FETCH_SCHEDULE_BY_ID_FAILED = 'FETCH_SCHEDULE_BY_ID_FAILED';
export const CREATE_SCHEDULE = 'CREATE_SCHEDULE';
export const CREATE_SCHEDULE_SUCCESS = 'CREATE_SCHEDULE_SUCCESS';
export const CREATE_SCHEDULE_FAILED = 'CREATE_SCHEDULE_FAILED';
export const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE';
export const UPDATE_SCHEDULE_SUCCESS = 'UPDATE_SCHEDULE_SUCCESS';
export const UPDATE_SCHEDULE_FAILED = 'UPDATE_SCHEDULE_FAILED';
export const DELETE_SCHEDULE = 'DELETE_SCHEDULE';
export const DELETE_SCHEDULE_SUCCESS = 'DELETE_SCHEDULE_SUCCESS';
export const DELETE_SCHEDULE_FAILED = 'DELETE_SCHEDULE_FAILED';
