import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Layout = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
`;

export const BehaviourIcon = styled.div`
  height: 30px;
  width: 30px;
  padding: 0px 15px;
  background-repeat: no-repeat;
  background-position: center;
  border: none !important;
  ${props => `
    ${
      props.selected
        ? `
        background-image: url('/icons/behaviour-selected.svg');

    `
        : `
        background-image: url('/icons/behaviour.svg');

    `
    }
  `} transition: all .15s ease-in-out;
`;
export const CallsIcon = styled.div`
  height: 30px;
  width: 30px;
  padding: 0px 15px;
  background-repeat: no-repeat;
  background-position: center;
  border: none !important;
  ${props => `
    ${
      props.selected
        ? `
        background-image: url('/icons/calls-selected.svg');

    `
        : `
        background-image: url('/icons/calls.svg');

    `
    }
  `} transition: all .15s ease-in-out;
`;
export const Icon = styled.i`
  margin-right: 12px;
  width: 19px;
  height: 19px;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
  opacity: 0.5;
  ${props => `
    ${props.selected &&
      `
      filter: grayscale(0%);
      opacity: 1;
    `}
  `}
`;

export const EntitiesIcon = styled.div`
  height: 30px;
  width: 30px;
  padding: 0px 15px;
  background-repeat: no-repeat;
  background-position: center;
  border: none !important;
  ${props => `
    ${
      props.selected
        ? `
        background-image: url('/icons/entities-selected.svg');

    `
        : `
        background-image: url('/icons/entities.svg');

    `
    }
  `} transition: all .15s ease-in-out;
`;

export const IntegrationIcon = styled.div`
  height: 30px;
  width: 30px;
  padding: 0px 15px;
  background-repeat: no-repeat;
  background-position: center;
  border: none !important;
  ${props => `
    ${
      props.selected
        ? `
        background-image: url('/icons/integration-selected.svg');

    `
        : `
        background-image: url('/icons/integration.svg');

    `
    }
  `} transition: all .15s ease-in-out;
`;

export const LocutionsIcon = styled.div`
  height: 30px;
  width: 30px;
  padding: 0px 15px;
  background-repeat: no-repeat;
  background-position: center;
  border: none !important;
  ${props => `
    ${
      props.selected
        ? `
        background-image: url('/icons/locutions-selected.svg');

    `
        : `
        background-image: url('/icons/locutions.svg');

    `
    }
  `} transition: all .15s ease-in-out;
`;

export const Navigator = styled.nav`
  width: 260px;
  margin-right: 20px;
`;

export const Container = styled.div`
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
`;

export const Column = styled.ul`
  ${props => `
    ${props.separator &&
      `
      border-bottom: 1px solid #E8E8E8;  
    `}
    ${props.padding &&
      `
      padding: ${props.padding};
    `}
  `};
`;

export const Field = styled.li`
  padding-bottom: 28px;
`;

export const Label = styled.i`
  color: #ababb8;
  font-size: 12px;
  font-style: normal;
  text-transform: uppercase;
  font-family: 'SF UI Display Medium';
`;

export const Value = styled.div`
  font-family: 'SF UI Display Medium';
  font-size: 14px;
  padding-top: 2px;
  color: ${props => props.color || '#243847'};
`;

export const Option = styled.li`
  box-shadow: inset 0px 0 0 transparent;
  font-family: 'SF UI Display Medium';
  font-size: 14px;
  padding-top: 2px;
  padding-bottom: 5px;
  color: #dadddf;
  font-size: 14px;
  ${props => `
    ${
      props.selected
        ? `
      color: #000;
      box-shadow: inset 2px 0 0 #a2e1e1;
    `
        : `
      &:hover {
        color: #999;
      }
    `
    }
  `} transition: all .15s ease-in-out;
`;

export const SubOption = styled.li`
  box-shadow: inset 0px 0 0 transparent;
  font-family: 'SF UI Display Medium';
  font-size: 14px;
  padding-top: 2px;
  padding-bottom: 5px;
  color: #dadddf;
  font-size: 14px;
  ${props => `
    ${
      props.selected
        ? `
      color: #000;
      box-shadow: inset 2px 0 0 #a2e1e1;
    `
        : `
    color: #dadddf;
      box-shadow: inset 2px 0 0 #E7E7E7;
      &:hover {
        color: #999;
      }
    `
    }
  `} transition: all .15s ease-in-out;
`;

export const LinkElement = styled(Link)`
  color: inherit;
  padding: 0 5px;
  display: flex;
  height: 46px;
  align-items: center;
`;

export const SubLinkContainer = styled.div`
  padding: 10px 0 10px 30px;
  font-size: 14px;
`;

export const SubLinkElement = styled(Link)`
  color: inherit;
  padding: 0 20px 0 10px;
  display: flex;
  height: 23px;
  align-items: center;
`;

export const OptionLabel = styled.span``;

export const Body = styled.div`
  flex: 1;
  width: 100%;
`;
