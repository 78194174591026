import React from 'react';
import PropType from 'prop-types';

import { FlexColumn, Button } from '../../theme/styles';
import Actions from '../UI/Modal/components/Actions';
import { ModalInputsContainer } from '../ModalInputsContainer/styles';
import { ModalInputsRowContainer } from '../ModalInputsRowContainer/styles';

const DeleteModal = ({
  item,
  phone,
  deleteModal,
  title,
  deleteAction,
  removeText,
}) => (
  <>
    <ModalInputsContainer textOnly paddingBottom="60">
      <ModalInputsRowContainer center>
        <FlexColumn>
          <span>{title}</span>
        </FlexColumn>
      </ModalInputsRowContainer>
      <ModalInputsRowContainer center>
        <FlexColumn>
          <span>Esta acción no puede deshacerse</span>
        </FlexColumn>
      </ModalInputsRowContainer>
    </ModalInputsContainer>
    <Actions>
      <Button type="button" onClick={() => deleteModal()}>
        Cancelar
      </Button>
      <Button
        danger
        type="button"
        onClick={() => {
          deleteAction({ item, phone });
          deleteModal();
        }}
      >
        {removeText}
      </Button>
    </Actions>
  </>
);

DeleteModal.propTypes = {
  deleteModal: PropType.func.isRequired,
  values: PropType.shape({}),
  touched: PropType.shape({}),
  errors: PropType.shape({}),
  removeText: PropType.string.isRequired,
  title: PropType.string.isRequired,
};

DeleteModal.defaultProps = {
  values: {},
  touched: {},
  errors: {},
};

export default DeleteModal;
