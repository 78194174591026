// Modules
import React from 'react';
import PropTypes from 'prop-types';

// Commons
import {
  Layout,
  Container,
  Outline,
  Header,
  Title,
  Body,
  Close,
  Icon,
} from './styles';

import Actions from './components/Actions';

// Images
import CloseIcon from './img/close.svg';

// Component
const Modal = ({ title, body, width, actions, deleteModal }) => (
  <Layout>
    <Outline>
      <div style={{ padding: '2em 1em' }}>
        <Container width={width}>
          <Header>
            <Title>{title}</Title>
            <Close onClick={deleteModal}>
              <Icon src={CloseIcon} />
            </Close>
          </Header>
          <Body>{body}</Body>
          {actions && <Actions>{actions}</Actions>}
        </Container>
      </div>
    </Outline>
  </Layout>
);

Modal.defaultProps = {
  title: '',
  width: 500,
  body: null,
  actions: null,
};

Modal.propTypes = {
  title: PropTypes.node,
  body: PropTypes.element,
  actions: PropTypes.element,
  width: PropTypes.number,
  deleteModal: PropTypes.func.isRequired,
};

export default Modal;
