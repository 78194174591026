import { createActions } from 'redux-actions';

import {
  FETCH_BEHAVIOURS,
  FETCH_BEHAVIOUR_BY_ID,
  SET_BEHAVIOURS,
  SET_BEHAVIOUR_BY_ID,
  FETCH_BEHAVIOURS_FAILED,
  CREATE_BEHAVIOUR,
  DELETE_BEHAVIOUR,
} from './actionTypes';

export const {
  fetchBehaviours,
  setBehaviours,
  setBehaviourById,
  setError,
} = createActions(
  FETCH_BEHAVIOURS,
  SET_BEHAVIOURS,
  SET_BEHAVIOUR_BY_ID,
  FETCH_BEHAVIOURS_FAILED
);

export const {
  createBehaviour,
  deleteBehaviour,
  fetchBehaviourById,
} = createActions({
  [CREATE_BEHAVIOUR]: behaviour => ({ behaviour }),
  [DELETE_BEHAVIOUR]: behaviour => ({ behaviour }),
  [FETCH_BEHAVIOUR_BY_ID]: id => ({ id }),
});
