export const CREATE_INTEGRATION = 'CREATE_INTEGRATION';
export const CREATE_INTEGRATION_SUCCESS = 'CREATE_INTEGRATION_SUCCESS';
export const CREATE_INTEGRATION_FAILED = 'CREATE_INTEGRATION_FAILED';

export const FETCH_INTEGRATION = 'FETCH_INTEGRATION';
export const FETCH_INTEGRATION_SUCCESS = 'FETCH_INTEGRATION_SUCCESS';
export const FETCH_INTEGRATION_FAILED = 'FETCH_INTEGRATION_FAILED';

export const EDIT_INTEGRATION = 'EDIT_INTEGRATION';
export const EDIT_INTEGRATION_SUCCESS = 'EDIT_INTEGRATION_SUCCESS';
export const EDIT_INTEGRATION_FAILED = 'EDIT_INTEGRATION_FAILED';
export const SET_SUBMITTED = 'SET_SUBMITTED';
