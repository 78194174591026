import { takeLatest, put, call } from 'redux-saga/effects';

import {
  FETCH_USERS,
  CREATE_USER,
  DELETE_USER,
  FETCH_USER_BY_ID,
  REGISTER_USER,
  FETCH_USER_BY_UID,
  SET_PASSWORD,
} from './actionTypes';
import {
  fetchUsers as getUsers,
  setUsers,
  setUserById,
  fetchUserByUidSuccess,
  setError,
  setPasswordSuccess,
  setPasswordFailed,
} from './actions';
import { deleteModal, setIsSubmitting } from '../ui/actions';

import * as api from './api';
import Firebase from '../../lib/firebase/instance';

function* fetchUsers() {
  try {
    const response = yield call(api.fetchUsers);
    yield put(setUsers({ users: response.data.data }));
  } catch (e) {
    console.error({ e });
  }
}

export function* watchFetchUsers() {
  yield takeLatest(FETCH_USERS, fetchUsers);
}

function* fetchUserById({ payload: { id } }) {
  try {
    const response = yield call(api.fetchUserById, id);
    yield put(setUserById({ user: response.data }));
  } catch (e) {
    console.error({ e });
  }
}

export function* watchFetchUserById() {
  yield takeLatest(FETCH_USER_BY_ID, fetchUserById);
}

function* fetchUserByUid({ payload: { uid } }) {
  try {
    const response = yield call(api.fetchUserByUid, uid);
    yield put(fetchUserByUidSuccess({ user: response.data }));
  } catch (e) {
    console.error({ e });
  }
}

export function* watchFetchUserByUid() {
  yield takeLatest(FETCH_USER_BY_UID, fetchUserByUid);
}

function* createUser({ payload }) {
  try {
    yield put(setIsSubmitting(true));
    const response = yield call(api.createUser, payload.user);
    if (response && response.data) {
      yield put(getUsers());
      yield put(deleteModal());
      yield put(setIsSubmitting(false));
    }
  } catch (e) {
    yield put(setIsSubmitting(false));
    yield put(setError('El correo electrónico está en uso.'));
    console.error({ e });
  }
}

export function* watchCreateUser() {
  yield takeLatest(CREATE_USER, createUser);
}

function* setPassword({ payload }) {
  const { userId, data } = payload;
  try {
    yield put(setIsSubmitting(true));
    const response = yield call(api.setPassword, {
      userId,
      data: { password: data },
    });
    if (response && response.data) {
      yield put(setPasswordSuccess());
    }
    yield put(setIsSubmitting(false));

    if (
      response.status === 500 ||
      response.status === 401 ||
      response.status === 404
    ) {
      yield put(setPasswordFailed(response.data));
    }
  } catch (e) {
    yield put(setIsSubmitting(false));
    console.error(e);
    yield put(setPasswordFailed(e));
  }
}

export function* watchSetPassword() {
  yield takeLatest(SET_PASSWORD, setPassword);
}

function* deleteUser({ payload }) {
  try {
    const response = yield call(api.deleteUser, payload.user.item.id);
    if (response && response.data) {
      yield put(getUsers());
    }
  } catch (e) {
    console.error({ e });
  }
}

export function* watchDeleteUser() {
  yield takeLatest(DELETE_USER, deleteUser);
}

function* registerUser({ payload: { email, password } }) {
  const firebase = new Firebase();
  try {
    yield put(setIsSubmitting(true));
    const response = yield call(firebase.createUserWithEmailAndPassword, {
      email,
      password,
    });
    const { user } = response;
    if (user && !user.emailVerified === false) {
      console.log('email not verified. Sending email');
      yield call(user.sendEmailVerification);
    } else {
      console.log('email already verified');
    }
    const userInfo = {
      email,
    };
    yield call(api.registerFirebaseUser, user.uid, userInfo);
    yield put(setIsSubmitting(false));
  } catch (e) {
    yield put(setIsSubmitting(false));
    console.error({ e });
  }
}

export function* watchRegisterUser() {
  yield takeLatest(REGISTER_USER, registerUser);
}
