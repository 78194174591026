// Modules
import { createActions } from 'redux-actions';

// ActionTypes
import {
  CREATE_MODAL,
  EDIT_MODAL,
  DELETE_MODAL,
  SET_IS_SUBMITTING,
} from './actionTypes';

// Actions
export const {
  createModal,
  editModal,
  deleteModal,
  setIsSubmitting,
} = createActions(CREATE_MODAL, EDIT_MODAL, DELETE_MODAL, SET_IS_SUBMITTING);
