import React from 'react';
import PropType from 'prop-types';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import { FormRow, Button } from '../../../theme/styles';
import Logo from '../../../components/Logo';
import IntroText from '../../../components/IntroText';
import Input from '../../../components/Input';
import FormContainer from '../../../components/FormContainer';
import Loader from '../../../components/Loader';

const InnerForm = ({
  values,
  // errors,
  // touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
}) => (
  <FormContainer onSubmit={handleSubmit}>
    <Logo />
    {!values.success ? (
      <div>
        <FormRow>
          <IntroText>
            Introduce y confirma la nueva contraseña
            <br />
            para acceder a tu cuenta
          </IntroText>
        </FormRow>
        <FormRow>
          <Input
            type="password"
            name="password"
            placeholder="Nueva contraseña"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            noBorderRadiusBottom
          />
          {/* touched.password && errors.password && <span>{errors.password}</span> */}
        </FormRow>
        <FormRow>
          <Input
            type="password"
            name="password_repeat"
            noBorderRadiusTop
            placeholder="Repetir nueva contraseña"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password_repeat}
          />
          {/* touched.password && errors.password && <span>{errors.password}</span> */}
        </FormRow>
        <FormRow margin="30px 0 10px">
          <Button
            type="submit"
            primary
            width="180px"
            disabled={isSubmitting || values.isLoading}
          >
            {values.isLoading ? (
              <Loader color="#fff" size="18px" />
            ) : (
              <span>Cambiar contraseña</span>
            )}
          </Button>
        </FormRow>
      </div>
    ) : (
      <IntroText>¡La contraseña ha sido cambiada con éxito!</IntroText>
    )}
  </FormContainer>
);

InnerForm.propTypes = {
  values: PropType.shape({}).isRequired,
  errors: PropType.shape({}).isRequired,
  touched: PropType.shape({}).isRequired,
  isSubmitting: PropType.bool.isRequired,
  handleChange: PropType.func.isRequired,
  handleBlur: PropType.func.isRequired,
  handleSubmit: PropType.func.isRequired,
};

const Form = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ token, isLoading, success }) => ({
    password: '',
    password_repeat: '',
    token,
    isLoading,
    success,
  }),
  validationSchema: Yup.object().shape({
    password: Yup.string().required('Campo obligatorio'),
    password_repeat: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')
      .required('Campo obligatorio'),
  }),
  handleSubmit: (
    { token, password },
    { props: { validateResetPassword }, setSubmitting }
  ) => {
    validateResetPassword({ token, password });
    setSubmitting(true);
  },
})(InnerForm);

export default Form;
