// Dependencies
import { connect } from 'react-redux';

// Actions
import {
  createIntegration,
  fetchIntegration,
  editIntegration,
  setSubmitted,
} from '../../modules/integrations/actions';

// Component
import View from './view';

// Redux Connector
export default connect(
  state => ({
    clientId: state.auth.me.id,
    data: state.integrations.data,
    isSubmitted: state.integrations.isSubmitted,
    isSubmitting: state.ui.isSubmitting,
  }),
  {
    editIntegration,
    createIntegration,
    fetchIntegration,
    setSubmitted,
  }
)(View);
