// Modules
import { connect } from 'react-redux';

// Screen Layout
import View from './view';

// Actions
import { fetchPaths } from '../../modules/paths/actions';

// Redux Connector
export default connect(
  state => ({
    paths: state.paths.list,
  }),
  {
    fetchPaths,
  }
)(View);
