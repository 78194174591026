// Dependencies
import { connect } from 'react-redux';

// View
import NewPasswordForm from './stateless';

// Actions
import { validateResetPassword } from '../../../modules/reset-password/actions';

// Connector
export default connect(
  state => ({
    isLoading: state.resetPassword.isLoading,
    success: state.resetPassword.success,
    isSubmitting: state.ui.isSubmitting,
  }),
  {
    validateResetPassword,
  }
)(NewPasswordForm);
