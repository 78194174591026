// Dependencies
import { post, put } from '../../lib/restClient';

// Request Password
export const requestPassword = async data =>
  post('/reset-password/request', data);

// Validate Token
export const validateResetPassword = async data =>
  put('/reset-password/validate', data);
