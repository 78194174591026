import { takeLatest, call, put } from 'redux-saga/effects';

import {
  FETCH_SCHEDULES,
  CREATE_SCHEDULE,
  FETCH_SCHEDULE_BY_ID,
  UPDATE_SCHEDULE,
  DELETE_SCHEDULE,
} from './actionTypes';

import {
  fetchSchedules as getSchedules,
  fetchScheduleById as getScheduleById,
  fetchSchedulesFailed,
  fetchSchedulesSuccess,
  createScheduleSuccess,
  createScheduleFailed,
  fetchScheduleByIdFailed,
  fetchScheduleByIdSuccess,
  updateScheduleFailed,
  updateScheduleSuccess,
} from './actions';

import * as api from './api';
import { deleteModal, setIsSubmitting } from '../ui/actions';

function* fetchSchedules({ payload: { phone } }) {
  try {
    const response = yield call(api.fetchSchedules, phone);
    yield put(fetchSchedulesSuccess(response.data));
  } catch (e) {
    console.error(e);
    yield put(fetchSchedulesFailed());
  }
}

export function* watchFetchSchedules() {
  yield takeLatest(FETCH_SCHEDULES, fetchSchedules);
}

function* fetchScheduleById({ payload }) {
  try {
    const response = yield call(api.fetchScheduleById, payload.id);
    yield put(fetchScheduleByIdSuccess(response.data));
  } catch (e) {
    console.error(e);
    yield put(fetchScheduleByIdFailed());
  }
}

export function* watchFetchScheduleById() {
  yield takeLatest(FETCH_SCHEDULE_BY_ID, fetchScheduleById);
}

function* createSchedule({ payload }) {
  try {
    yield put(setIsSubmitting(true));
    yield call(api.create, payload.data);
    yield put(createScheduleSuccess());
    yield put(getSchedules({ phone: payload.data.agent }));
    yield put(deleteModal());
    yield put(setIsSubmitting(false));
  } catch (e) {
    yield put(setIsSubmitting(false));
    console.error(e);
    yield put(createScheduleFailed());
  }
}

export function* watchCreateSchedule() {
  yield takeLatest(CREATE_SCHEDULE, createSchedule);
}

function* updateSchedule({ payload }) {
  try {
    yield put(setIsSubmitting(true));
    yield call(api.update, payload.id, payload.data);
    yield put(updateScheduleSuccess());
    yield put(getScheduleById({ id: payload.id }));
    yield put(getSchedules({ phone: payload.data.agentPhone }));
    yield put(deleteModal());
    yield put(setIsSubmitting(false));
  } catch (e) {
    yield put(setIsSubmitting(false));
    console.error(e);
    yield put(updateScheduleFailed());
  }
}

export function* watchUpdateSchedule() {
  yield takeLatest(UPDATE_SCHEDULE, updateSchedule);
}

function* deleteSchedule({ payload }) {
  try {
    if (payload.item.id) {
      const response = yield call(api.deleteSchedule, payload.item.id);
      if (response && response.status === 200 && payload.item.phone) {
        yield put(getSchedules({ phone: payload.item.phone }));
      }
    }
  } catch (e) {
    console.error(e);
    yield put(updateScheduleFailed());
  }
}

export function* watchDeleteSchedule() {
  yield takeLatest(DELETE_SCHEDULE, deleteSchedule);
}
