import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Layout = styled.nav`
  display: flex;
  flex-direction: column;
  width: 235px;
  padding: 21px 0;
`;

export const Item = styled(Link)`
  padding: 22px 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: inset 0px 0 0 transparent;
  ${props => `
    ${props.selected &&
      `
      box-shadow: inset 2px 0 0 #2D2F3B;
    `}
  `}
  transition: box-shadow .2s ease-in-out;
`;

export const Icon = styled.i`
  margin-right: 12px;
  width: 19px;
  height: 19px;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
  opacity: 0.5;
  ${props => `
    ${props.selected &&
      `
      filter: grayscale(0%);
      opacity: 1;
    `}
  `}
`;

export const Label = styled.span`
  font-size: 14px;
  font-family: 'SF UI Display Medium';
  color: #dadddf;
  ${props => `
    ${props.selected &&
      `
      color: #2D2F3B;
    `}
  `}
`;
