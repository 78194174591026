import { createActions } from 'redux-actions';

import {
  FETCH_AGENTS,
  FETCH_AGENT_BY_ID,
  FETCH_AGENT_BY_PHONE,
  FETCH_AGENT_BY_PHONE_SUCCESS,
  FETCH_AGENT_BY_PHONE_FAILED,
  FETCH_AGENT_REASONS,
  FETCH_AGENT_REASONS_SUCCESS,
  FETCH_AGENT_REASONS_FAILED,
  SET_AGENTS,
  SET_AGENT_BY_ID,
  FETCH_AGENTS_FAILED,
  CREATE_AGENT,
  CREATE_AGENT_SUCCESS,
  CREATE_AGENT_FAILED,
  DELETE_AGENT,
  FETCH_AGENT_AUDIOS,
  FETCH_AGENT_AUDIOS_SUCCESS,
  FETCH_AGENT_AUDIOS_FAILED,
  GET_AGENT_ROLE,
} from './actionTypes';

export const {
  fetchAgents,
  fetchAgentReasons,
  fetchAgentReasonsSuccess,
  fetchAgentReasonsFailed,
  fetchAgentByPhone,
  fetchAgentByPhoneSuccess,
  fetchAgentByPhoneFailed,
  setAgents,
  setAgentById,
  setError,
  fetchAgentAudios,
  fetchAgentAudiosSuccess,
  fetchAgentAudiosFailed,
  createAgentSuccess,
  createAgentFailed,
  getAgentRole,
} = createActions(
  FETCH_AGENTS,
  FETCH_AGENT_REASONS,
  FETCH_AGENT_REASONS_SUCCESS,
  FETCH_AGENT_REASONS_FAILED,
  FETCH_AGENT_BY_PHONE,
  FETCH_AGENT_BY_PHONE_SUCCESS,
  FETCH_AGENT_BY_PHONE_FAILED,
  SET_AGENTS,
  SET_AGENT_BY_ID,
  FETCH_AGENTS_FAILED,
  FETCH_AGENT_AUDIOS,
  FETCH_AGENT_AUDIOS_SUCCESS,
  FETCH_AGENT_AUDIOS_FAILED,
  CREATE_AGENT_SUCCESS,
  CREATE_AGENT_FAILED,
  GET_AGENT_ROLE
);

export const { createAgent, deleteAgent, fetchAgentById } = createActions({
  [CREATE_AGENT]: agent => ({ agent }),
  [DELETE_AGENT]: agent => ({ agent }),
  [FETCH_AGENT_BY_ID]: id => ({ id }),
});
