import { connect } from 'react-redux';
import View from './view';
import { fetchAgents } from '../../modules/agents/actions';
import { fetchAccountAgents } from '../../modules/accounts/actions';

export default connect(
  state => ({
    user: state.auth.me,
    agents: state.agents.list,
    accountAgents: state.accounts.agents,
  }),
  {
    fetchAgents,
    fetchAccountAgents,
  }
)(View);
