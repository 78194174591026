// Modules
import { connect } from 'react-redux';

// Layout Screen
import View from './view';

// Actions
import { createSubaccount } from '../../modules/subaccounts/actions';
import { deleteModal } from '../../modules/ui/actions';

export default connect(state => ({ isSubmitting: state.ui.isSubmitting }), {
  createSubaccount,
  deleteModal,
})(View);
