import styled from 'styled-components';
import { backgroundColor } from './colours';

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  ${props =>
    props.centerText &&
    `
  text-center: center
  `};
`;
export const Container = styled.div`
  width: 100%;
  background: ${backgroundColor};
  display: flex;
  flex-direction: column;
  ${props => ({
    width: props.smallDetail ? '230px' : '100%',
    'margin-left': props.marginLeft ? '20px' : '0',
  })};
`;

export const AddContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 10px;
  ${props => ({
    width: props.widthBehaviour ? '350px' : '310px',
  })};
`;

export const AppContainer = styled.div`
  width: 100%;
  background: ${backgroundColor};
  display: flex;
  flex-direction: row;
  margin-top: 3px;
`;

export const SectionContainer = styled.div`
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
`;

export const ReturnLinkText = styled.p`
  color: #66cccc;
  font-size: 14px;
  line-height: 16px;

  ${props => props.noMargin && `margin: 0;`}
`;

export const DetailContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DetailInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e8e8e8;
  padding: 10px 20px;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 4px 10px 0 rgb(0 0 0 / 5%);
`;

export const DetailTitle = styled.h6`
  color: #ababb8;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin: 0;
`;

export const DetailInfoTextBlue = styled(DetailTitle)`
  color: #66cccc;
  font-size: 18px;
  line-height: 20px;
  font-weight: 0;
`;

export const DetailInfoText = styled.p`
  color: #243847;
  font-size: 26px;
  line-height: 31px;
  margin: 0 0 30px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  &:hover {
    overflow: visible;
  }
`;

export const FormRow = styled.div`
  display: flex;
  ${props =>
    props.margin &&
    `
    margin: ${props.margin};
  `};
`;

export const Button = styled.button`
  font-size: 14px;
  padding: 13px 22px;
  border-radius: 3px;
  cursor: pointer;
  color: ${props => (props.danger ? '#F44336' : '#44444d')};
  background-color: white;
  border: 1px solid white;
  width: ${props => props.width || 'auto'};
  font-family: SF UI Display Medium;
  transition: all 0.1s ease-in-out;
  margin-right: ${({ marginRight }) => marginRight || 0}px;
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
  &:hover {
    background-color: #e7ebef;
  }
  ${props => props.noPadding && `padding: 0;`}
  ${props => props.disabled && `opacity: 0.4`}
  ${props =>
    props.primary &&
    `color: white;
  background-color: #71CFCF;
  border: 1px solid #3DCECE;

  &:hover {
    background: #68c1c1;
  }`}
  ${props =>
    props.secondary &&
    `
      color: #7cd5d5;
          background: #0000;
          border: 1px solid #0000;
      `}
      ${props =>
        props.danger &&
        `
        background: #FFEBEE;
        border: 1px solid #FFD9D8;
        &:hover {
          background-color: #ffd6dc;
        }
          `}

`;
