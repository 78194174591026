import { handleActions, combineActions } from 'redux-actions';

import { STATUS } from './constants';
import {
  checkLoginSuccess,
  checkLoginFailed,
  loginSuccess,
  loginFailed,
  setToken,
  setMe,
  login,
} from './actions';

const defaultState = {
  status: STATUS.NOT_CHECKED,
  isLoading: false,
};

export default handleActions(
  {
    [combineActions(checkLoginSuccess, loginSuccess)]: state => ({
      ...state,
      status: STATUS.LOGGED,
      isLoading: false,
    }),
    [login]: state => ({
      ...state,
      isLoading: true,
    }),
    [checkLoginFailed]: state => ({ ...state, status: STATUS.NOT_LOGGED }),
    [loginFailed]: state => ({
      ...state,
      status: STATUS.LOGGED_ERROR,
      isLoading: false,
    }),
    [setToken]: (state, action) => ({
      ...state,
      token: action.payload.token,
      status: STATUS.LOGGED,
    }),
    [setMe]: (state, action) => ({
      ...state,
      status: STATUS.LOGGED,
      me: action.payload.me,
    }),
  },
  defaultState
);
