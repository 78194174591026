import { get, post, del, postAudio } from '../../lib/restClient';

export const fetchAgents = async () => get('/agents');

export const fetchAgentById = async id => get(`/agents/${id}`);

export const fetchAgentByPhone = async phone => get(`/agents/phone/${phone}`);

export const fetchAgentAudios = async id => get(`/agents/${id}/audios`);

export const fetchAgentReasons = async id => get(`/agents/${id}/reasons`);

export const createAgent = async data => post('/agents', data);

export const deleteAgent = async id => del(`/agents/${id}`);

export const fetchAssignedAgents = async () => get('/assigned-agents');

export const createAudio = async data => postAudio('/audios', data);
