// Modules
import React from 'react';
import PropType from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Actions from '../UI/Modal/components/Actions';

import { FlexColumn, Button } from '../../theme/styles';
import { Label } from '../Label/styles';
import { ModalInputsContainer } from '../ModalInputsContainer/styles';
import { ModalInputsRowContainer } from '../ModalInputsRowContainer/styles';

import Select from '../Select';
import Input from '../Input';

const errorMessage = 'Este campo es obligatorio';
const validation = Yup.object().shape({
  reason: Yup.string().required(errorMessage),
  schedule: Yup.string().required(errorMessage),
  action: Yup.string().required(errorMessage),
  destination: Yup.string().when('action', {
    is: val => val !== 'DIALOG',
    then: Yup.string().test('destination', errorMessage, value => !!value),
  }),
});

const actionTypes = [
  { name: 'Transferencia', value: 'TRANSFER' },
  { name: 'Locución', value: 'LOCUTION' },
  { name: 'Diálogo', value: 'DIALOG' },
];

class BehaviourForm extends React.Component {
  componentDidMount() {
    const {
      id,
      agentPhone,
      fetchReasons,
      fetchSchedules,
      fetchAgentById,
      fetchDestinations,
      fetchLocutions,
    } = this.props;
    fetchAgentById(id);
    fetchReasons();
    fetchSchedules({ phone: agentPhone });
    fetchDestinations({ phone: agentPhone });
    fetchLocutions({ phone: agentPhone });
  }

  checkValueById = (arr, value) => arr.find(el => el.id === value);

  sortingByName = listReasons => {
    const listName = listReasons.sort((a, b) =>
      a.reason.toLowerCase() > b.reason.toLowerCase() ? 1 : -1
    );
    return listName;
  };

  renderDestinations = ({
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
  }) => {
    const { destinations, locutions, agent } = this.props;
    console.log({destinations, locutions})
    if (values.action === 'DIALOG') {
      return null;
    }
    if (values.action === 'TRANSFER') {
      if (
        destinations.length > 0 &&
        !this.checkValueById(destinations, values.destination)
      ) {
        setFieldValue('destination', destinations[0] ? destinations[0].id : '');
      }
      return (
        <FlexColumn>
          <Label htmlFor="destination" style={{ display: 'block' }}>
            Destino
          </Label>
          <Select marginRight id="destination" onChange={handleChange}>
            {destinations.map(locution => (
              <option key={locution.value} value={locution.id}>
                {locution.name}
              </option>
            ))}
          </Select>
          {errors.destination && touched.destination && (
            <div className="input-feedback">{errors.destination}</div>
          )}
        </FlexColumn>
      );
    }

    if (
      locutions.length > 0 &&
      !this.checkValueById(locutions, values.destination)
    ) {
      setFieldValue('destination', locutions[0] ? locutions[0].id : '');
    }

    return (
      <FlexColumn>
        <Label htmlFor="destination" style={{ display: 'block' }}>
          Destino
        </Label>
        <Select marginRight id="destination" onChange={handleChange}>
          {locutions.map(locution => (
            <option key={locution.value} value={locution.id}>
              {locution.name}
            </option>
          ))}
        </Select>
        {errors.destination && touched.destination && (
          <div className="input-feedback">{errors.destination}</div>
        )}
      </FlexColumn>
    );
  };

  render() {
    const {
      agentPhone,
      deleteModal,
      schedules,
      reasons,
      createBehaviour,
      agent,
      sortingByName,
      isSubmitting,
    } = this.props;

    return (
      <Formik
        initialValues={{
          reason: reasons[0] ? reasons[0].id : '',
          schedule: schedules[0] ? schedules[0].id : '',
          action: actionTypes[0].value,
          destination: '',
          agent: agentPhone,
        }}
        enableReinitialize
        onSubmit={values => {
          createBehaviour(values);
        }}
        validationSchema={validation}
      >
        {formProps => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
          } = formProps;
          return (
            <form>
              <ModalInputsContainer padding={10}>
                <ModalInputsRowContainer spaceBetween>
                  <FlexColumn>
                    <Label htmlFor="reason" style={{ display: 'block' }}>
                      Motivo
                    </Label>

                    <Select marginRight id="reason" onChange={handleChange}>
                      {this.sortingByName(reasons).map(reason => {
                        if (reason.activated && reason.agentId === agent.id) {
                          return (
                            <option key={reason.id} value={reason.id}>
                              {reason.reason}
                            </option>
                          );
                        }
                        return false;
                      })}
                    </Select>
                    {errors.reason && touched.reason && (
                      <div className="input-feedback">{errors.reason}</div>
                    )}
                  </FlexColumn>

                  <FlexColumn>
                    <Label htmlFor="schedule" style={{ display: 'block' }}>
                      Horario
                    </Label>
                    <Select marginRight id="schedule" onChange={handleChange}>
                      {schedules.map(schedule => (
                        <option key={schedule.id} value={schedule.id}>
                          {schedule.name}
                        </option>
                      ))}
                    </Select>
                    {errors.schedule && touched.schedule && (
                      <div className="input-feedback">{errors.schedule}</div>
                    )}
                  </FlexColumn>
                </ModalInputsRowContainer>

                <ModalInputsRowContainer>
                  <FlexColumn>
                    <Label htmlFor="action" style={{ display: 'block' }}>
                      Acción
                    </Label>
                    <Select
                      marginRight
                      id="action"
                      onChange={e => setFieldValue('action', e.target.value)}
                    >
                      {actionTypes.map(actionType => (
                        <option key={actionType.name} value={actionType.value}>
                          {actionType.name}
                        </option>
                      ))}
                    </Select>
                    {errors.action && touched.action && (
                      <div className="input-feedback">{errors.nif}</div>
                    )}
                  </FlexColumn>

                  {this.renderDestinations({
                    values,
                    errors,
                    touched,
                    handleChange,
                    setFieldValue,
                  })}
                </ModalInputsRowContainer>
              </ModalInputsContainer>
              <Actions>
                <Button
                  type="button"
                  disabled={isSubmitting}
                  onClick={deleteModal}
                >
                  Cancelar
                </Button>
                <Button
                  primary
                  type="submit"
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  Crear Comportamiento
                </Button>
              </Actions>
            </form>
          );
        }}
      </Formik>
    );
  }
}

BehaviourForm.propTypes = {
  id: PropType.string.isRequired,
  fetchAgentById: PropType.func.isRequired,
  fetchReasons: PropType.func.isRequired,
  fetchSchedules: PropType.func.isRequired,
  fetchDestinations: PropType.func.isRequired,
  createBehaviour: PropType.func.isRequired,
  fetchLocutions: PropType.func.isRequired,
  values: PropType.shape({}),
  touched: PropType.shape({}),
  errors: PropType.shape({}),
  agent: PropType.shape({}),
  schedules: PropType.arrayOf(PropType.shape({})).isRequired,
  reasons: PropType.arrayOf(PropType.shape({})).isRequired,
  locutions: PropType.arrayOf(PropType.shape({})).isRequired,
  destinations: PropType.arrayOf(PropType.shape({})).isRequired,
  deleteModal: PropType.func.isRequired,
};

BehaviourForm.defaultProps = {
  values: {},
  touched: {},
  errors: {},
  agent: {},
};

export default BehaviourForm;
