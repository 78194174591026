import styled from 'styled-components';

export const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-top: 2px;
`;

export const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

export const SwitchLabel = styled.span`
  position: absolute;
  z-index: 1;
  top: 8px;
  opacity: 0.8;
  font-weight: 500;
  transition: all 0.4s ease-in-out;
  ${props =>
    props.checked
      ? 'transform: translateX(6px)'
      : 'transform: translateX(26px)'};
`;

export const RoundedSliderBackground = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 34px;
  background-color: ${({ checked }) => (checked ? '#68C1C1' : '#ccc')};
  transition: background-color 0.4s ease-in-out;
`;

export const RoundedSlider = styled.span`
  position: absolute;
  cursor: pointer;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 34px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  &::before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 5.5px;
    top: 7px;
    background-color: #666666;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
  ${({ checked }) => {
    if (checked) {
      return {
        '::before': {
          '-webkit-transform': 'translateX(28px)',
          '-ms-transform': 'translateX(28px)',
          transform: 'translateX(28px)',
        },
      };
    }
    return {
      '::before': {
        '-webkit-transform': 'translateX(0px)',
        '-ms-transform': 'translateX(0px)',
        transform: 'translateX(0px)',
      },
    };
  }} &:focus {
    box-shadow: 0 0 1px #2196f3;
  }
`;
