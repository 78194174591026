// Modules
import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

// Commons
import { Layout } from './styles';

import Actions from '../UI/Modal/components/Actions';
import { Button } from '../../theme/styles';

import { Input, StyledFileInput, StyledFileInputLabel } from '../Input/styles';
import { Label } from '../Label/styles';

// Validation
const errorMessage = 'Este campo es obligatorio';
const validation = Yup.object().shape({
  name: Yup.string().required(errorMessage),
  audio: Yup.mixed().required(errorMessage),
});

const LocutionForm = ({
  createLocution,
  deleteModal,
  agent,
  agentId,
  agentName,
  agentPhone,
  accountRole,
  isSubmitting,
}) => (
  <Formik
    enableReinitialize
    initialValues={agent}
    validationSchema={validation}
    onSubmit={values => {
      const data = { ...values };
      data.agentId = agentId;
      data.agentName = agentName;
      data.accountRole = accountRole;
      data.agentPhone = agentPhone;
      createLocution(data);
    }}
  >
    {formProps => {
      const {
        values,
        touched,
        errors,
        handleChange,
        setFieldValue,
        handleBlur,
        handleSubmit,
      } = formProps;

      return (
        <form>
          <Layout>
            <div>
              <Label htmlFor="name" style={{ display: 'block' }}>
                Nombre
              </Label>
              <Input
                marginBottom
                id="name"
                placeholder="Nombre de la locución del agente"
                type="text"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.name && touched.name
                    ? 'text-input error'
                    : 'text-input'
                }
              />
              {errors.name && touched.name && (
                <div className="input-feedback">{errors.name}</div>
              )}

              <StyledFileInputLabel htmlFor="audio">
                {values.audio ? values.audio.name : 'Subir un audio'}
              </StyledFileInputLabel>
              <StyledFileInput
                id="audio"
                name="audio"
                placeholder="Enter your audio"
                type="file"
                accept="audio/*"
                onChange={event => {
                  setFieldValue('audio', event.currentTarget.files[0]);
                }}
                onBlur={handleBlur}
                className={
                  errors.audio && touched.audio
                    ? 'text-input error'
                    : 'text-input'
                }
              />
              {errors.audio && touched.audio && (
                <div className="input-feedback">{errors.audio}</div>
              )}
            </div>
          </Layout>
          <Actions>
            <Button type="button" disabled={isSubmitting} onClick={deleteModal}>
              Cancelar
            </Button>
            <Button
              primary
              type="submit"
              disabled={isSubmitting}
              onClick={handleSubmit}
            >
              Crear Locución
            </Button>
          </Actions>
        </form>
      );
    }}
  </Formik>
);

LocutionForm.propTypes = {
  values: PropTypes.shape({}),
  touched: PropTypes.shape({}),
  errors: PropTypes.shape({}),
  agent: PropTypes.shape({}),
  createLocution: PropTypes.func.isRequired,
  deleteModal: PropTypes.func.isRequired,
};

LocutionForm.defaultProps = {
  values: {},
  touched: {},
  errors: {},
  agent: {},
};

export default LocutionForm;
