import { get, post, put, del } from '../../lib/restClient';

export const fetchUsers = async () => get('/users');

export const fetchUserById = async id => get(`/users/${id}`);

export const fetchUserByUid = async uid => get(`/users/firebase/${uid}`);

export const createUser = async data => post('/users', data);

export const setPassword = async ({ userId, data }) =>
  put(`users/${userId}/set-password`, data);

export const deleteUser = async id => del(`/users/${id}`);

export const registerFirebaseUser = async (uid, userInfo) =>
  post(`/users/register-with-firebase/${uid}`, userInfo);
