import React from 'react';
import PropType from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

const validation = Yup.object().shape({
  group: Yup.string().required('Required'),
  identifier: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
});

const AudiosFormScreen = props => {
  const { createAudio, isSubmitting } = props;
  return (
    <Formik
      initialValues={{
        group: '',
        identifier: '',
        name: '',
      }}
      onSubmit={values => {
        createAudio(values);
      }}
      validationSchema={validation}
    >
      {formProps => {
        const {
          values,
          touched,
          errors,
          dirty,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
        } = formProps;
        return (
          <form>
            <label htmlFor="group" style={{ display: 'block' }}>
              Grupo
            </label>
            <input
              id="group"
              placeholder="Enter your group"
              type="text"
              value={values.group}
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                errors.group && touched.group
                  ? 'text-input error'
                  : 'text-input'
              }
            />
            {errors.group && touched.group && (
              <div className="input-feedback">{errors.group}</div>
            )}
            <label htmlFor="identifier" style={{ display: 'block' }}>
              Identificador
            </label>
            <input
              id="identifier"
              placeholder="Enter your identifier"
              type="text"
              value={values.identifier}
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                errors.identifier && touched.identifier
                  ? 'text-input error'
                  : 'text-input'
              }
            />
            {errors.identifier && touched.identifier && (
              <div className="input-feedback">{errors.identifier}</div>
            )}
            <label htmlFor="name" style={{ display: 'block' }}>
              Nombre
            </label>
            <input
              id="name"
              placeholder="Enter your name"
              type="text"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                errors.name && touched.name ? 'text-input error' : 'text-input'
              }
            />
            {errors.name && touched.name && (
              <div className="input-feedback">{errors.name}</div>
            )}

            <button
              type="button"
              className="outline"
              onClick={handleReset}
              disabled={!dirty || isSubmitting}
            >
              Reset
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              onClick={handleSubmit}
            >
              Submit
            </button>
          </form>
        );
      }}
    </Formik>
  );
};

AudiosFormScreen.propTypes = {
  createAudio: PropType.func.isRequired,
  values: PropType.shape({}),
  touched: PropType.shape({}),
  errors: PropType.shape({}),
};

AudiosFormScreen.defaultProps = {
  values: {},
  touched: {},
  errors: {},
};

export default AudiosFormScreen;
