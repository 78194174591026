import { handleActions } from 'redux-actions';

import {
  setAccounts,
  setAccountById,
  fetchAccountAgentsSuccess,
  fetchAccountClientsSuccess,
  addAccountAgentSuccess,
  addAccountAgentFailed,
  getAgentAccountInfo,
} from './actions';

const defaultState = {
  list: [],
};

export default handleActions(
  {
    [setAccounts]: (state, action) => ({
      ...state,
      list: action.payload.accounts,
    }),
    [getAgentAccountInfo]: (state, action) => ({
      ...state,
      info: {
        accountRole: action.payload.accountRole,
        agentName: action.payload.agentName,
        agentPhone: action.payload.agentPhone,
      },
    }),
    [setAccountById]: (state, action) => ({
      ...state,
      accountById: action.payload.account,
      errorMessage: null,
    }),
    [fetchAccountAgentsSuccess]: (state, { payload: { agents } }) => ({
      ...state,
      agents,
    }),
    [fetchAccountClientsSuccess]: (state, { payload }) => ({
      ...state,
      accountClients: payload,
    }),
    [addAccountAgentSuccess]: state => ({
      ...state,
      errorMessage: null,
    }),

    [addAccountAgentFailed]: (state, { payload }) => ({
      ...state,
      errorMessage: payload.message,
    }),
  },
  defaultState
);
