import { createActions } from 'redux-actions';

import {
  FETCH_ACCOUNTS,
  FETCH_ACCOUNT_BY_ID,
  SET_ACCOUNTS,
  SET_ACCOUNT_BY_ID,
  FETCH_ACCOUNTS_FAILED,
  CREATE_ACCOUNT,
  DELETE_ACCOUNT,
  FETCH_ACCOUNT_AGENTS,
  FETCH_ACCOUNT_AGENTS_SUCCESS,
  FETCH_ACCOUNT_AGENTS_FAILED,
  FETCH_ACCOUNT_CLIENTS,
  FETCH_ACCOUNT_CLIENTS_SUCCESS,
  FETCH_ACCOUNT_CLIENTS_FAILED,
  ADD_ACCOUNT_AGENT,
  ADD_ACCOUNT_AGENT_SUCCESS,
  ADD_ACCOUNT_AGENT_FAILED,
  UPDATE_ACCOUNT,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_FAILED,
  REMOVE_ACCOUNT_AGENT,
  REMOVE_ACCOUNT_AGENT_SUCCESS,
  REMOVE_ACCOUNT_AGENT_FAILED,
  GET_AGENT_ACCOUNT_INFO,
} from './actionTypes';

export const {
  fetchAccounts,
  setAccounts,
  setAccountById,
  setError,
  fetchAccountAgents,
  fetchAccountAgentsSuccess,
  fetchAccountAgentsFailed,
  fetchAccountClients,
  fetchAccountClientsSuccess,
  fetchAccountClientsFailed,
  addAccountAgent,
  addAccountAgentSuccess,
  addAccountAgentFailed,
  updateAccount,
  updateAccountSuccess,
  updateAccountFailed,
  removeAccountAgent,
  removeAccountAgentSuccess,
  removeAccountAgentFailed,
  getAgentAccountInfo,
} = createActions(
  FETCH_ACCOUNTS,
  SET_ACCOUNTS,
  SET_ACCOUNT_BY_ID,
  FETCH_ACCOUNTS_FAILED,
  FETCH_ACCOUNT_AGENTS,
  FETCH_ACCOUNT_AGENTS_SUCCESS,
  FETCH_ACCOUNT_AGENTS_FAILED,
  FETCH_ACCOUNT_CLIENTS,
  FETCH_ACCOUNT_CLIENTS_SUCCESS,
  FETCH_ACCOUNT_CLIENTS_FAILED,
  ADD_ACCOUNT_AGENT,
  ADD_ACCOUNT_AGENT_SUCCESS,
  ADD_ACCOUNT_AGENT_FAILED,
  UPDATE_ACCOUNT,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_FAILED,
  REMOVE_ACCOUNT_AGENT,
  REMOVE_ACCOUNT_AGENT_SUCCESS,
  REMOVE_ACCOUNT_AGENT_FAILED,
  GET_AGENT_ACCOUNT_INFO
);

export const { createAccount, deleteAccount, fetchAccountById } = createActions(
  {
    [CREATE_ACCOUNT]: account => ({ account }),
    [DELETE_ACCOUNT]: account => ({ account }),
    [FETCH_ACCOUNT_BY_ID]: id => ({ id }),
  }
);
