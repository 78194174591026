export const primaryColor = '#000022';
export const secondaryColor = '#010022';
export const backgroundColor = 'rgba(251, 251, 251)';
export const chartPrimaryColor = '#66CCCC';
export const chartSecondaryColor = '#3399CC';
export const chartColors = [
  '#4dd0e1',
  '#4ecede',
  '#4fcad9',
  '#50b6d4',
  '#5191c9',
  '#526bbd',
  '#5345b0',
  '#592c96',
  '#5f147c',
  '#6d166f',
  '#7a1961',
  '#871b53',
  '#942d48',
  '#a13f3d',
  '#ae5132',
  '#bc6327',
  '#c9741c',
  '#d68611',
  '#e49806',
  '#f0a800',
  '#f5b919',
  '#f8c13a',
  '#fbc95a',
  '#fed17b',
  '#ffda9b',
  '#ffe3bc',
  '#ffebdc',
  '#fff4fd',
  '#d7e6fe',
  '#a9d0fe',
];
