import styled from 'styled-components';

export const Container = styled.label`
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  user-select: none;
  color: #2d2f3b;
  font-size: 14px;
  line-height: 14px;
  padding-top: 3px;
  height: 20px;
  font-weight: normal;
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 2px solid #9598ac;
  border-radius: 2px;
  ${props =>
    props.checked &&
    `
    background-color: #9598AC;
  `}
  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 4px;
    top: 0px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  ${props =>
    props.checked &&
    `
    &:after {
      display: block;
    }
  `}
`;
