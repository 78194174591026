import React, { Component } from 'react';
import PropType from 'prop-types';
import AudioForm from '../../components/AudioForm';

class EditAudioScreen extends Component {
  componentDidMount() {
    const {
      fetchAudioById,
      match: {
        params: { audioId },
      },
    } = this.props;
    fetchAudioById(audioId);
  }

  render() {
    const { audioById } = this.props;
    return <AudioForm audio={audioById} />;
  }
}

EditAudioScreen.propTypes = {
  match: PropType.shape({}).isRequired,
  audioById: PropType.string.isRequired,
  fetchAudioById: PropType.func.isRequired,
};

export default EditAudioScreen;
