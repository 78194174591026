// Modules
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';

// Environment
import './css/index.css';
import './css/webfonts.css';

import App from './App';
import * as serviceWorker from './serviceWorker';
import createStore from './modules/createStore';

const queryClient = new QueryClient();

ReactDOM.render(
  <Provider store={createStore()}>
    <QueryClientProvider client={queryClient}>
      <Router>
        <App />
      </Router>
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
