// Dependencies
import { connect } from 'react-redux';

import { setPassword } from '../../../modules/users/actions';

// View
import View from './view';

// Connector
export default connect(
  state => ({
    isLoading: state.users.isSettingPassword,
    success: state.users.setPasswordSuccess,
    isSubmitting: state.ui.isSubmitting,
  }),
  {
    setPassword,
  }
)(View);
