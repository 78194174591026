export const FETCH_AGENTS = 'FETCH_AGENTS';
export const FETCH_AGENT_BY_ID = 'FETCH_AGENT_BY_ID';
export const FETCH_AGENT_BY_PHONE = 'FETCH_AGENT_BY_PHONE';
export const FETCH_AGENT_BY_PHONE_SUCCESS = 'FETCH_AGENT_BY_PHONE_SUCCESS';
export const FETCH_AGENT_BY_PHONE_FAILED = 'FETCH_AGENT_BY_PHONE_FAILED';
export const FETCH_AGENT_REASONS = 'FETCH_AGENT_REASONS';
export const FETCH_AGENT_REASONS_SUCCESS = 'FETCH_AGENT_REASONS_SUCCESS';
export const FETCH_AGENT_REASONS_FAILED = 'FETCH_AGENT_REASONS_FAILED';
export const SET_AGENTS = 'SET_AGENTS';
export const SET_AGENT_BY_ID = 'SET_AGENT_BY_ID';
export const FETCH_AGENTS_FAILED = 'FETCH_AGENTS_FAILED';
export const CREATE_AGENT = 'CREATE_AGENT';
export const CREATE_AGENT_SUCCESS = 'CREATE_AGENT_SUCCESS';
export const CREATE_AGENT_FAILED = 'CREATE_AGENT_FAILED';
export const DELETE_AGENT = 'DELETE_AGENT';
export const FETCH_AGENT_AUDIOS = 'FETCH_AGENT_AUDIOS';
export const FETCH_AGENT_AUDIOS_SUCCESS = 'FETCH_AGENT_AUDIOS_SUCCESS';
export const FETCH_AGENT_AUDIOS_FAILED = 'FETCH_AGENT_AUDIOS_FAILED';
export const GET_AGENT_ROLE = 'GET_AGENT_ROLE';
