// Modules
import React from 'react';
import PropType from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Row, Col } from 'antd';
import Actions from '../UI/Modal/components/Actions';

import { FlexColumn, Button } from '../../theme/styles';
import { Input } from '../Input/styles';
import { Label } from '../Label/styles';
import { ModalInputsContainer } from '../ModalInputsContainer/styles';
import { ModalInputsRowContainer } from '../ModalInputsRowContainer/styles';
import RenderTimeConditions from '../ScheduleForm/component';

const timeTableMock = [
  {
    startDay: '',
    endDay: '',
    startHour: '',
    endHour: '',
  },
];
const changeTimeTable = (index, values, setFieldValue) => (value, key) => {
  const timeConditions = values.timeConditions ? values.timeConditions : [];
  const timeTable = timeConditions.map((day, timeTableIndex) => {
    if (timeTableIndex !== index) {
      return day;
    }
    if (!value) {
      const newDay = {
        ...day,
        [key]: value,
      };
      return newDay;
    }
    return {
      ...day,
      [key]: value,
    };
  });
  setFieldValue('timeConditions', [...timeTable]);
};
const validation = Yup.object().shape({
  name: Yup.string().required(),
  startDay: Yup.string().required(),
  endDay: Yup.string().required(),
  startHour: Yup.string().required(),
  endHour: Yup.string().required(),
});
class ScheduleForm extends React.Component {
  componentDidMount() {
    const { id, fetchScheduleById } = this.props;
    fetchScheduleById({ id });
  }

  render() {
    const {
      id,
      schedule,
      updateSchedule,
      deleteModal,
      agentPhone,
      isSubmitting,
    } = this.props;

    const createInitialValues = () => {
      return {
        name: schedule ? schedule.name : '',
        timeConditions: schedule
          ? schedule.timeConditions.map(time => {
              return {
                endDay: time.endDay,
                startDay: time.startDay,
                startHour: time.startHour,
                endHour: time.endHour,
              };
            })
          : '',
      };
    };
    return (
      <Formik
        initialValues={schedule ? createInitialValues(schedule) : ''}
        enableReinitialize
        onSubmit={values => {
          const data = {
            name: values.name,
            timeConditions: values.timeConditions.map(time => {
              return {
                endDay: time.endDay,
                startDay: time.startDay,
                startHour: time.startHour,
                endHour: time.endHour,
              };
            }),
            agentPhone,
          };
          updateSchedule({ id, data });
        }}
        // validationSchema={validation}
      >
        {formProps => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          } = formProps;
          return (
            <form>
              <ModalInputsContainer padding={20}>
                <ModalInputsRowContainer spaceBetween>
                  <FlexColumn>
                    <Label htmlFor="name" style={{ display: 'block' }}>
                      Nombre
                    </Label>
                    <Input
                      id="name"
                      placeholder="Enter your name"
                      type="text"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.name && touched.name
                          ? 'text-input error'
                          : 'text-input'
                      }
                    />
                    {errors.name && touched.name && (
                      <div className="input-feedback">{errors.name}</div>
                    )}
                  </FlexColumn>
                </ModalInputsRowContainer>
                <ModalInputsRowContainer spaceBetween>
                  {values &&
                    values.timeConditions &&
                    values.timeConditions.map((time, index) => {
                      return (
                        <div style={{ width: '100%' }}>
                          <RenderTimeConditions
                            index={index}
                            onChange={changeTimeTable(
                              index,
                              values,
                              setFieldValue
                            )}
                            value={time}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      );
                    })}
                </ModalInputsRowContainer>
              </ModalInputsContainer>
              <Actions>
                <Button
                  type="button"
                  disabled={isSubmitting}
                  onClick={deleteModal}
                >
                  Cancelar
                </Button>
                <Button
                  primary
                  type="submit"
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  Editar horario
                </Button>
              </Actions>
            </form>
          );
        }}
      </Formik>
    );
  }
}

ScheduleForm.propTypes = {
  id: PropType.string.isRequired,
  fetchScheduleById: PropType.func.isRequired,
  updateSchedule: PropType.func.isRequired,
  values: PropType.shape({}),
  touched: PropType.shape({}),
  errors: PropType.shape({}),
  schedule: PropType.shape({}).isRequired,
  deleteModal: PropType.func.isRequired,
};

ScheduleForm.defaultProps = {
  values: {},
  touched: {},
  errors: {},
};

export default ScheduleForm;
