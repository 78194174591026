export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USER_BY_ID = 'FETCH_USER_BY_ID';
export const SET_USERS = 'SET_USERS';
export const SET_ERROR = 'SET_ERROR';
export const SET_USER_BY_ID = 'SET_USER_BY_ID';
export const FETCH_USERS_FAILED = 'FETCH_USERS_FAILED';
export const CREATE_USER = 'CREATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';
export const SET_USER = 'SET_USER';
export const FETCH_USER_BY_UID = 'FETCH_USER_BY_UID';
export const FETCH_USER_BY_UID_SUCCESS = 'FETCH_USER_BY_UID_SUCCESS';
export const FETCH_USER_BY_UID_FAILED = 'FETCH_USER_BY_UID_FAILED';
export const CLEAN_ERRORS = 'CLEAN_ERRORS';
export const SET_PASSWORD = 'SET_PASSWORD';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_FAILED = 'SET_PASSWORD_FAILED';
