import styled from 'styled-components';

export const Layout = styled.section`
  width: 100%;
  height: 100%;
`;

export const Body = styled.div`
  display: flex;
  width: 100%;
  padding: 0 38px;
  ${props =>
    props.noPadding &&
    `
    padding: 0;
    min-height: calc(100vh - 5em);
    `}
`;

export const Container = styled.div`
  flex: 1;
  display: flex;
  padding-top: 5em;
  ${props =>
    props.paddingBottom &&
    `position: absolute;
  top: 5em;
  padding: 0;
  width: 100%;
  `}
`;
