import { createActions } from 'redux-actions';

import {
  FETCH_LOCUTIONS,
  FETCH_LOCUTION_BY_ID,
  SET_LOCUTIONS,
  SET_LOCUTION_BY_ID,
  FETCH_LOCUTIONS_FAILED,
  CREATE_LOCUTION,
  UPDATE_LOCUTION,
  DELETE_LOCUTION,
} from './actionTypes';

export const {
  fetchLocutions,
  setLocutions,
  setLocutionById,
  setError,
  updateLocution,
} = createActions(
  FETCH_LOCUTIONS,
  SET_LOCUTIONS,
  SET_LOCUTION_BY_ID,
  FETCH_LOCUTIONS_FAILED,
  UPDATE_LOCUTION
);

export const {
  createLocution,
  deleteLocution,
  fetchLocutionById,
} = createActions({
  [CREATE_LOCUTION]: locution => ({ locution }),
  [DELETE_LOCUTION]: locution => ({ locution }),
  [FETCH_LOCUTION_BY_ID]: id => ({ id }),
});
