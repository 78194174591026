// Modules
import { connect } from 'react-redux';

// Screen Layout
import View from './view';

import {
  fetchSchedules,
  deleteSchedule,
} from '../../modules/schedules/actions';

// Modal
import { createModal } from '../../modules/ui/actions';

// Redux Connector
export default connect(
  state => ({
    agent: state.agents.agentById,
    schedules: state.schedules.list,
  }),
  {
    fetchSchedules,
    deleteSchedule,
    createModal,
  }
)(View);
