import styled from 'styled-components';

import ErrorText from '../ErrorText';

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
`;

export const Error = styled(ErrorText)`
  ${props =>
    !props.withoutPadding &&
    `
    padding: 8px 14px;
  `}
`;
