// Modules
import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

// Commons
import { Layout } from './styles';

import Actions from '../UI/Modal/components/Actions';
import { Button } from '../../theme/styles';

import { Input } from '../Input/styles';
import { Label } from '../Label/styles';

const errorMessage = 'Este campo es obligatorio';
// Validation
const validation = Yup.object().shape({
  name: Yup.string().required(errorMessage),
  destinationCode: Yup.string().required(errorMessage),
});

const DestinationForm = ({
  createDestination,
  deleteModal,
  agentPhone,
  isSubmitting,
}) => (
  <Formik
    enableReinitialize
    initialValues={{
      name: '',
      destinationCode: '',
      agent: agentPhone,
    }}
    validationSchema={validation}
    onSubmit={values => {
      createDestination({ data: values });
    }}
  >
    {formProps => {
      const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
      } = formProps;

      return (
        <form>
          <Layout>
            <div>
              <Label htmlFor="name" style={{ display: 'block' }}>
                Nombre
              </Label>
              <Input
                marginBottom
                id="name"
                placeholder="Introduce el nombre del destino"
                type="text"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.name && touched.name
                    ? 'text-input error'
                    : 'text-input'
                }
              />
              {errors.name && touched.name && (
                <div className="input-feedback">{errors.name}</div>
              )}

              <Label htmlFor="destinationCode" style={{ display: 'block' }}>
                Código de Destino
              </Label>
              <Input
                id="destinationCode"
                placeholder="Introduce el código de destino"
                type="text"
                value={values.destinationCode}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.destinationCode && touched.destinationCode
                    ? 'text-input error'
                    : 'text-input'
                }
              />
              {errors.destinationCode && touched.destinationCode && (
                <div className="input-feedback">{errors.destinationCode}</div>
              )}
            </div>
          </Layout>
          <Actions>
            <Button type="button" disabled={isSubmitting} onClick={deleteModal}>
              Cancelar
            </Button>
            <Button
              primary
              type="submit"
              disabled={isSubmitting}
              onClick={handleSubmit}
            >
              Crear Destino
            </Button>
          </Actions>
        </form>
      );
    }}
  </Formik>
);

DestinationForm.propTypes = {
  values: PropTypes.shape({}),
  touched: PropTypes.shape({}),
  errors: PropTypes.shape({}),
  createDestination: PropTypes.func.isRequired,
  deleteModal: PropTypes.func.isRequired,
};

DestinationForm.defaultProps = {
  values: {},
  touched: {},
  errors: {},
};

export default DestinationForm;
