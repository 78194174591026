import { connect } from 'react-redux';
import View from './view';

import { createReason } from '../../modules/reasons/actions';
import { deleteModal } from '../../modules/ui/actions';

export default connect(state => ({ isSubmitting: state.ui.isSubmitting }), {
  createReason,
  deleteModal,
})(View);
