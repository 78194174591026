import { takeLatest, call, put } from 'redux-saga/effects';

import { fetchPathsSuccess, fetchPathsFailed } from './actions';

import { FETCH_PATHS } from './actionTypes';

import * as api from './api';

function* fetchPaths() {
  try {
    const response = yield call(api.fetchPaths);
    yield put(fetchPathsSuccess(response.data));
  } catch (e) {
    console.error({ e });
    yield put(fetchPathsFailed());
  }
}

export function* watchFetchPaths() {
  yield takeLatest(FETCH_PATHS, fetchPaths);
}
