// Modules
import { connect } from 'react-redux';

// Layout Screen
import View from './view';

// Actions
import { createAgent, getAgentRole } from '../../modules/agents/actions';
import { deleteModal } from '../../modules/ui/actions';

// Redux Connector
export default connect(
  state => ({
    error: state.agents.error,
    me: state.auth.me,
    isSubmitting: state.ui.isSubmitting,
  }),
  {
    createAgent,
    deleteModal,
    getAgentRole,
  }
)(View);
