import { connect } from 'react-redux';

import View from './view';

import { fetchAccountById } from '../../modules/accounts/actions';

export default connect(
  state => ({
    accountById: state.accounts.accountById,
  }),
  {
    fetchAccountById,
  }
)(View);
