import React from 'react';
import PropType from 'prop-types';

import {
  TableNameContainer,
  Title,
  ColRight,
  LinkContainer,
  ArrowIcon,
  TextInfo,
} from './styles';

import { Button, ReturnLinkText } from '../../theme/styles';

const TableName = ({
  title,
  handleButtonAction,
  buttonText,
  backLinkTo,
  textInfo,
  backLinkText,
  children,
  primary,
}) => (
  <TableNameContainer>
    {!!title && <Title>{title}</Title>}
    {backLinkTo && (
      <LinkContainer to={backLinkTo}>
        <ArrowIcon />
        <ReturnLinkText noMargin>{backLinkText}</ReturnLinkText>
      </LinkContainer>
    )}
    {handleButtonAction && (
      <ColRight>
        {textInfo && <TextInfo>{textInfo}</TextInfo>}
        {children}
        <Button
          width="160px"
          onClick={handleButtonAction}
          primary={primary}
          marginL="30px"
          noPadding
        >
          {buttonText}
        </Button>
      </ColRight>
    )}
  </TableNameContainer>
);

TableName.propTypes = {
  title: PropType.string.isRequired,
  children: PropType.node,
  handleButtonAction: PropType.func,
  buttonText: PropType.string,
  backLinkTo: PropType.string,
  textInfo: PropType.string,
  backLinkText: PropType.string,
  primary: PropType.bool,
};

TableName.defaultProps = {
  handleButtonAction: null,
  children: null,
  buttonText: '',
  backLinkTo: '',
  textInfo: null,
  backLinkText: '',
  primary: false,
};

export default TableName;
