// Modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

import moment from 'moment';

import {
  // Icon,
  Body,
  Field,
  Label,
  Value,
  Option,
  Layout,
  Column,
  Navigator,
  Container,
  LinkElement,
} from './styles';

import Headline from '../../components/Headline';

// Screens
import ReasonsScreen from '../ReasonsScreen';
import AudiosScreen from '../AudiosScreen';

class DetailAgentScreen extends Component {
  componentDidMount() {
    const {
      fetchAgentById,
      match: {
        params: { id },
      },
    } = this.props;
    fetchAgentById(id);
  }

  render() {
    const {
      agent: { id, name, identifier, createdAt, author, authorName },
      location: { pathname },
    } = this.props;
    const focused = pathname.split('/')[3];

    // ================== //
    return (
      <Layout>
        <Navigator>
          <Headline title={name} />
          <Container>
            <Column separator padding="20px 20px 0  20px">
              <Field>
                <Label>ID</Label>
                <Value>{identifier}</Value>
              </Field>
              <Field>
                <Label>Creado el</Label>
                <Value>{moment(createdAt).format('DD/MM/YYYY')}</Value>
              </Field>
              <Field>
                <Label>Creado por</Label>
                <Value color="#66CCCC">{authorName}</Value>
              </Field>
            </Column>
            <Column>
              <Option selected={focused === 'reasons'}>
                <LinkElement to={`/agents/${id}/reasons`}>Motivos</LinkElement>
              </Option>
              <Option selected={focused === 'audios'}>
                <LinkElement to={`/agents/${id}/audios`}>Audios</LinkElement>
              </Option>
            </Column>
          </Container>
        </Navigator>
        <Body>
          <Switch>
            <Route path="/agents/:id/reasons" component={ReasonsScreen} />
            <Route path="/agents/:id/audios" component={AudiosScreen} />
            {id && (
              <Route
                path={`/agents/${id}`}
                render={() => <Redirect to={`/agents/${id}/reasons`} />}
              />
            )}
          </Switch>
        </Body>
      </Layout>
    );
  }
}

DetailAgentScreen.propTypes = {
  agent: PropTypes.shape({}),
  match: PropTypes.shape({}).isRequired,
  fetchAgentById: PropTypes.func.isRequired,
  location: PropTypes.shape({}).isRequired,
};

DetailAgentScreen.defaultProps = {
  agent: {},
};

export default DetailAgentScreen;
