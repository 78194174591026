import { createActions } from 'redux-actions';

import {
  CHECK_LOGIN,
  CHECK_LOGIN_SUCCESS,
  CHECK_LOGIN_FAILED,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT,
  SET_TOKEN,
  FETCH_ME,
  SET_ME,
} from './actionTypes';

export const {
  checkLogin,
  checkLoginSuccess,
  checkLoginFailed,
  login,
  loginSuccess,
  loginFailed,
  logout,
  setToken,
  fetchMe,
  setMe,
} = createActions(
  CHECK_LOGIN,
  CHECK_LOGIN_SUCCESS,
  CHECK_LOGIN_FAILED,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT,
  SET_TOKEN,
  FETCH_ME,
  SET_ME
);
