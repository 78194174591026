import styled from 'styled-components';

export const Label = styled.label`
  color: #ababb8;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  margin-bottom: 8px;
  text-transform: uppercase;
  ${props => ({
    'margin-right': props.marginRight ? '25px' : '0',
    'margin-top': props.marginTop ? '3px' : '0',
  })}
`;
