// Dependencies
import { takeLatest, put, call } from 'redux-saga/effects';

// ActionTypes
import { REQUEST_PASSWORD, VALIDATE_RESET_PASSWORD } from './actionTypes';

// API
import * as api from './api';

// Actions
import {
  requestPasswordSuccess,
  requestPasswordFailed,
  validateResetPasswordSuccess,
  validateResetPasswordFailed,
} from './actions';
import { setIsSubmitting } from '../ui/actions';

function* requestPassword({ payload: { email } }) {
  try {
    yield put(setIsSubmitting(true));
    const response = yield call(api.requestPassword, { email });
    if (response && response.status === 200) {
      yield put(requestPasswordSuccess());
      yield put(setIsSubmitting(false));
    }
  } catch (error) {
    yield put(setIsSubmitting(false));
    console.error(error);
    yield put(requestPasswordFailed());
  }
}

export function* watchRequestPassword() {
  yield takeLatest(REQUEST_PASSWORD, requestPassword);
}

function* validateResetPassword({ payload: { token, password } }) {
  try {
    yield put(setIsSubmitting(true));
    const response = yield call(api.validateResetPassword, { token, password });
    if (response && response.status === 200) {
      yield put(validateResetPasswordSuccess());
      yield put(setIsSubmitting(false));
    }
  } catch (error) {
    yield put(setIsSubmitting(false));
    console.error(error);
    yield put(validateResetPasswordFailed(error));
  }
}

export function* watchValidateResetPassword() {
  yield takeLatest(VALIDATE_RESET_PASSWORD, validateResetPassword);
}
