// Modules
import { connect } from 'react-redux';

// Screen Layout
import View from './view';

import { fetchAccountAgents } from '../../modules/accounts/actions';

// Agents
import { deleteAgent } from '../../modules/agents/actions';

// Modal
import { createModal } from '../../modules/ui/actions';

// Redux Connector
export default connect(
  state => ({
    agents: state.accounts.agents,
    me: state.auth.me,
  }),
  {
    // Agents
    fetchAccountAgents,
    deleteAgent,

    // Modal
    createModal,
  }
)(View);
