// Modules
import { connect } from 'react-redux';

// Layout Screen
import View from './view';

// Actions
import {
  fetchAccounts,
  fetchAccountClients,
} from '../../modules/accounts/actions';
import { createUser, cleanErrors } from '../../modules/users/actions';
import { deleteModal } from '../../modules/ui/actions';

// Redux Connector
export default connect(
  state => ({
    accounts: state.accounts.list,
    accountClients: state.accounts.accountClients,
    me: state.auth.me,
    error: state.users.error,
    isSubmitting: state.ui.isSubmitting,
  }),
  {
    fetchAccounts,
    fetchAccountClients,
    createUser,
    deleteModal,
    cleanErrors,
  }
)(View);
