// Modules
import { all } from 'redux-saga/effects';

// User Interface
import {
  watchCreateModal,
  watchDeleteModal,
  watcheEditModal,
} from './ui/sagas';

// Authentication
import {
  watchCheckLogin,
  watchLogin,
  watchLogout,
  watchFetchMe,
} from './auth/sagas';

// Reset Password
import {
  watchRequestPassword,
  watchValidateResetPassword,
} from './reset-password/sagas';

// Dashoard
import { watchFetchChartData } from './dashboard/sagas';

// Agents
import {
  watchFetchAgents,
  watchCreateAgent,
  watchDeleteAgent,
  watchFetchAgentById,
  watchFetchAgentByPhone,
  watchFetchAgentAudios,
  watchFetchAgentReasons,
} from './agents/sagas';

// Accounts
import {
  watchFetchAccounts,
  watchFetchAccountAgents,
  watchCreateAccount,
  watchDeleteAccount,
  watchFetchAccountById,
  watchAddAgent,
  watchUpdateAccount,
  watchFetchAccountClients,
  watchRemoveAccountAgent,
} from './accounts/sagas';

import {
  watchCreateSubaccount,
  watchFetchSubaccountsById,
} from './subaccounts/sagas';

// Audios
import {
  watchFetchAudios,
  watchCreateAudio,
  watchDeleteAudio,
  watchFetchAudioById,
} from './audios/sagas';

// Reasons
import {
  watchFetchReasons,
  watchCreateReason,
  watchUpdateReason,
  watchDeleteReason,
  watchFetchReasonById,
} from './reasons/sagas';

// Users
import {
  watchFetchUsers,
  watchCreateUser,
  watchDeleteUser,
  watchFetchUserById,
  watchRegisterUser,
  watchFetchUserByUid,
  watchSetPassword,
} from './users/sagas';

// Locutions
import {
  watchFetchLocutions,
  watchCreateLocution,
  watchDeleteLocution,
  watchFetchLocutionById,
  watchUpdateLocution,
} from './locutions/sagas';

// Behaviours
import {
  watchFetchBehaviours,
  watchCreateBehaviour,
  watchDeleteBehaviour,
  watchFetchBehaviourById,
} from './behaviours/sagas';

// Populations
import { watchFetchPopulations } from './populations/sagas';

// Stops
import { watchFetchStops } from './stops/sagas';

// Paths
import { watchFetchPaths } from './paths/sagas';

// Integrations
import {
  watchCreateIntegration,
  watchFetchIntegrationById,
  watchEditIntegration,
} from './integrations/sagas';

// Schedules
import {
  watchFetchSchedules,
  watchCreateSchedule,
  watchFetchScheduleById,
  watchUpdateSchedule,
  watchDeleteSchedule,
} from './schedules/sagas';

import {
  watchFetchDestinations,
  watchFetchDestinationById,
  watchCreateDestination,
  watchUpdateDestination,
  watchDeleteDestinationById,
} from './destinations/sagas';

// Watchers
export default function* rootSaga() {
  yield all([
    // User-Interface
    watchCreateModal(),
    watchDeleteModal(),
    watcheEditModal(),

    // Auth
    watchCheckLogin(),
    watchLogin(),
    watchLogout(),
    watchFetchMe(),

    // Reset Password
    watchRequestPassword(),
    watchValidateResetPassword(),

    // Dashboard
    watchFetchChartData(),

    // Agents
    watchFetchAgents(),
    watchFetchAgentById(),
    watchFetchAgentAudios(),
    watchFetchAgentReasons(),
    watchCreateAgent(),
    watchDeleteAgent(),

    // Accounts
    watchFetchAccounts(),
    watchFetchAccountById(),
    watchFetchAccountAgents(),
    watchCreateAccount(),
    watchAddAgent(),
    watchUpdateAccount(),
    watchDeleteAccount(),
    watchFetchAccountClients(),
    watchRemoveAccountAgent(),

    // Subaccounts
    watchFetchSubaccountsById(),
    watchCreateSubaccount(),

    // Users
    watchRegisterUser(),
    watchFetchUsers(),
    watchFetchUserById(),
    watchFetchAgentByPhone(),
    watchFetchUserByUid(),
    watchCreateUser(),
    watchDeleteUser(),
    watchSetPassword(),

    // Reasons
    watchFetchReasons(),
    watchFetchReasonById(),
    watchCreateReason(),
    watchUpdateReason(),
    watchDeleteReason(),

    // Audios
    watchFetchAudios(),
    watchFetchAudioById(),
    watchCreateAudio(),
    watchDeleteAudio(),

    // Locutions
    watchFetchLocutions(),
    watchFetchLocutionById(),
    watchCreateLocution(),
    watchUpdateLocution(),
    watchDeleteLocution(),

    // Behaviours
    watchFetchBehaviours(),
    watchFetchBehaviourById(),
    watchCreateBehaviour(),
    watchDeleteBehaviour(),

    // Populations
    watchFetchPopulations(),

    // Stops
    watchFetchStops(),

    // Paths
    watchFetchPaths(),

    // Integrations
    watchCreateIntegration(),
    watchFetchIntegrationById(),
    watchEditIntegration(),

    // Schedules
    watchFetchSchedules(),
    watchFetchScheduleById(),
    watchCreateSchedule(),
    watchUpdateSchedule(),
    watchDeleteSchedule(),

    // Destinations
    watchFetchDestinations(),
    watchFetchDestinationById(),
    watchCreateDestination(),
    watchUpdateDestination(),
    watchDeleteDestinationById(),
  ]);
}
