// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Commons
import Headline from '../../components/Headline';
import Table from '../../components/TableV2';
import DestinationForm from '../../components/DestinationForm';
import EditDestinationForm from '../../components/EditDestinationForm';
import DeleteModal from '../../components/DeleteModal';
import { SectionContainer, Button } from '../../theme/styles';

// Assets
import ConfigImg from './img/tune.svg';
import TrashImg from './img/trash-can-outline.svg';

class DestinationsScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table: {
        page: 1,
        order: 'desc',
        orderBy: 'name',
      },
    };
  }

  componentDidMount() {
    const {
      fetchDestinations,
      match: {
        params: { phone },
      },
    } = this.props;
    fetchDestinations({ phone });
  }

  createDestination = () => {
    const {
      createModal,
      match: {
        params: { phone },
      },
    } = this.props;
    createModal({
      title: 'Destino Nuevo',
      width: 400,
      body: <DestinationForm agentPhone={phone} />,
    });
  };

  editDestination = destination => {
    const { createModal } = this.props;
    createModal({
      title: 'Editar Destino',
      width: 400,
      body: <EditDestinationForm id={destination.id} />,
    });
  };

  render() {
    const {
      table: { page, order, orderBy },
    } = this.state;
    const {
      destinations,
      createModal,
      deleteDestinationById,
      match: {
        params: { phone },
      },
    } = this.props;
    // ===========================
    return (
      <SectionContainer>
        <Headline
          title="Destinos"
          actions={
            <Button primary onClick={this.createDestination}>
              Nuevo Destino
            </Button>
          }
        />
        <Table
          page={page}
          order={order}
          orderBy={orderBy}
          rowsPerPage={12}
          fields={[
            {
              key: 'name',
              label: 'Nombre',
              format: 'default',
              color: '#ABABB8',
            },
            {
              key: 'destinationCode',
              label: 'Número de Destino',
              format: 'number',
            },
            {
              key: 'actions',
              label: 'Acciones',
              format: 'embed',
            },
          ]}
          data={destinations.map((destination, index) => ({
            key: index,
            destinationCode: destination.destinationCode,
            name: destination.name,
            author: 'Admin',
            actions: [
              {
                icon: (
                  <img
                    alt="config-icon"
                    src={ConfigImg}
                    width="18px"
                    height="18px"
                  />
                ),
                color: 'green',
                text: 'Editar',
                handler: () => this.editDestination(destination),
              },
              {
                icon: (
                  <img
                    alt="trash-icon"
                    src={TrashImg}
                    width="13px"
                    height="15px"
                  />
                ),
                color: 'red',
                text: 'Eliminar',
                handler: () =>
                  createModal({
                    title: 'Eliminar Destino',
                    width: 480,
                    body: (
                      <DeleteModal
                        item={destination.id}
                        phone={phone}
                        title="¿Estas seguro que deseas eliminar este Destino?"
                        removeText="Eliminar Destino"
                        deleteAction={deleteDestinationById}
                      />
                    ),
                  }),
              },
            ],
          }))}
          onChangeField={(key, value) => {
            this.setState(prevState => ({
              ...prevState,
              table: {
                ...prevState.table,
                [key]: value,
              },
            }));
          }}
        />
      </SectionContainer>
    );
  }
}

DestinationsScreen.propTypes = {
  destinations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  match: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  fetchDestinations: PropTypes.func.isRequired,
  createModal: PropTypes.func.isRequired,
};

export default DestinationsScreen;
