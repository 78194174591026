import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 0 20px;
  margin-bottom: 40px;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 4px 10px 0 rgb(0 0 0 / 5%);
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 2vh;
  margin-bottom: 2vh;
`;

export const FormInputContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  flex: 0.8;
`;

export const ButtonContainer = styled.div`
  flex-direction: row;
  display: flex;
  margin-top: 10px;
  margin-right: 20px;
  justify-content: flex-end;
  border-radius: 0 0 4px 4px;
`;
