import { createActions } from 'redux-actions';

import {
  FETCH_SUBACCOUNTS_BY_ID,
  FETCH_SUBACCOUNTS_BY_ID_SUCCESS,
  FETCH_SUBACCOUNTS_BY_ID_FAILED,
  CREATE_SUBACCOUNT,
  CREATE_SUBACCOUNT_SUCCESS,
  CREATE_SUBACCOUNT_FAILED,
} from './actionTypes';

export const {
  fetchSubaccountsById,
  fetchSubaccountsByIdSuccess,
  fetchSubaccountsByIdFailed,
  createSubaccount,
  createSubaccountSuccess,
  createSubaccountFailed,
} = createActions(
  FETCH_SUBACCOUNTS_BY_ID,
  FETCH_SUBACCOUNTS_BY_ID_SUCCESS,
  FETCH_SUBACCOUNTS_BY_ID_FAILED,
  CREATE_SUBACCOUNT,
  CREATE_SUBACCOUNT_SUCCESS,
  CREATE_SUBACCOUNT_FAILED
);
