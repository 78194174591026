// Modules
import { connect } from 'react-redux';

// Layout Screen
import View from './view';

// Actions
import { createSchedule } from '../../../modules/schedules/actions';
import { deleteModal } from '../../../modules/ui/actions';

export default connect(() => ({}), {
  createSchedule,
  deleteModal,
})(View);
