// Modules
import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

// Commons
import { Layout } from './styles';

import Actions from '../UI/Modal/components/Actions';
import { Button } from '../../theme/styles';

import { Input } from '../Input/styles';
import { Label } from '../Label/styles';

// Validation
const validation = Yup.object().shape({
  name: Yup.string().required('Required'),
  file: Yup.string().required('Required'),
});

class EditLocutionForm extends React.Component {
  componentDidMount() {
    const { fetchLocutionById, id } = this.props;
    fetchLocutionById(id);
  }

  render() {
    const {
      updateLocution,
      deleteModal,
      id,
      locution,
      isSubmitting,
    } = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={{
          name: locution ? locution.name : '',
          file: locution ? locution.file : '',
        }}
        validationSchema={validation}
        onSubmit={values => {
          updateLocution({ id, locution: values });
        }}
      >
        {formProps => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
          } = formProps;

          return (
            <form>
              <Layout>
                <div>
                  <Label htmlFor="name" style={{ display: 'block' }}>
                    Nombre
                  </Label>
                  <Input
                    marginBottom
                    id="name"
                    placeholder="Enter your agent's locution name"
                    type="text"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.name && touched.name
                        ? 'text-input error'
                        : 'text-input'
                    }
                  />
                  {errors.name && touched.name && (
                    <div className="input-feedback">{errors.name}</div>
                  )}

                  <Label htmlFor="file" style={{ display: 'block' }}>
                    Archivo
                  </Label>
                  <Input
                    id="file"
                    placeholder="Enter your file"
                    type="text"
                    value={values.file}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.file && touched.file
                        ? 'text-input error'
                        : 'text-input'
                    }
                  />
                  {errors.file && touched.file && (
                    <div className="input-feedback">{errors.file}</div>
                  )}
                </div>
              </Layout>
              <Actions>
                <Button
                  type="button"
                  disabled={isSubmitting}
                  onClick={deleteModal}
                >
                  Cancelar
                </Button>
                <Button
                  primary
                  type="submit"
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  Editar Locución
                </Button>
              </Actions>
            </form>
          );
        }}
      </Formik>
    );
  }
}

EditLocutionForm.propTypes = {
  values: PropTypes.shape({}),
  id: PropTypes.string.isRequired,
  locution: PropTypes.shape({}).isRequired,
  touched: PropTypes.shape({}),
  errors: PropTypes.shape({}),
  updateLocution: PropTypes.func.isRequired,
  deleteModal: PropTypes.func.isRequired,
  fetchLocutionById: PropTypes.func.isRequired,
};

EditLocutionForm.defaultProps = {
  values: {},
  touched: {},
  errors: {},
};

export default EditLocutionForm;
