import { connect } from 'react-redux';
import View from './view';

import { createAudio } from '../../modules/audios/actions';
import { deleteModal } from '../../modules/ui/actions';

export default connect(
  state => ({
    isSubmitting: state.ui.isSubmitting,
  }),
  {
    createAudio,
    deleteModal,
  }
)(View);
