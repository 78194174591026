// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Commons
import Headline from '../../components/Headline';
import Table from '../../components/TableV2';
import ScheduleForm from '../../components/ScheduleForm';
import EditScheduleForm from '../../components/EditScheduleForm';
import DeleteModal from '../../components/DeleteModal';
import { SectionContainer, Button } from '../../theme/styles';

// Assets
import ConfigImg from './img/tune.svg';
import TrashImg from './img/trash-can-outline.svg';

class SchedulesScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table: {
        page: 1,
        order: 'desc',
        orderBy: 'name',
      },
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { phone },
      },
      fetchSchedules,
    } = this.props;
    fetchSchedules({ phone });
  }

  createSchedule = () => {
    const {
      createModal,
      match: {
        params: { phone },
      },
    } = this.props;
    createModal({
      title: 'Nuevo Horario',
      width: 600,
      body: <ScheduleForm agentPhone={phone} />,
    });
  };

  editSchedule = id => {
    const {
      createModal,
      match: {
        params: { phone },
      },
    } = this.props;
    createModal({
      title: 'Editar Horario',
      width: 600,
      body: <EditScheduleForm id={id} agentPhone={phone} />,
    });
  };

  render() {
    const {
      table: { page, order, orderBy },
    } = this.state;
    const {
      schedules,
      createModal,
      deleteSchedule,
      match: {
        params: { phone },
      },
    } = this.props;
    // ===========================
    return (
      <SectionContainer>
        <Headline
          title="Flujo Llamadas - Horarios"
          actions={
            <Button primary onClick={this.createSchedule}>
              Nuevo Horario
            </Button>
          }
        />
        <Table
          page={page}
          order={order}
          orderBy={orderBy}
          rowsPerPage={12}
          fields={[
            {
              key: 'name',
              label: 'Nombre',
              format: 'default',
            },
            {
              key: 'timeCondition',
              label: 'Condición Horaria',
              format: 'list',
              color: '#ABABB8',
            },
            {
              key: 'actions',
              label: 'Acciones',
              format: 'embed',
            },
          ]}
          data={schedules.map((schedule, index) => ({
            key: index,
            name: schedule.name,
            timeCondition: schedule.timeConditions,
            actions: [
              {
                icon: (
                  <img
                    alt="config-icon"
                    src={ConfigImg}
                    width="18px"
                    height="18px"
                  />
                ),
                color: 'green',
                text: 'Configurar',
                handler: () => this.editSchedule(schedule.id),
              },
              {
                icon: (
                  <img
                    alt="trash-icon"
                    src={TrashImg}
                    width="13px"
                    height="15px"
                  />
                ),
                color: 'red',
                text: 'Eliminar',
                handler: () =>
                  createModal({
                    title: 'Eliminar Horario',
                    width: 480,
                    body: (
                      <DeleteModal
                        item={{ id: schedule.id, phone }}
                        title="¿Estas seguro que deseas eliminar este Horario?"
                        removeText="Eliminar Horario"
                        deleteAction={deleteSchedule}
                      />
                    ),
                  }),
              },
            ],
          }))}
          onChangeField={(key, value) => {
            this.setState(prevState => ({
              ...prevState,
              table: {
                ...prevState.table,
                [key]: value,
              },
            }));
          }}
        />
      </SectionContainer>
    );
  }
}

SchedulesScreen.propTypes = {
  schedules: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  match: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  fetchSchedules: PropTypes.func.isRequired,
  createModal: PropTypes.func.isRequired,
  deleteSchedule: PropTypes.func.isRequired,
};

export default SchedulesScreen;
