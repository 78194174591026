import styled from 'styled-components';

export const Layout = styled.div`
  padding: 2em;
  height: 17em;
  display: flex;
  justify-content: center;
`;

export const ErrorContainer = styled.div`
  flex-wrap: wrap;
  padding-top: 20px;
`;

export const ErrorText = styled.div`
  color: red;
  font-size: 14px;
`;
