import styled from 'styled-components';

export const Input = styled.input`
  height: 40px;
  box-sizing: border-box;
  width: 250px;
  border: 1px solid #E8E8E8;
  background-color: #FFFFFF;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.05);
  color: #6d9191;;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  outline: none;
  padding: 0 20px;
  transition: all 0.2s ease-in;
  &::-webkit-input-placeholder {
    opacity: 0.2;
    color: #2D2F3B;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
  }
  &:focus {
    border: 1px solid #71CFCF;
    box-shadow: 0 4px 10px 0 rgba(0,0,0,0.05);
    outline: 0 none;
    border-left: 3px solid #71CFCF;
    color: black;
  }
  ${props => ({
    'margin-bottom': props.marginBottom ? '20px' : '0',
    'margin-right': props.marginRight ? '20px' : '0',
    width: props.width ? `${props.width}px` : '250px',
  })}
  ${props =>
    props.fullWidth &&
    `
    width: 100%
  `}
  ${props =>
    props.noBorderRadiusBottom &&
    `
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `}
  ${props =>
    props.noBorderRadiusTop &&
    `
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  `}
  ${props =>
    props.submited &&
    `
   color: #6d9191;
  `}

`;

export const StyledFileInputLabel = styled.label`
  transition: 0.25s ease-in;
  font-size: 0.9em;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 5px;
  height: 40px;
  line-height: 40px;
  justify-content: center;
  font-weight: 500;
  color: #ededef;
  background-color: #ffffff;
  border: 1px dashed #71cfcf;
  display: flex;
  &:hover {
    background-color: #71cfcf;
    color: #ffffff;
    opacity: 0.6;
  }
`;

export const StyledFileInput = styled(Input).attrs({
  type: 'file',
})`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;
