// Modules
import { connect } from 'react-redux';

// Screen Layout
import View from './view';

// Actions
import { fetchAgentReasons } from '../../../../modules/agents/actions';

import {
  deleteReason,
  updateReason,
} from '../../../../modules/reasons/actions';

import { createModal, deleteModal } from '../../../../modules/ui/actions';

// Redux Connector
export default connect(
  state => ({
    me: state.auth.me,
    reasons: state.agents.agentReasons,
  }),
  {
    fetchAgentReasons,
    updateReason,
    deleteReason,
    createModal,
    deleteModal,
  }
)(View);
