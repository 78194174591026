import { handleActions } from 'redux-actions';

import { setReasons, setReasonById } from './actions';

const defaultState = {
  list: [],
};

export default handleActions(
  {
    [setReasons]: (state, action) => ({
      ...state,
      list: action.payload.reasons,
    }),
    [setReasonById]: (state, action) => ({
      ...state,
      reasonById: action.payload.reason,
    }),
  },
  defaultState
);
