// Modules
import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

// Commons
import { Layout } from './styles';

import Actions from '../UI/Modal/components/Actions';
import { Button } from '../../theme/styles';

import { Input } from '../Input/styles';
import { Label } from '../Label/styles';

// Validation
const validation = Yup.object().shape({
  name: Yup.string().required('Required'),
  destinationCode: Yup.string().required('Required'),
});

class EditDestinationForm extends React.Component {
  componentDidMount() {
    const { id, fetchDestinationById } = this.props;
    fetchDestinationById({ id });
  }

  render() {
    const {
      id,
      destination,
      updateDestination,
      deleteModal,
      isSubmitting,
    } = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={{
          name: destination ? destination.name : '',
          destinationCode: destination ? destination.destinationCode : '',
        }}
        validationSchema={validation}
        onSubmit={values => {
          updateDestination({ id, data: values });
        }}
      >
        {formProps => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
          } = formProps;

          return (
            <form>
              <Layout>
                <div>
                  <Label htmlFor="name" style={{ display: 'block' }}>
                    Nombre
                  </Label>
                  <Input
                    marginBottom
                    id="name"
                    placeholder="Enter your destination name"
                    type="text"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.name && touched.name
                        ? 'text-input error'
                        : 'text-input'
                    }
                  />
                  {errors.name && touched.name && (
                    <div className="input-feedback">{errors.name}</div>
                  )}

                  <Label htmlFor="destinationCode" style={{ display: 'block' }}>
                    Código de Destino
                  </Label>
                  <Input
                    id="destinationCode"
                    placeholder="Enter your destination code"
                    type="text"
                    value={values.destinationCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.destinationCode && touched.destinationCode
                        ? 'text-input error'
                        : 'text-input'
                    }
                  />
                  {errors.destinationCode && touched.destinationCode && (
                    <div className="input-feedback">
                      {errors.destinationCode}
                    </div>
                  )}
                </div>
              </Layout>
              <Actions>
                <Button
                  type="button"
                  disabled={isSubmitting}
                  onClick={deleteModal}
                >
                  Cancelar
                </Button>
                <Button
                  primary
                  type="submit"
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  Editar Destino
                </Button>
              </Actions>
            </form>
          );
        }}
      </Formik>
    );
  }
}

EditDestinationForm.propTypes = {
  destination: PropTypes.shape({}).isRequired,
  id: PropTypes.string.isRequired,
  values: PropTypes.shape({}),
  touched: PropTypes.shape({}),
  errors: PropTypes.shape({}),
  updateDestination: PropTypes.func.isRequired,
  fetchDestinationById: PropTypes.func.isRequired,
  deleteModal: PropTypes.func.isRequired,
};

EditDestinationForm.defaultProps = {
  values: {},
  touched: {},
  errors: {},
};

export default EditDestinationForm;
