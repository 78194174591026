import React from 'react';
import PropType from 'prop-types';
import { withFormik } from 'formik';
import { string, object } from 'yup';

import { FormRow, Button } from '../../../theme/styles';
import Logo from '../../../components/Logo';
import IntroText from '../../../components/IntroText';
import ErrorText from '../../../components/ErrorText';
import Input from '../../../components/Input';
import InputCheckbox from '../../../components/InputCheckbox';
import FormContainer from '../../../components/FormContainer';
import Loader from '../../../components/Loader';

const Message = ({ withError }) =>
  withError ? (
    <ErrorText>
      Los datos introducidos no son correctos.
      <br />
      Por favor inténtalo de nuevo.
    </ErrorText>
  ) : (
    <IntroText>
      Bienvenido, introduce tus datos
      <br />
      para acceder a tu cuenta.
    </IntroText>
  );

Message.defaultProps = {
  withError: false,
};

Message.propTypes = {
  withError: PropType.bool,
};

const InnerForm = ({
  values,
  // errors,
  // touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
}) => (
  <FormContainer onSubmit={handleSubmit}>
    <Logo />
    <FormRow>
      <Message withError={values.status === 'logged-error'} />
    </FormRow>
    <FormRow>
      <Input
        type="text"
        name="email"
        placeholder="Email"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        noBorderRadiusBottom
      />
      {/* touched.email && errors.email && <span>{errors.email}</span> */}
    </FormRow>
    <FormRow>
      <Input
        type="password"
        name="password"
        placeholder="Contraseña"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.password}
        noBorderRadiusTop
      />
      {/* touched.password && errors.password && <span>{errors.password}</span> */}
    </FormRow>
    <FormRow margin="14px 0 34px">
      <InputCheckbox
        type="checkbox"
        onChange={handleChange}
        onBlur={handleBlur}
        name="remember"
        label="Recordarme"
        checked={values.remember}
      />
    </FormRow>
    <FormRow margin="0 0 48px">
      <Button
        type="submit"
        primary
        width="107px"
        disabled={isSubmitting || values.isLoading}
      >
        {values.isLoading ? (
          <Loader color="#fff" size="18px" />
        ) : (
          <span>Ingresar</span>
        )}
      </Button>
    </FormRow>
  </FormContainer>
);

InnerForm.propTypes = {
  values: PropType.shape({}).isRequired,
  errors: PropType.shape({}).isRequired,
  touched: PropType.shape({}).isRequired,
  isSubmitting: PropType.bool.isRequired,
  handleChange: PropType.func.isRequired,
  handleBlur: PropType.func.isRequired,
  handleSubmit: PropType.func.isRequired,
};

const Form = withFormik({
  enableReinitialize: true,
  mapPropsToValues: props => ({
    email: '',
    password: '',
    history: props.history,
    status: props.status,
    isLoading: props.isLoading,
  }),
  validationSchema: object().shape({
    email: string().required(),
    password: string().required(),
  }),
  handleSubmit: (values, { props: { login, history }, setSubmitting }) => {
    login({ ...values, history });
    setSubmitting(false);
  },
})(InnerForm);

export default Form;
