// Modules
import { connect } from 'react-redux';

// Screen Layout
import View from './view';

// Actions
import { deleteReason } from '../../modules/reasons/actions';

import { createModal } from '../../modules/ui/actions';

// Redux Connector
export default connect(
  state => ({
    me: state.auth.me,
  }),
  {
    deleteReason,
    createModal,
  }
)(View);
