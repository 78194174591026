// Modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Commons
import Headline from '../../components/Headline';
import DeleteModal from '../../components/DeleteModal';
import Table from '../../components/TableV2';
import { SectionContainer, Button } from '../../theme/styles';

import UserForm from '../../components/UserForm';

// Assets
import TrashImg from './img/trash-can-outline.svg';

class UsersScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table: {
        page: 1,
        order: 'desc',
        orderBy: 'name',
      },
    };
  }

  componentDidMount() {
    const { fetchUsers } = this.props;
    fetchUsers();
  }

  createUser = () => {
    const { createModal } = this.props;
    createModal({
      title: 'Nuevo Usuario',
      width: 560,
      body: <UserForm />,
    });
  };

  render() {
    const {
      table: { page, order, orderBy },
    } = this.state;
    const { users, deleteUser, createModal } = this.props;
    // ===========================
    return (
      <SectionContainer>
        <Headline
          title="Usuarios"
          actions={
            <Button primary onClick={this.createUser}>
              Nuevo Usuario
            </Button>
          }
        />
        <Table
          page={page}
          order={order}
          orderBy={orderBy}
          rowsPerPage={12}
          fields={[
            {
              key: 'name',
              label: 'Nombre',
              format: 'default',
            },
            {
              key: 'account',
              label: 'Cuenta',
              format: 'default',
            },
            {
              key: 'subaccount',
              label: 'Sub-Cuenta',
              format: 'default',
              color: '#ABABB8',
            },
            {
              key: 'email',
              label: 'Email',
              format: 'default',
              color: '#ABABB8',
            },
            {
              key: 'role',
              label: 'Rol',
              format: 'default',
              color: '#66CCCC',
            },
            {
              key: 'actions',
              label: 'Acciones',
              format: 'embed',
            },
          ]}
          data={users.map((user, index) => ({
            key: index,
            name: user.name,
            account: user.accountName,
            subaccount: user.subaccount,
            email: user.email,
            role: user.role,
            actions: [
              {
                disabled: user.role === 'SUPERADMIN',
                icon: (
                  <img
                    alt="trash-icon"
                    src={TrashImg}
                    width="13px"
                    height="15px"
                  />
                ),
                color: 'red',
                text: 'Eliminar',
                handler: () =>
                  createModal({
                    title: 'Eliminar Usuario',
                    width: 480,
                    body: (
                      <DeleteModal
                        item={{ id: user.id }}
                        title="¿Estas seguro que deseas eliminar este Usuario?"
                        removeText="Eliminar Usuario"
                        deleteAction={deleteUser}
                      />
                    ),
                  }),
              },
            ],
          }))}
          onChangeField={(key, value) => {
            this.setState(prevState => ({
              ...prevState,
              table: {
                ...prevState.table,
                [key]: value,
              },
            }));
          }}
        />
      </SectionContainer>
    );
  }
}

UsersScreen.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  match: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  fetchUsers: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  createModal: PropTypes.func.isRequired,
};

export default UsersScreen;
