import { connect } from 'react-redux';

import View from './view';

import { fetchUserById } from '../../modules/users/actions';

export default connect(
  state => ({
    user: state.users.userById,
  }),
  {
    fetchUserById,
  }
)(View);
