// Modules
import React from 'react';
import PropTypes from 'prop-types';

// Commons
import { Layout, Title } from './styles';

const Headline = ({ title, actions, className = '' }) => (
  <Layout className={className}>
    <Title>{title}</Title>
    <div>{actions}</div>
  </Layout>
);

Headline.defaultProps = {
  actions: null,
};

Headline.propTypes = {
  title: PropTypes.string.isRequired,
  actions: PropTypes.element,
};

export default Headline;
