// Modules
import React from 'react';
import PropType from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Actions from '../UI/Modal/components/Actions';

import { RadioLabel, RadioInput, AccountTypeText } from './styles';
import { FlexColumn, Button } from '../../theme/styles';
import { Input } from '../Input/styles';
import { Label } from '../Label/styles';
import { ModalInputsContainer } from '../ModalInputsContainer/styles';
import { ModalInputsRowContainer } from '../ModalInputsRowContainer/styles';

const validation = Yup.object().shape({
  type: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
  identifier: Yup.string(),
  businessName: Yup.string(),
  direction: Yup.string(),
  nif: Yup.string(),
  billingContact: Yup.string(),
  email: Yup.string().email(),
  phone: Yup.number(),
  businessContact: Yup.string(),
  emailContactBusiness: Yup.string().email(),
  phoneContactBusiness: Yup.number(),
});

const types = [
  { name: 'Cliente', value: 'CLIENT' },
  { name: 'Socio', value: 'PARTNER' },
];

const AccountsFormExtraPartner = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
}) => {
  if (values.type === 'PARTNER') {
    return (
      <>
        <ModalInputsRowContainer>
          <FlexColumn>
            <Label htmlFor="businessContact" style={{ display: 'block' }}>
              Contacto negocio
            </Label>
            <Input
              marginRight
              id="businessContact"
              placeholder="Enter your account businessContact"
              type="text"
              value={values.businessContact}
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                errors.businessContact && touched.businessContact
                  ? 'text-input error'
                  : 'text-input'
              }
            />

            {errors.businessContact && touched.businessContact && (
              <div className="input-feedback">{errors.businessContact}</div>
            )}
          </FlexColumn>
          <FlexColumn>
            <Label htmlFor="emailContactBusiness" style={{ display: 'block' }}>
              Email contacto negocio
            </Label>
            <Input
              id="emailContactBusiness"
              placeholder="Enter your account emailContactBusiness"
              type="text"
              value={values.emailContactBusiness}
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                errors.emailContactBusiness && touched.emailContactBusiness
                  ? 'text-input error'
                  : 'text-input'
              }
            />
            {errors.emailContactBusiness && touched.emailContactBusiness && (
              <div className="input-feedback">
                {errors.emailContactBusiness}
              </div>
            )}
          </FlexColumn>
        </ModalInputsRowContainer>

        <ModalInputsRowContainer>
          <FlexColumn>
            <Label htmlFor="phoneContactBusiness" style={{ display: 'block' }}>
              Teléfono contacto negocio
            </Label>
            <Input
              marginRight
              id="phoneContactBusiness"
              placeholder="Enter your account phoneContactBusiness"
              type="text"
              value={values.phoneContactBusiness}
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                errors.phoneContactBusiness && touched.phoneContactBusiness
                  ? 'text-input error'
                  : 'text-input'
              }
            />
            {errors.phoneContactBusiness && touched.phoneContactBusiness && (
              <div className="input-feedback">
                {errors.phoneContactBusiness}
              </div>
            )}
          </FlexColumn>
        </ModalInputsRowContainer>
      </>
    );
  }
  return null;
};

const AccountsForm = ({
  createAccount,
  deleteModal,
  account,
  me: { email, id, name },
  isSubmitting,
}) => (
  <Formik
    initialValues={account}
    enableReinitialize
    onSubmit={values => {
      const newAccount = { ...values };
      newAccount.createdBy = id;
      newAccount.createdByEmail = email;
      newAccount.authorName = name;
      createAccount(newAccount);
    }}
    validationSchema={validation}
  >
    {formProps => {
      const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
      } = formProps;
      return (
        <form>
          <ModalInputsContainer paddingBottom={20}>
            <ModalInputsRowContainer>
              <Label
                marginTop
                marginRight
                htmlFor="type"
                style={{ display: 'block' }}
              >
                Tipo
              </Label>
              <div>
                {types.map(type => (
                  <RadioLabel key={type.value}>
                    <RadioInput
                      id="type"
                      type="radio"
                      name="type"
                      value={type.value}
                      defaultChecked={type.value === values.type}
                      onChange={handleChange}
                      className={
                        errors.type && touched.type
                          ? 'text-input error'
                          : 'text-input'
                      }
                    />
                    <AccountTypeText>{type.name}</AccountTypeText>
                    {errors.type && touched.type && (
                      <div className="input-feedback">{errors.type}</div>
                    )}
                  </RadioLabel>
                ))}
              </div>
            </ModalInputsRowContainer>

            <ModalInputsRowContainer spaceBetween>
              <FlexColumn>
                <Label htmlFor="name" style={{ display: 'block' }}>
                  Nombre
                </Label>
                <Input
                  marginRight
                  id="name"
                  placeholder="Nombre de la cuenta"
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.name && touched.name
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                {errors.name && touched.name && (
                  <div className="input-feedback">{errors.name}</div>
                )}
              </FlexColumn>

              <FlexColumn>
                <Label htmlFor="businessName" style={{ display: 'block' }}>
                  Razón social
                </Label>
                <Input
                  id="businessName"
                  placeholder="Nombre de la empresa"
                  type="text"
                  value={values.businessName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.businessName && touched.businessName
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                {errors.businessName && touched.businessName && (
                  <div className="input-feedback">{errors.businessName}</div>
                )}
              </FlexColumn>
            </ModalInputsRowContainer>

            <ModalInputsRowContainer>
              <FlexColumn>
                <Label htmlFor="direction" style={{ display: 'block' }}>
                  Dirección
                </Label>
                <Input
                  marginRight
                  width={350}
                  id="direction"
                  placeholder="Dirección de la cuenta"
                  type="text"
                  value={values.direction}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.direction && touched.direction
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                {errors.direction && touched.direction && (
                  <div className="input-feedback">{errors.nif}</div>
                )}
              </FlexColumn>

              <FlexColumn>
                <Label htmlFor="nif" style={{ display: 'block' }}>
                  Nif
                </Label>
                <Input
                  width={160}
                  id="nif"
                  placeholder="NIF de la cuenta"
                  type="text"
                  value={values.nif}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.nif && touched.nif
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                {errors.nif && touched.nif && (
                  <div className="input-feedback">{errors.nif}</div>
                )}
              </FlexColumn>
            </ModalInputsRowContainer>

            <ModalInputsRowContainer>
              <FlexColumn>
                <Label htmlFor="billingContact" style={{ display: 'block' }}>
                  Contacto Facturación
                </Label>
                <Input
                  marginRight
                  id="billingContact"
                  placeholder="Dirección de facturación de la cuenta"
                  type="text"
                  value={values.billingContact}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.billingContact && touched.billingContact
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                {errors.billingContact && touched.billingContact && (
                  <div className="input-feedback">{errors.billingContact}</div>
                )}
              </FlexColumn>

              <FlexColumn>
                <Label htmlFor="email" style={{ display: 'block' }}>
                  Email
                </Label>
                <Input
                  id="email"
                  placeholder="Mail de la cuenta"
                  type="text"
                  width={257}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.email && touched.email
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                {errors.email && touched.email && (
                  <div className="input-feedback">{errors.email}</div>
                )}
              </FlexColumn>
            </ModalInputsRowContainer>

            <ModalInputsRowContainer>
              <FlexColumn>
                <Label htmlFor="phone" style={{ display: 'block' }}>
                  Teléfono
                </Label>
                <Input
                  marginRight
                  id="phone"
                  placeholder="Teléfono de la cuenta"
                  type="text"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.phone && touched.phone
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                {errors.phone && touched.phone && (
                  <div className="input-feedback">{errors.phone}</div>
                )}
              </FlexColumn>
            </ModalInputsRowContainer>

            <AccountsFormExtraPartner {...formProps} />
          </ModalInputsContainer>
          <Actions>
            <Button type="button" disabled={isSubmitting} onClick={deleteModal}>
              Cancelar
            </Button>
            <Button
              primary
              type="submit"
              disabled={isSubmitting}
              onClick={handleSubmit}
            >
              Crear Cuenta
            </Button>
          </Actions>
        </form>
      );
    }}
  </Formik>
);

AccountsForm.propTypes = {
  createAccount: PropType.func.isRequired,
  values: PropType.shape({
    type: PropType.string,
  }),
  touched: PropType.shape({}),
  errors: PropType.shape({}),
  account: PropType.shape({}),
  me: PropType.shape({
    id: PropType.string,
  }).isRequired,
  deleteModal: PropType.func.isRequired,
};

AccountsForm.defaultProps = {
  values: {},
  touched: {},
  errors: {},
  account: {
    type: 'CLIENT',
    name: '',
    businessName: '',
    direction: '',
    nif: '',
    billingContact: '',
    email: '',
    phone: '',
    businessContact: '',
    emailContactBusiness: '',
    phoneContactBusiness: '',
  },
};

AccountsFormExtraPartner.propTypes = {
  values: PropType.shape({
    type: PropType.string,
    businessContact: PropType.string,
    emailContactBusiness: PropType.string,
    phoneContactBusiness: PropType.string,
  }).isRequired,
  touched: PropType.bool.isRequired,
  errors: PropType.shape({
    businessContact: PropType.string,
    phoneContactBusiness: PropType.string,
    emailContactBusiness: PropType.string,
  }).isRequired,
  handleChange: PropType.func.isRequired,
  handleBlur: PropType.func.isRequired,
};

export default AccountsForm;
