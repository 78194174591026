// Modules
import { connect } from 'react-redux';

// Layout Screen
import View from './view';

// Actions
import { fetchAgentById } from '../../modules/agents/actions';

// Redux Connector
export default connect(
  state => ({
    agent: state.agents.agentById,
  }),
  {
    fetchAgentById,
  }
)(View);
