import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

// Shared
import { Alert } from 'antd';
import ErrorText from '../../components/ErrorText';
import Input from '../../components/Input';
import { Container, Button } from '../../theme/styles';

import {
  Form,
  FormRow,
  FormContent,
  FormInputContent,
  ButtonContainer,
} from './styles';

import TableName from '../../components/TableName';
import Label from '../../components/Label';
import Select from '../../components/Select';
import Headline from '../../components/Headline';

const validation = Yup.object().shape({
  scheduleRequestUrl: Yup.string(),
  schedulePriceUrl: Yup.string(),
  scheduleAddressUrl: Yup.string(),
});

const types = [
  {
    name: 'GET',
    value: 'get',
  },
  {
    name: 'POST',
    value: 'post',
  },
  {
    name: 'PUT',
    value: 'put',
  },
  {
    name: 'DELETE',
    value: 'delete',
  },
];

class IntegrationsScreen extends React.Component {
  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      fetchIntegration,
      clientId,
      setSubmitted,
    } = this.props;
    fetchIntegration({
      agentId: id,
      clientId,
    });
    setSubmitted();
  }

  getTypeFromValue = (value = null) => {
    if (!value) return types[0].value;
    return types.filter(e => e.value === value)[0].value;
  };

  initializeParams = () => {
    const { data } = this.props;
    return {
      scheduleRequestUrl: data ? data.scheduleRequestUrl : '',
      schedulePriceUrl: data ? data.schedulePriceUrl : '',
      scheduleAddressUrl: data ? data.scheduleAddressUrl : '',
      scheduleRequestType: this.getTypeFromValue(
        data ? data.scheduleRequestType : null
      ),
      schedulePriceType: this.getTypeFromValue(
        data ? data.schedulePriceType : null
      ),
      scheduleAddressType: this.getTypeFromValue(
        data ? data.scheduleAddressType : null
      ),
    };
  };

  render() {
    const {
      match: {
        params: { id },
      },
      createIntegration,
      editIntegration,
      clientId,
      data,
      isSubmitted,
      setSubmitted,
      isSubmitting,
    } = this.props;
    return (
      <Container marginLeft>
        <Formik
          enableReinitialize
          initialValues={{
            agentId: id,
            clientId,
            ...(data && { id: data.id }),
            ...this.initializeParams(),
          }}
          onSubmit={values => {
            // Already have info.
            if (data) {
              editIntegration({ data: values });
            } else {
              createIntegration({ data: values });
            }
          }}
          validationSchema={validation}
        >
          {formProps => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
            } = formProps;
            return (
              <>
                <Container>
                  <Headline title="Integración" />
                </Container>
                <Form>
                  <FormRow>
                    <FormInputContent>
                      <Label
                        htmlFor="scheduleRequestUrl"
                        style={{ display: 'block' }}
                      >
                        URL SOLICITUD HORARIO
                      </Label>
                      <Input
                        id="scheduleRequestUrl"
                        placeholder="Nombre"
                        type="text"
                        submited={
                          !!(isSubmitted && values.scheduleRequestUrl !== '')
                        }
                        onFocus={() => setSubmitted()}
                        value={values.scheduleRequestUrl}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.scheduleRequestUrl &&
                          touched.scheduleRequestUrl
                            ? 'text-input error'
                            : 'text-input'
                        }
                        fullWidth
                        noBorderRadiusTop
                        noBorderRadiusBottom
                      />
                      {errors.scheduleRequestUrl &&
                        touched.scheduleRequestUrl && (
                          <ErrorText>{errors.scheduleRequestUrl}</ErrorText>
                        )}
                    </FormInputContent>
                    <FormContent>
                      <Select
                        id="scheduleRequestType"
                        onChange={handleChange}
                        width="150"
                        withoutLeftBorder
                        noBorderRadiusTop
                        noBorderRadiusBottom
                        value={values.scheduleRequestType}
                      >
                        {types.map(type => (
                          <option key={type.value} value={type.value}>
                            {type.name}
                          </option>
                        ))}
                      </Select>
                    </FormContent>
                  </FormRow>
                  <FormRow>
                    <FormInputContent>
                      <Label
                        htmlFor="schedulePriceUrl"
                        style={{ display: 'block' }}
                      >
                        URL SOLICITUD PRECIO
                      </Label>
                      <Input
                        id="schedulePriceUrl"
                        placeholder="Razón social"
                        type="text"
                        submited={
                          !!(isSubmitted && values.schedulePriceUrl !== '')
                        }
                        value={values.schedulePriceUrl}
                        onChange={handleChange}
                        onFocus={() => setSubmitted()}
                        onBlur={handleBlur}
                        className={
                          errors.schedulePriceUrl && touched.schedulePriceUrl
                            ? 'text-input error'
                            : 'text-input'
                        }
                        fullWidth
                        noBorderRadiusTop
                        noBorderRadiusBottom
                      />
                      {errors.schedulePriceUrl && touched.schedulePriceUrl && (
                        <ErrorText>{errors.schedulePriceUrl}</ErrorText>
                      )}
                    </FormInputContent>
                    <FormContent>
                      <Select
                        id="schedulePriceType"
                        onChange={handleChange}
                        width="150"
                        withoutLeftBorder
                        noBorderRadiusTop
                        noBorderRadiusBottom
                        value={values.schedulePriceType}
                      >
                        {types.map(type => (
                          <option key={type.value} value={type.value}>
                            {type.name}
                          </option>
                        ))}
                      </Select>
                    </FormContent>
                  </FormRow>
                  <FormRow>
                    <FormInputContent>
                      <Label
                        htmlFor="scheduleAddressUrl"
                        style={{ display: 'block' }}
                      >
                        URL SOLICITUD DIRECCIÓN
                      </Label>
                      <Input
                        id="scheduleAddressUrl"
                        placeholder="scheduleAddressUrl"
                        type="text"
                        submited={
                          !!(isSubmitted && values.scheduleAddressUrl !== '')
                        }
                        value={values.scheduleAddressUrl}
                        onChange={handleChange}
                        onFocus={() => setSubmitted()}
                        onBlur={handleBlur}
                        className={
                          errors.scheduleAddressUrl &&
                          touched.scheduleAddressUrl
                            ? 'text-input error'
                            : 'text-input'
                        }
                        fullWidth
                        noBorderRadiusTop
                        noBorderRadiusBottom
                      />
                      {errors.scheduleAddressUrl &&
                        touched.scheduleAddressUrl && (
                          <ErrorText>{errors.scheduleAddressUrl}</ErrorText>
                        )}
                    </FormInputContent>
                    <FormContent>
                      <Select
                        id="scheduleAddressType"
                        onChange={handleChange}
                        width="150"
                        withoutLeftBorder
                        noBorderRadiusTop
                        noBorderRadiusBottom
                        value={values.scheduleAddressType}
                      >
                        {types.map(type => (
                          <option key={type.value} value={type.value}>
                            {type.name}
                          </option>
                        ))}
                      </Select>
                    </FormContent>
                  </FormRow>
                  {isSubmitted && (
                    <Alert
                      message="Integraciones correctamente guardadas"
                      type="success"
                      showIcon
                    />
                  )}
                  <ButtonContainer>
                    <Button
                      primary
                      type="button"
                      disabled={isSubmitting}
                      onClick={handleSubmit}
                    >
                      Guardar Integraciones
                    </Button>
                  </ButtonContainer>
                </Form>
              </>
            );
          }}
        </Formik>
      </Container>
    );
  }
}

IntegrationsScreen.propTypes = {
  createIntegration: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  fetchIntegration: PropTypes.func.isRequired,
  editIntegration: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired,
  data: PropTypes.shape({}).isRequired,
};

export default IntegrationsScreen;
