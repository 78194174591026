// Modules
import { connect } from 'react-redux';

// Screen Layout
import View from './view';

// Agents
import { fetchAgents, deleteAgent } from '../../modules/agents/actions';

// Modal
import { createModal } from '../../modules/ui/actions';

// Redux Connector
export default connect(
  state => ({
    me: state.auth.me,
    agents: state.agents.list,
  }),
  {
    // Agents
    fetchAgents,
    deleteAgent,

    // Modal
    createModal,
  }
)(View);
