// Modules
import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

// Commons
import { Layout } from './styles';

import Actions from '../UI/Modal/components/Actions';
import { Button } from '../../theme/styles';

import { Input, StyledFileInput, StyledFileInputLabel } from '../Input/styles';
import { Label } from '../Label/styles';

// Validation
const validation = Yup.object().shape({
  name: Yup.string().required('Required'),
  identifier: Yup.string().required('Required'),
  audio: Yup.mixed().required(),
});

const AudioForm = ({
  createAudio,
  deleteModal,
  audio,
  agentId,
  agentPhone,
  agentName,
  role,
  isSubmitting,
}) => (
  <Formik
    enableReinitialize
    initialValues={audio}
    validationSchema={validation}
    onSubmit={values => {
      const audioToBeCreated = values;
      audioToBeCreated.agentId = agentId;
      audioToBeCreated.agentPhone = agentPhone;
      audioToBeCreated.agentName = agentName;
      audioToBeCreated.accountRole = role;
      createAudio(audioToBeCreated);
    }}
  >
    {formProps => {
      const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      } = formProps;

      return (
        <form>
          <Layout>
            <div>
              <Label htmlFor="group" style={{ display: 'block' }}>
                Grupo
              </Label>
              <Input
                marginBottom
                id="group"
                placeholder="Grupo"
                type="text"
                value={values.group}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.group && touched.group
                    ? 'text-input error'
                    : 'text-input'
                }
              />
              {errors.group && touched.group && (
                <div className="input-feedback">{errors.group}</div>
              )}

              <Label htmlFor="identifier" style={{ display: 'block' }}>
                Identificador
              </Label>
              <Input
                id="identifier"
                placeholder="Identificador"
                type="text"
                value={values.identifier}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.identifier && touched.identifier
                    ? 'text-input error'
                    : 'text-input'
                }
              />
              {errors.identifier && touched.identifier && (
                <div className="input-feedback">{errors.identifier}</div>
              )}

              <Label
                htmlFor="name"
                style={{ display: 'block', marginTop: '20px' }}
              >
                Nombre
              </Label>
              <Input
                id="name"
                placeholder="Nombre del audio"
                type="text"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.name && touched.name
                    ? 'text-input error'
                    : 'text-input'
                }
              />
              {errors.name && touched.name && (
                <div className="input-feedback">{errors.name}</div>
              )}

              <StyledFileInputLabel htmlFor="audio">
                {values.audio ? values.audio.name : 'Subir un audio'}
              </StyledFileInputLabel>
              <StyledFileInput
                id="audio"
                name="audio"
                placeholder="Sube tu audio"
                type="file"
                accept="audio/*"
                onChange={event => {
                  setFieldValue('audio', event.currentTarget.files[0]);
                }}
                onBlur={handleBlur}
                className={
                  errors.audio && touched.audio
                    ? 'text-input error'
                    : 'text-input'
                }
              />
              {errors.audio && touched.audio && (
                <div className="input-feedback">{errors.audio}</div>
              )}
            </div>
          </Layout>
          <Actions>
            <Button type="button" disabled={isSubmitting} onClick={deleteModal}>
              Cancelar
            </Button>
            <Button
              primary
              type="submit"
              disabled={isSubmitting}
              onClick={handleSubmit}
            >
              Crear Audio
            </Button>
          </Actions>
        </form>
      );
    }}
  </Formik>
);

AudioForm.propTypes = {
  values: PropTypes.shape({}),
  touched: PropTypes.shape({}),
  errors: PropTypes.shape({}),
  audio: PropTypes.shape({}),
  createAudio: PropTypes.func.isRequired,
  deleteModal: PropTypes.func.isRequired,
  agentId: PropTypes.string.isRequired,
  agentPhone: PropTypes.string.isRequired,
  agentName: PropTypes.string.isRequired,
};

AudioForm.defaultProps = {
  values: {},
  touched: {},
  errors: {},
  audio: {
    group: '',
    identifier: '',
    name: '',
    audio: null,
  },
};

export default AudioForm;
