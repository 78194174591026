// Modules
import React from 'react';
import PropTypes from 'prop-types';

// Commons
import { Layout, Pagination, Counter, Actions, Page, Image } from './styles';

// Icons
import ArrowImg from './img/Path.svg';

const calculateActualRecord = ({ pageSize, page, data }) => {
  if (data.length <= pageSize * page) {
    return data.length;
  }
  return pageSize * page;
};

const Footer = ({ data, onChangePage, page, pageSize }) => (
  <Layout>
    <Pagination>
      <Counter>
        {page === 1 && data.length === 0 ? 0 : pageSize * (page - 1) + 1}-
        {data.length <= 8
          ? data.length
          : calculateActualRecord({ pageSize, page, data })}{' '}
        de
        {` ${data.length}`}
      </Counter>
      <Actions>
        <Page
          disabled={page === 1}
          direction="left"
          onClick={() => onChangePage(-1)}
        >
          <Image src={ArrowImg} />
        </Page>
        <Page
          disabled={page * pageSize >= data.length}
          direction="right"
          onClick={() => onChangePage(1)}
        >
          <Image src={ArrowImg} />
        </Page>
      </Actions>
    </Pagination>
  </Layout>
);

Footer.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
};

export default Footer;
