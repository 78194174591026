import { createActions } from 'redux-actions';

import {
  FETCH_POPULATIONS,
  FETCH_POPULATIONS_SUCCESS,
  FETCH_POPULATIONS_FAILED,
} from './actionTypes';

export const {
  fetchPopulations,
  fetchPopulationsSuccess,
  fetchPopulationsFailed,
} = createActions(
  FETCH_POPULATIONS,
  FETCH_POPULATIONS_SUCCESS,
  FETCH_POPULATIONS_FAILED
);
