// Modules
import React from 'react';
import PropTypes from 'prop-types';
import size from 'lodash.size';

// Commons
import { Layout, Body, Container } from './styles';

import Header from '../Header';
import SideMenu from '../SideMenu';
import Modal from './Modal';

const UI = ({ path, overlay, children, modal, user }) => (
  <Layout noScroll={path === '/dashboard'}>
    {overlay === 'logged' ? (
      <div style={{ position: 'relative', width: '100%' }}>
        <Header />
        {user && user.role ? (
          <Container paddingBottom={path === '/dashboard'}>
            <SideMenu pathname={path} />
            <Body noPadding={path === '/dashboard'}>{children}</Body>
          </Container>
        ) : null}
      </div>
    ) : (
      children
    )}
    {size(modal) > 0 && (
      <Modal
        title={modal.title}
        width={modal.width}
        body={modal.body}
        actions={modal.actions}
      />
    )}
  </Layout>
);

UI.defaultProps = {
  user: {},
  modal: {},
};

UI.propTypes = {
  path: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  overlay: PropTypes.string.isRequired,
  modal: PropTypes.shape({
    title: PropTypes.any,
    width: PropTypes.number,
    body: PropTypes.element,
    actions: PropTypes.element,
  }),
  user: PropTypes.shape({}),
};

export default UI;
