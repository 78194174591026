import styled from 'styled-components';
import { DatePicker, Card } from 'antd';
import 'antd/dist/antd.css';

const { RangePicker } = DatePicker;

const COLOR = '#66cccc';

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  // background-color: #f6f6f6;
  min-height: calc(100vh - 210px);

  div.headline {
    padding: 32px 0;
  }

  .agent-select {
    height: 32px !important;
  }

  .ant-card {
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 7px !important;
    box-shadow: rgba(145, 158, 171, 0.3) 0px 0px 2px 0px,
      rgba(145, 158, 171, 0.12) 0px 12px 24px -4px !important;
    padding: 12px;
  }

  .ant-picker:hover,
  .ant-picker-focused {
    border-color: ${COLOR};
    border-right-width: 1px;
  }

  .ant-radio-button-wrapper:hover {
    position: relative;
    color: ${COLOR};
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: ${COLOR};
    border-color: ${COLOR};
  }

  .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-right-color: ${COLOR};
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
    border-color: ${COLOR};
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: ${COLOR};
    background: #fff;
    border-color: ${COLOR};
  }
`;

export const MainWraper = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 40px 30px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: calc(100vh - 210px);
`;

export const ContentPie = styled.div`
  width: 48.8%;
  height: 400px;

  margin: 0.25% 0.4%;
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;

  ${props =>
    props.semiPie &&
    `
    height: 300px;
    margin-bottom: 10px;

    h4 {
      margin-bottom: 32px !important;
    }
  `}

  .ant-card, ant-card-body {
    height: 100%;
    // padding: 32px;

    &.h-auto {
      height: auto;
    }
  }

  h4 {
    text-align: left;
    margin: 0;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.6rem;
  }
`;

export const MonthSelector = styled(RangePicker)`
  margin-bottom: 10px !important;
`;

export const HeaderDataContainer = styled.div`
  display: flex;
  margin: 5px 0;
  justify-content: center;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ant-card {
    margin: 0 4px;
    padding: 6px 24px 8px;
  }

  .ant-card-body {
    padding: 6px 24px 8px;
  }

  h4 {
    margin: 0;
    font-family: 'SF UI Display Medium';
    font-size: 0.875rem;
    line-height: 1.75;
  }

  span {
    font-size: 1.5rem;
    line-height: 1.6rem;
    font-weight: bold;
  }
`;

export const ReasonTableContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  background-color: white;
  width: 100%;
  margin-top: 8px;
`;

export const ReasonTableRowContainer = styled.div`
  .ant-progress-outer {
    padding: 0 10px;
  }
`;

export const ReasonTableRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
`;

export const ReasonTableHeading = styled(ReasonTableRow)`
  color: #3e3e3e;
  font-weight: bold;
`;

export const ReasonTableRowItem = styled.div`
  display: flex;
  flex: 1;
  font-size: 12px;
  padding: 2px 0;
  align-items: center;
  padding-left: 10px;
  transition: all 0.15s ease-in-out;

  &:last-child {
    border-bottom: 0;
  }

  ${props =>
    props.doble &&
    `
    flex: 2;
    line-height: 1.2;
  `}

  ${props =>
    props.center &&
    `
    justify-content: center;
    padding: 0;
  `}
`;

export const ReasonTableRowItemProgress = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// export const CardComponent = styled(Card)``;

export const CardComponent = styled.div.attrs(() => ({
  className: 'ant-card',
}))`
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
  padding: 12px;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
`;
