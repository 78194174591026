import { connect } from 'react-redux';
import View from './view';

import {
  updateLocution,
  fetchLocutionById,
} from '../../modules/locutions/actions';
import { deleteModal } from '../../modules/ui/actions';

export default connect(
  state => ({
    locution: state.locutions.locutionById,
    isSubmitting: state.ui.isSubmitting,
  }),
  {
    fetchLocutionById,
    updateLocution,
    deleteModal,
  }
)(View);
