import { handleActions } from 'redux-actions';

import {
  setUsers,
  setUserById,
  setUser,
  setError,
  fetchUserByUidSuccess,
  cleanErrors,
  setPassword,
  setPasswordSuccess,
  setPasswordFailed,
} from './actions';

const defaultState = {
  list: [],
};

export default handleActions(
  {
    [setUser]: (state, action) => ({
      ...state,
      userProfile: action.payload.user,
    }),
    [setUsers]: (state, action) => ({
      ...state,
      list: action.payload.users,
    }),
    [setUserById]: (state, action) => ({
      ...state,
      userById: action.payload.user,
    }),
    [setError]: (state, action) => ({
      ...state,
      error: action.payload,
    }),
    [fetchUserByUidSuccess]: (state, { payload }) => ({
      ...state,
      userByUid: payload.user,
    }),
    [cleanErrors]: state => ({
      ...state,
      error: null,
    }),
    [setPassword]: state => ({
      ...state,
      isSettingPassword: true,
    }),
    [setPasswordSuccess]: state => ({
      ...state,
      isSettingPassword: false,
      setPasswordSuccess: true,
    }),
    [setPasswordFailed]: (state, { payload }) => ({
      ...state,
      error: payload,
      setPasswordSuccess: false,
    }),
  },
  defaultState
);
