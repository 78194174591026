import styled from 'styled-components';

export const ModalInputsRowContainer = styled.div`
  display: flex;
  padding: 15px;
  flex-direction: column;
  ${props => `
    ${props.spaceBetween ? 'justify-content: space-between' : ''}
    ${props.center ? 'justify-content: center' : ''}
  `};
`;
