import { createActions } from 'redux-actions';

import {
  FETCH_USERS,
  FETCH_USER_BY_ID,
  SET_USERS,
  SET_ERROR,
  SET_USER_BY_ID,
  FETCH_USERS_FAILED,
  CREATE_USER,
  DELETE_USER,
  REGISTER_USER,
  REGISTER_USER_FAILED,
  SET_USER,
  FETCH_USER_BY_UID,
  FETCH_USER_BY_UID_SUCCESS,
  FETCH_USER_BY_UID_FAILED,
  CLEAN_ERRORS,
  SET_PASSWORD,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_FAILED,
} from './actionTypes';

export const {
  fetchUsers,
  setUsers,
  setUserById,
  setError,
  registerUser,
  registerUserFailed,
  setUser,
  fetchUserByUid,
  fetchUserByUidSuccess,
  fetchUserByUidFailed,
  cleanErrors,
  setPassword,
  setPasswordSuccess,
  setPasswordFailed,
} = createActions(
  FETCH_USERS,
  SET_USERS,
  SET_USER_BY_ID,
  SET_ERROR,
  FETCH_USERS_FAILED,
  REGISTER_USER,
  REGISTER_USER_FAILED,
  SET_USER,
  FETCH_USER_BY_UID,
  FETCH_USER_BY_UID_SUCCESS,
  FETCH_USER_BY_UID_FAILED,
  CLEAN_ERRORS,
  SET_PASSWORD,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_FAILED
);

export const { createUser, deleteUser, fetchUserById } = createActions({
  [CREATE_USER]: user => ({ user }),
  [DELETE_USER]: user => ({ user }),
  [FETCH_USER_BY_ID]: id => ({ id }),
  [SET_ERROR]: error => ({ error }),
});
