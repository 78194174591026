import React from 'react';
import PropType from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

const validation = Yup.object().shape({
  reason: Yup.string().required('Required'),
  activated: Yup.string().required('Required'),
});

const RadioBox = ({ id, name, label, checked, ...props }) => (
  <div>
    <input type="radio" id={id} name={name} checked={checked} {...props} />
    <label htmlFor={id}>{label}</label>
  </div>
);

const ReasonsFormScreen = props => {
  const { createReason, isSubmitting } = props;
  return (
    <Formik
      initialValues={{
        reason: '',
        activated: '',
      }}
      onSubmit={values => {
        createReason(values);
      }}
      validationSchema={validation}
    >
      {formProps => {
        const {
          values,
          touched,
          errors,
          dirty,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
        } = formProps;
        return (
          <form>
            <label htmlFor="reason" style={{ display: 'block' }}>
              Motivo
            </label>
            <input
              id="reason"
              placeholder="Enter your reason"
              type="text"
              value={values.reason}
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                errors.reason && touched.reason
                  ? 'text-input error'
                  : 'text-input'
              }
            />
            {errors.reason && touched.reason && (
              <div className="input-feedback">{errors.reason}</div>
            )}
            <label htmlFor="activated" style={{ display: 'block' }}>
              Activado
            </label>
            <Field
              name="activated"
              render={({ field }) => (
                <RadioBox
                  {...field}
                  value="true"
                  id="activated-0"
                  checked={values.activated}
                  label="Yes"
                />
              )}
            />
            <Field
              name="activated"
              render={({ field }) => (
                <RadioBox
                  {...field}
                  value="false"
                  id="activated-1"
                  checked={!values.activated}
                  label="No"
                />
              )}
            />

            <button
              type="button"
              className="outline"
              onClick={handleReset}
              disabled={!dirty || isSubmitting}
            >
              Reset
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              onClick={handleSubmit}
            >
              Submit
            </button>
          </form>
        );
      }}
    </Formik>
  );
};

ReasonsFormScreen.propTypes = {
  createReason: PropType.func.isRequired,
  values: PropType.shape({}),
  touched: PropType.shape({}),
  errors: PropType.shape({}),
};

ReasonsFormScreen.defaultProps = {
  values: {},
  touched: {},
  errors: {},
};

export default ReasonsFormScreen;
