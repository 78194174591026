// Modules
import { connect } from 'react-redux';

// Screen Layout
import View from './view';

import {
  fetchLocutions,
  deleteLocution,
} from '../../modules/locutions/actions';

// Modal
import { createModal } from '../../modules/ui/actions';

// Redux Connector
export default connect(
  state => ({
    me: state.auth.me,
    agent: state.agents.agentById,
    locutions: state.locutions.list,
  }),
  {
    fetchLocutions,
    deleteLocution,

    // Modal
    createModal,
  }
)(View);
