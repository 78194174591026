import styled from 'styled-components';

export const Select = styled.select`
  height: 40px;
  border: 1px solid #E8E8E8;
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
  ${props => ({
    'margin-right': props.marginRight ? '20px' : '0',
    width: props.width ? `${props.width}px` : '292px',
    'border-left-width': props.withoutLeftBorder ? 0 : '1px',
  })}
  ${props =>
    props.noBorderRadiusBottom &&
    `
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `}
  ${props =>
    props.noBorderRadiusTop &&
    `
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  `}
`;
