import { get, post, del, put } from '../../lib/restClient';

export const fetchReasons = async () => get('/reasons');

export const fetchReasonById = async id => get(`/reasons/${id}`);

export const createReason = async data => post('/reasons', data);

export const updateReason = async (id, data) => put(`/reasons/${id}`, data);

export const deleteReason = async id => del(`/reasons/${id}`);
