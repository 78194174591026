import React, { Component } from 'react';
import PropType from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import moment from 'moment';
import {
  FormContainer,
  Form,
  FormRow,
  FormContent,
  AddAgentSelect,
  ErrorMessageContainer,
  ErrorMessageText,
} from './styles';
import Input from '../../components/Input';
import {
  SectionContainer,
  Container,
  DetailContainer,
  DetailInfoContainer,
  DetailTitle,
  DetailInfoText,
  DetailInfoTextBlue,
  AddContainer,
  Button,
} from '../../theme/styles';

// import ConfigImg from './img/tune.svg';
// import TrashImg from './img/trash-can-outline.svg';

import Table from '../../components/TableV2';
import TableName from '../../components/TableName';
import Label from '../../components/Label';

const validation = Yup.object().shape({
  name: Yup.string().required('Required'),
  businessName: Yup.string().required('Required'),
  direction: Yup.string().required('Required'),
  nif: Yup.string().required('Required'),
  billingContact: Yup.string().required('Required'),
  email: Yup.string()
    .email()
    .required('Required'),
  phone: Yup.number().required('Required'),
});

class AccountsConfigureScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table: {
        page: 1,
        order: 'desc',
        orderBy: 'name',
      },
    };
  }

  componentDidMount() {
    const {
      fetchAccountById,
      fetchAccountAgents,
      fetchAgents,
      fetchUserById,
      match: {
        params: { id },
      },
    } = this.props;
    fetchAgents();
    fetchAccountById(id);
    fetchAccountAgents({ id });
  }

  submitAgent = (agent, phone) => {
    const {
      addAccountAgent,
      match: {
        params: { id },
      },
    } = this.props;
    return addAccountAgent({ accountId: id, data: { agent, phone } });
  };

  render() {
    const {
      table: { page, order, orderBy },
    } = this.state;
    const {
      account: {
        id: accountId,
        name,
        businessName,
        direction,
        nif,
        billingContact,
        email,
        phone,
        createdAt,
        createdBy,
        createdByEmail,
        authorName,
      },
      match: {
        params: { id },
      },
      agents = [],
      history,
      updateAccount,
      accountAgents,
      // removeAccountAgent,
      errorMessage,
      me,
      getAgentAccountInfo,
      isSubmitting,
    } = this.props;

    return (
      <SectionContainer>
        <DetailContainer>
          <FormContainer>
            <TableName title={name} />
            <DetailInfoContainer>
              <DetailTitle>ID</DetailTitle>
              <DetailInfoText>{nif}</DetailInfoText>
              <DetailTitle>CREADO EL</DetailTitle>
              <DetailInfoText>
                {moment(createdAt).format('DD/MM/YYYY')}
              </DetailInfoText>
              <DetailTitle>CREADO POR</DetailTitle>
              <DetailInfoTextBlue>{authorName}</DetailInfoTextBlue>
            </DetailInfoContainer>
          </FormContainer>
          <Container marginLeft>
            <Formik
              enableReinitialize
              initialValues={{
                name,
                businessName,
                direction,
                nif,
                billingContact,
                email,
                phone,
                agent: 'empty',
                agentPhone: '',
              }}
              onSubmit={(values, { setSubmitting }) => {
                updateAccount({
                  id,
                  data: {
                    name: values.name,
                    businessName: values.businessName,
                    direction: values.direction,
                    nif: values.nif,
                    billingContact: values.billingContact,
                    email: values.email,
                    phone: values.phone,
                  },
                });
                setSubmitting(false);
              }}
              validationSchema={validation}
            >
              {formProps => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                } = formProps;
                return (
                  <>
                    <Container>
                      <TableName
                        backLinkText="Volver a lista de Clientes"
                        textInfo="Añadir agente"
                        backLinkTo={
                          history.location.state &&
                          history.location.state.accountId
                            ? `/accounts/${history.location.state.accountId}/subaccounts`
                            : '/accounts'
                        }
                        buttonText="Añadir"
                        primary
                        handleButtonAction={() => {
                          const currentAgent = agents.filter(
                            agent => values.agent === agent.id
                          );
                          getAgentAccountInfo({
                            accountRole: me.role,
                            agentName: currentAgent[0].name,
                            agentPhone: values.agentPhone,
                          });
                          this.submitAgent(values.agent, values.agentPhone);
                          values.agent = agents[0].id;
                          values.agentPhone = '';
                        }}
                      >
                        <AddContainer widthBehaviour>
                          <AddAgentSelect
                            value={values.agent}
                            onChange={handleChange('agent')}
                          >
                            <option disabled selected value="empty">
                              Seleccionar
                            </option>
                            {agents.map(agent => (
                              <option key={agent.id} value={agent.id}>
                                {agent.name}
                              </option>
                            ))}
                          </AddAgentSelect>
                          <Input
                            id="agentPhone"
                            placeholder="Número de teléfono"
                            type="text"
                            value={values.agentPhone}
                            onChange={handleChange('agentPhone')}
                            onBlur={handleBlur('agentPhone')}
                            className={
                              errors.name && touched.name
                                ? 'text-input error'
                                : 'text-input'
                            }
                          />
                        </AddContainer>
                      </TableName>
                      {errorMessage ? (
                        <ErrorMessageContainer>
                          <ErrorMessageText>{errorMessage}</ErrorMessageText>
                        </ErrorMessageContainer>
                      ) : null}
                    </Container>
                    <Form>
                      <FormRow marginR={30}>
                        <FormContent>
                          <Label htmlFor="name" style={{ display: 'block' }}>
                            Nombre
                          </Label>
                          <Input
                            fullWidth
                            id="name"
                            placeholder="Nombre"
                            type="text"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.name && touched.name
                                ? 'text-input error'
                                : 'text-input'
                            }
                          />
                          {errors.name && touched.name && (
                            <div className="input-feedback">{errors.name}</div>
                          )}
                        </FormContent>

                        <FormContent>
                          <Label
                            htmlFor="businessName"
                            style={{ display: 'block' }}
                          >
                            Razón social
                          </Label>
                          <Input
                            fullWidth
                            id="businessName"
                            placeholder="Razón social"
                            type="text"
                            value={values.businessName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.businessName && touched.businessName
                                ? 'text-input error'
                                : 'text-input'
                            }
                          />
                          {errors.businessName && touched.businessName && (
                            <div className="input-feedback">
                              {errors.businessName}
                            </div>
                          )}
                        </FormContent>

                        <FormContent>
                          <Label htmlFor="nif" style={{ display: 'block' }}>
                            NIF
                          </Label>
                          <Input
                            fullWidth
                            id="nif"
                            placeholder="NIF"
                            type="text"
                            value={values.nif}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.nif && touched.nif
                                ? 'text-input error'
                                : 'text-input'
                            }
                          />
                          {errors.nif && touched.nif && (
                            <div className="input-feedback">{errors.nif}</div>
                          )}
                        </FormContent>

                        <FormContent flexValue={1.4}>
                          <Label htmlFor="email" style={{ display: 'block' }}>
                            Email
                          </Label>
                          <Input
                            fullWidth
                            id="email"
                            placeholder="Email"
                            type="text"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.email && touched.email
                                ? 'text-input error'
                                : 'text-input'
                            }
                          />
                          {errors.email && touched.email && (
                            <div className="input-feedback">{errors.email}</div>
                          )}
                        </FormContent>

                      </FormRow>

                      <FormRow marginR={60}>
                        <FormContent flexValue={1.6}>
                          <Label
                            htmlFor="direction"
                            style={{ display: 'block' }}
                          >
                            Dirección
                          </Label>
                          <Input
                            fullWidth
                            id="direction"
                            placeholder="Dirección"
                            type="text"
                            value={values.direction}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.direction && touched.direction
                                ? 'text-input error'
                                : 'text-input'
                            }
                          />
                          {errors.direction && touched.direction && (
                            <div className="input-feedback">
                              {errors.direction}
                            </div>
                          )}
                        </FormContent>
                        <FormContent>
                          <Label htmlFor="phone" style={{ display: 'block' }}>
                            Teléfono
                          </Label>
                          <Input
                            fullWidth
                            id="phone"
                            placeholder="Teléfono"
                            type="text"
                            value={values.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.phone && touched.phone
                                ? 'text-input error'
                                : 'text-input'
                            }
                          />
                          {errors.phone && touched.phone && (
                            <div className="input-feedback">{errors.phone}</div>
                          )}
                        </FormContent>
                        <FormContent flexValue={1.6}>
                          <Label
                            htmlFor="billingContact"
                            style={{ display: 'block' }}
                          >
                            Contacto de facturación
                          </Label>
                          <Input
                            fullWidth
                            id="billingContact"
                            placeholder="Contacto facturación"
                            type="text"
                            value={values.billingContact}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.billingContact && touched.billingContact
                                ? 'text-input error'
                                : 'text-input'
                            }
                          />
                          {errors.billingContact && touched.billingContact && (
                            <div className="input-feedback">
                              {errors.billingContact}
                            </div>
                          )}
                        </FormContent>
                      </FormRow>

                      <FormRow />
                      <FormRow>
                        <FormContent flexEnd>
                          <Button
                            width="160px"
                            type="button"
                            primary
                            disabled={isSubmitting}
                            onClick={handleSubmit}
                          >
                            Actualizar
                          </Button>
                        </FormContent>
                      </FormRow>
                    </Form>
                  </>
                );
              }}
            </Formik>
            <TableName title="Agentes" />
            <Table
              page={page}
              order={order}
              orderBy={orderBy}
              rowsPerPage={12}
              fields={[
                {
                  key: 'name',
                  label: 'Nombre',
                  format: 'default',
                },
                {
                  key: 'phoneNumber',
                  label: 'Teléfono',
                  format: 'phone',
                },
                {
                  key: 'id',
                  label: 'ID',
                  format: 'number',
                  color: '#ABABB8',
                },
                {
                  key: 'actions',
                  label: 'Acciones',
                  format: 'embed',
                },
              ]}
              data={accountAgents.map((agent, index) => ({
                key: index,
                id: agent.identifier,
                name: agent.name,
                phoneNumber: agent.phone,
                actions: [
                  // {
                  //   icon: (
                  //     <img
                  //       alt="config-icon"
                  //       src={ConfigImg}
                  //       width="18px"
                  //       height="18px"
                  //     />
                  //   ),
                  //   color: 'green',
                  //   text: 'Configurar',
                  //   handler: () => history.push(`/agents/${agent.id}`),
                  // },
                  // {
                  //   icon: (
                  //     <img
                  //       alt="trash-icon"
                  //       src={TrashImg}
                  //       width="13px"
                  //       height="15px"
                  //     />
                  //   ),
                  //   color: 'red',
                  //   text: 'Eliminar',
                  //   handler: () =>
                  //     removeAccountAgent({ id, agentId: agent.id }),
                  // },
                ],
              }))}
              onChangeField={(key, value) => {
                this.setState(prevState => ({
                  ...prevState,
                  table: {
                    ...prevState.table,
                    [key]: value,
                  },
                }));
              }}
            />
          </Container>
        </DetailContainer>
      </SectionContainer>
    );
  }
}

AccountsConfigureScreen.propTypes = {
  values: PropType.shape({}),
  touched: PropType.shape({}),
  errors: PropType.shape({}),
  account: PropType.shape(),
  match: PropType.shape({}).isRequired,
  history: PropType.shape({}).isRequired,
  fetchAccountById: PropType.func.isRequired,
  fetchAccountAgents: PropType.func.isRequired,
  fetchAgents: PropType.func.isRequired,
  addAccountAgent: PropType.func.isRequired,
  updateAccount: PropType.func.isRequired,
  agents: PropType.arrayOf(PropType.shape({})),
  accountAgents: PropType.arrayOf(PropType.shape({})),
  // removeAccountAgent: PropType.func.isRequired,
  errorMessage: PropType.string,
};

AccountsConfigureScreen.defaultProps = {
  values: {},
  touched: {},
  errors: {},
  account: {},
  agents: [],
  accountAgents: [],
  errorMessage: null,
};

export default AccountsConfigureScreen;
