import styled from 'styled-components';

export const LoginFormColumn = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: #f8fbfb;
  min-height: 100vh;
`;
