import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { FormRow, Button } from '../../../theme/styles';
import Logo from '../../../components/Logo';
import IntroText from '../../../components/IntroText';
import Input from '../../../components/Input';
import FormContainer from '../../../components/FormContainer';
import Loader from '../../../components/Loader';
import { GoBackLink, GoBackContainer } from '../ResetPasswordForm/styles';

const validationSchema = Yup.object().shape({
  password: Yup.string().required('Campo obligatorio'),
  passwordRepeat: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')
    .required('Campo obligatorio'),
});

const SetPasswordForm = ({
  success,
  userId,
  isLoading,
  setPassword,
  history,
  goBack,
  isSubmitting,
}) => (
  <Formik
    initialValues={{ password: '', passwordRepeat: '' }}
    validationSchema={validationSchema}
    onSubmit={(values, { setSubmitting }) => {
      setPassword({ userId, data: values.password });
      setSubmitting(false);
    }}
  >
    {({ values, handleBlur, handleChange, handleSubmit }) => (
      <FormContainer onSubmit={handleSubmit}>
        <Logo />
        {success && (
          <FormRow>
            <IntroText>
              La contraseña ha sido establecida correctamente.
            </IntroText>
          </FormRow>
        )}
        {!success && (
          <>
            <FormRow>
              <IntroText>
                Introduce y confirma la nueva contraseña
                <br />
                para acceder a tu cuenta
              </IntroText>
            </FormRow>
            <FormRow>
              <Input
                type="password"
                name="password"
                placeholder="Nueva contraseña"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                noBorderRadiusBottom
              />
            </FormRow>
            <FormRow>
              <Input
                type="password"
                name="passwordRepeat"
                noBorderRadiusTop
                placeholder="Repetir nueva contraseña"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.passwordRepeat}
              />
            </FormRow>
            <FormRow margin="30px 0 10px">
              <Button
                type="submit"
                primary
                width="180px"
                disabled={isSubmitting || isLoading}
              >
                {isLoading ? (
                  <Loader color="#fff" size="18px" />
                ) : (
                  <span>Establecer contraseña</span>
                )}
              </Button>
            </FormRow>
          </>
        )}
        <GoBackContainer>
          <GoBackLink
            onClick={() => {
              goBack();
              history.push('/');
            }}
          >
            Volver al login
          </GoBackLink>
        </GoBackContainer>
      </FormContainer>
    )}
  </Formik>
);

SetPasswordForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  setPassword: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(SetPasswordForm);
