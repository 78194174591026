// Modules
import { connect } from 'react-redux';

// Screen Layout
import View from './view';

// Accounts
import {
  deleteAccount,
  fetchAccountClients,
} from '../../modules/accounts/actions';

// Modal
import { createModal } from '../../modules/ui/actions';

// Redux Connector
export default connect(
  state => ({
    accounts: state.accounts.accountClients,
  }),
  {
    // Accounts
    fetchAccountClients,
    deleteAccount,

    // Modal
    createModal,
  }
)(View);
