import { handleActions } from 'redux-actions';

import { FETCH_POPULATIONS_SUCCESS } from './actionTypes';

const defaultState = {
  list: [],
};

export default handleActions(
  {
    [FETCH_POPULATIONS_SUCCESS]: (state, { payload }) => ({
      ...state,
      list: payload,
    }),
  },
  defaultState
);
