import { connect } from 'react-redux';

import LoginForm from './view';

// actions
import { login } from '../../../modules/auth/actions';

export default connect(
  state => ({
    status: state.auth.status,
    isLoading: state.auth.isLoading,
    isSubmitting: state.ui.isSubmitting,
  }),
  {
    login,
  }
)(LoginForm);
