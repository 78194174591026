/* eslint-disable */
const config = require('./default');
const { ENVIRONMENT } = require('./env.json');

let envConfig = {};
try {
  envConfig = require(`./${ENVIRONMENT}.json`);
} catch (e) {
  console.error(`error while loading config: ${e}`);
  envConfig = require('./dev.json');
}

let firebaseConfig = {};
try {
  firebaseConfig = require(`./firebase/${ENVIRONMENT}.json`);
} catch (e) {
  console.error(`error while loading config: ${e}`);
  firebaseConfig = require('./firebase/dev.json');
}

export default {
  ...config,
  ...envConfig,
  FIREBASE: firebaseConfig,
};
/* eslint-enable */
