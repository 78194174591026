import { createActions } from 'redux-actions';

import {
  CREATE_INTEGRATION,
  CREATE_INTEGRATION_SUCCESS,
  CREATE_INTEGRATION_FAILED,
  FETCH_INTEGRATION,
  FETCH_INTEGRATION_SUCCESS,
  FETCH_INTEGRATION_FAILED,
  EDIT_INTEGRATION,
  EDIT_INTEGRATION_SUCCESS,
  EDIT_INTEGRATION_FAILED,
  SET_SUBMITTED,
} from './actionTypes';

export const {
  createIntegration,
  createIntegrationSuccess,
  createIntegrationFailed,
  fetchIntegration,
  fetchIntegrationSuccess,
  fetchIntegrationFailed,
  editIntegration,
  editIntegrationSuccess,
  editIntegrationFailed,
  setSubmitted,
} = createActions(
  CREATE_INTEGRATION,
  CREATE_INTEGRATION_SUCCESS,
  CREATE_INTEGRATION_FAILED,
  FETCH_INTEGRATION,
  FETCH_INTEGRATION_SUCCESS,
  FETCH_INTEGRATION_FAILED,
  EDIT_INTEGRATION,
  EDIT_INTEGRATION_SUCCESS,
  EDIT_INTEGRATION_FAILED,
  SET_SUBMITTED
);
