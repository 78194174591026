import { createActions } from 'redux-actions';
import {
  FETCH_DESTINATIONS,
  FETCH_DESTINATIONS_SUCCESS,
  FETCH_DESTINATIONS_FAILED,
  FETCH_DESTINATION_BY_ID,
  FETCH_DESTINATION_BY_ID_SUCCESS,
  FETCH_DESTINATION_BY_ID_FAILED,
  CREATE_DESTINATION,
  CREATE_DESTINATION_SUCCESS,
  CREATE_DESTINATION_FAILED,
  UPDATE_DESTINATION,
  UPDATE_DESTINATION_SUCCESS,
  UPDATE_DESTINATION_FAILED,
  DELETE_DESTINATION,
  DELETE_DESTINATION_SUCCESS,
  DELETE_DESTINATION_FAILED,
  DELETE_DESTINATION_BY_ID,
} from './actionTypes';

export const {
  fetchDestinations,
  fetchDestinationsSuccess,
  fetchDestinationsFailed,
  fetchDestinationById,
  fetchDestinationByIdSuccess,
  fetchDestinationByIdFailed,
  createDestination,
  createDestinationSuccess,
  createDestinationFailed,
  updateDestination,
  updateDestinationSuccess,
  updateDestinationFailed,
  deleteDestination,
  deleteDestinationSuccess,
  deleteDestinationFailed,
  deleteDestinationById,
} = createActions(
  FETCH_DESTINATIONS,
  FETCH_DESTINATIONS_SUCCESS,
  FETCH_DESTINATIONS_FAILED,
  FETCH_DESTINATION_BY_ID,
  FETCH_DESTINATION_BY_ID_SUCCESS,
  FETCH_DESTINATION_BY_ID_FAILED,
  CREATE_DESTINATION,
  CREATE_DESTINATION_SUCCESS,
  CREATE_DESTINATION_FAILED,
  UPDATE_DESTINATION,
  UPDATE_DESTINATION_SUCCESS,
  UPDATE_DESTINATION_FAILED,
  DELETE_DESTINATION,
  DELETE_DESTINATION_SUCCESS,
  DELETE_DESTINATION_FAILED,
  DELETE_DESTINATION_BY_ID
);
