import { connect } from 'react-redux';
import {
  fetchDestinations,
  deleteDestination,
  deleteDestinationById,
} from '../../modules/destinations/actions';

import { createModal } from '../../modules/ui/actions';

import View from './view';

export default connect(
  state => ({
    agent: state.agents.agentById,
    destinations: state.destinations.list,
  }),
  { fetchDestinations, deleteDestinationById, createModal }
)(View);
