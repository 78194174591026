import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AudioForm from '../../components/AudioForm';

import { Container, Button } from '../../theme/styles';
import Headline from '../../components/Headline';
import Table from '../../components/TableV2';
import DeleteModal from '../../components/DeleteModal';

import TrashImg from './img/trash-can-outline.svg';

class AudiosScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      table: {
        page: 1,
        order: 'asc',
        orderBy: 'name',
      },
    };
  }

  componentDidMount() {
    const {
      fetchAgentAudios,
      match: {
        params: { id },
      },
    } = this.props;
    fetchAgentAudios({ id });
  }

  createAudio = id => {
    const {
      createModal,
      agent: { name, phone },
      me: { role },
    } = this.props;
    createModal({
      title: 'Audio Nuevo',
      width: 400,
      body: (
        <AudioForm
          agentId={id}
          agentName={name}
          agentPhone={phone}
          role={role}
        />
      ),
    });
  };

  render() {
    const {
      me,
      deleteAudio,
      createModal,
      audios,
      history,
      match: {
        params: { id },
      },
    } = this.props;
    const {
      table: { page, order, orderBy },
    } = this.state;
    return (
      <Container>
        <Headline
          title="Audios"
          actions={
            <div>
              <Button
                secondary
                style={{ marginRight: '8px' }}
                onClick={() => history.push('/agents')}
              >
                Volver a la lista de agentes
              </Button>
              <Button
                disabled={me.role !== 'SUPERADMIN' && me.role !== 'ADMIN'}
                primary
                onClick={() => this.createAudio(id)}
              >
                Nuevo Audio
              </Button>
            </div>
          }
        />
        <Table
          page={page}
          order={order}
          orderBy={orderBy}
          rowsPerPage={12}
          fields={[
            {
              key: 'group',
              label: 'Grupo',
              format: 'default',
            },
            {
              key: 'id',
              label: 'Identificador',
              format: 'default',
              color: '#ABABB8',
            },
            {
              key: 'name',
              label: 'Nombre',
              format: 'default',
              color: '#ABABB8',
            },
            {
              key: 'audio',
              label: 'Archivo',
              format: 'embed',
              color: '#ABABB8',
            },
            {
              key: 'actions',
              label: 'Acciones',
              format: 'embed',
            },
          ]}
          data={audios.map((agentAudio, index) => ({
            key: index,
            group: agentAudio.group,
            id: agentAudio.identifier,
            name: agentAudio.name,
            audio: agentAudio.audio,
            actions: [
              // {
              //   icon: <img alt="config-icon" src={ConfigImg} width="18px" height="18px" />,
              //   color: 'green',
              //   text: 'Editar',
              //   handler: () => history.push(`/audios/${audio.id}`),
              // },
              {
                disabled: me.accountType !== 'MASTER',
                icon: (
                  <img
                    alt="trash-icon"
                    src={TrashImg}
                    width="13px"
                    height="15px"
                  />
                ),
                color: 'red',
                text: 'Eliminar',
                handler: () =>
                  createModal({
                    title: 'Eliminar Audio',
                    width: 480,
                    body: (
                      <DeleteModal
                        item={{
                          id: agentAudio.id,
                          agentId: agentAudio.agentId,
                        }}
                        title="¿Estas seguro que deseas eliminar este Audio?"
                        removeText="Eliminar Audio"
                        deleteAction={deleteAudio}
                      />
                    ),
                  }),
              },
            ],
          }))}
          onChangeField={(key, value) => {
            this.setState(prevState => ({
              ...prevState,
              table: {
                ...prevState.table,
                [key]: value,
              },
            }));
          }}
        />
      </Container>
    );
  }
}

AudiosScreen.propTypes = {
  me: PropTypes.shape({
    accountType: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({}).isRequired,
  fetchAgentAudios: PropTypes.func.isRequired,
  deleteAudio: PropTypes.func.isRequired,
  audios: PropTypes.arrayOf(PropTypes.shape({})),
  history: PropTypes.shape({}).isRequired,
  createModal: PropTypes.func.isRequired,
};

AudiosScreen.defaultProps = {
  audios: [],
};

export default AudiosScreen;
