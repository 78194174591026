import { takeLatest, put, call, select } from 'redux-saga/effects';

import {
  FETCH_AGENTS,
  CREATE_AGENT,
  DELETE_AGENT,
  FETCH_AGENT_BY_ID,
  FETCH_AGENT_AUDIOS,
  FETCH_AGENT_REASONS,
  FETCH_AGENT_BY_PHONE,
} from './actionTypes';
import {
  fetchAgents as getAgents,
  setAgents,
  setAgentById,
  fetchAgentAudiosSuccess,
  fetchAgentReasonsSuccess,
  createAgentFailed,
  createAgentSuccess,
  fetchAgentByPhoneSuccess,
  fetchAgentByPhoneFailed,
} from './actions';

import { fetchAccountAgents } from '../accounts/actions';

import * as api from './api';
import { deleteModal, setIsSubmitting } from '../ui/actions';

function* fetchAgents() {
  try {
    const response = yield call(api.fetchAgents);
    yield put(setAgents({ agents: response.data.data }));
  } catch (e) {
    console.error({ e });
  }
}

export function* watchFetchAgents() {
  yield takeLatest(FETCH_AGENTS, fetchAgents);
}

function* fetchAgentById({ payload: { id } }) {
  try {
    const response = yield call(api.fetchAgentById, id);
    yield put(setAgentById({ agent: response.data }));
  } catch (e) {
    console.error({ e });
  }
}
export function* watchFetchAgentById() {
  yield takeLatest(FETCH_AGENT_BY_ID, fetchAgentById);
}

function* fetchAgentByPhone({ payload: { phone } }) {
  try {
    const response = yield call(api.fetchAgentByPhone, phone);
    // const responseAssignedAgents = yield call(api.fetchAssignedAgents);
    yield put(fetchAgentByPhoneSuccess({ agent: response.data }));
  } catch (e) {
    console.error({ e });
    yield put(fetchAgentByPhoneFailed({ e }));
  }
}

export function* watchFetchAgentByPhone() {
  yield takeLatest(FETCH_AGENT_BY_PHONE, fetchAgentByPhone);
}

function* fetchAgentAudios({ payload: { id } }) {
  try {
    const response = yield call(api.fetchAgentAudios, id);
    yield put(fetchAgentAudiosSuccess(response.data));
  } catch (e) {
    console.error({ e });
  }
}

function* fetchAgentReasons({ payload: { id } }) {
  try {
    const response = yield call(api.fetchAgentReasons, id);
    yield put(fetchAgentReasonsSuccess(response.data));
  } catch (e) {
    console.error({ e });
  }
}

export function* watchFetchAgentAudios() {
  yield takeLatest(FETCH_AGENT_AUDIOS, fetchAgentAudios);
}

export function* watchFetchAgentReasons() {
  yield takeLatest(FETCH_AGENT_REASONS, fetchAgentReasons);
}

function* createAgent({ payload }) {
  try {
    yield put(setIsSubmitting(true));
    const response = yield call(api.createAgent, payload.agent);
    if (response && response.data) {
      yield put(getAgents());
      yield put(createAgentSuccess());
      yield put(deleteModal());
      yield put(setIsSubmitting(false));
      const { role } = yield select(state => state.agents);
      yield call(api.createAudio, {
        agentName: payload.agent.name,
        accountRole: role,
      });
    }
  } catch (e) {
    yield put(setIsSubmitting(false));
    console.error({ e });
    let messageError = 'Ha ocurrido un error inesperado. Vuelva a intentarlo';
    if (
      e &&
      e.response &&
      e.response.data === 'There is already other agent with this identifier'
    ) {
      messageError = 'Ya existe un agente con ese identificador';
    }
    yield put(createAgentFailed({ error: messageError }));
  }
}

export function* watchCreateAgent() {
  yield takeLatest(CREATE_AGENT, createAgent);
}

function* deleteAgent({ payload: { agent } }) {
  try {
    const { item } = agent;
    const response = yield call(api.deleteAgent, item.id);
    if (response && response.status === 200) {
      yield put(fetchAccountAgents({ id: item.account }));
      yield put(getAgents());
    }
  } catch (e) {
    console.error({ e });
  }
}

export function* watchDeleteAgent() {
  yield takeLatest(DELETE_AGENT, deleteAgent);
}
