import { takeLatest, put, call } from 'redux-saga/effects';

import {
  FETCH_DESTINATIONS,
  FETCH_DESTINATION_BY_ID,
  CREATE_DESTINATION,
  DELETE_DESTINATION_BY_ID,
  UPDATE_DESTINATION,
} from './actionTypes';
import {
  fetchDestinations as getDestinations,
  fetchDestinationsFailed,
  fetchDestinationsSuccess,
  fetchDestinationByIdSuccess,
  fetchDestinationByIdFailed,
  createDestinationSuccess,
  deleteDestinationFailed,
  deleteDestinationSuccess,
  createDestinationFailed,
  updateDestinationFailed,
  updateDestinationSuccess,
} from './actions';

import * as api from './api';
import { deleteModal, setIsSubmitting } from '../ui/actions';

function* fetchDestinations({ payload: { phone } }) {
  try {
    const response = yield call(api.fetchDestinations, phone);
    yield put(fetchDestinationsSuccess(response.data));
  } catch (e) {
    console.error({ e });
    yield put(fetchDestinationsFailed());
  }
}

export function* watchFetchDestinations() {
  yield takeLatest(FETCH_DESTINATIONS, fetchDestinations);
}

function* fetchDestinationById({ payload }) {
  try {
    const response = yield call(api.fetchDestinationById, payload.id);
    yield put(fetchDestinationByIdSuccess(response.data));
  } catch (e) {
    console.error(e);
    yield put(fetchDestinationByIdFailed());
  }
}

export function* watchFetchDestinationById() {
  yield takeLatest(FETCH_DESTINATION_BY_ID, fetchDestinationById);
}

function* createDestination({ payload }) {
  try {
    yield put(setIsSubmitting(true));
    yield call(api.createDestination, payload.data);
    yield put(createDestinationSuccess());
    yield put(deleteModal());
    yield put(getDestinations({ phone: payload.data.agent }));
    yield put(setIsSubmitting(false));
  } catch (e) {
    yield put(setIsSubmitting(false));
    console.error(e);
    yield put(createDestinationFailed());
  }
}

export function* watchCreateDestination() {
  yield takeLatest(CREATE_DESTINATION, createDestination);
}

function* updateDestination({ payload }) {
  try {
    yield call(api.updateDestinationById, payload.id, payload.data);
    yield put(updateDestinationSuccess());
    yield put(deleteModal());
    yield put(getDestinations());
  } catch (e) {
    console.error(e);
    yield put(updateDestinationFailed());
  }
}

export function* watchUpdateDestination() {
  yield takeLatest(UPDATE_DESTINATION, updateDestination);
}

function* deleteDestinationById({ payload }) {
  try {
    yield call(api.deleteDestinationById, payload.item);
    yield put(deleteDestinationSuccess());
    yield put(getDestinations({ phone: payload.phone }));
  } catch (e) {
    console.error(e);
    yield put(deleteDestinationFailed());
  }
}

export function* watchDeleteDestinationById() {
  yield takeLatest(DELETE_DESTINATION_BY_ID, deleteDestinationById);
}
