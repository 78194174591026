// Modules
import { takeLatest } from 'redux-saga/effects';

// ActionTypes
import { CREATE_MODAL, EDIT_MODAL, DELETE_MODAL } from './actionTypes';

// Actions
import { createModal, editModal, deleteModal } from './actions';

// Watchers
export function* watchCreateModal() {
  yield takeLatest(CREATE_MODAL, createModal);
}

export function* watcheEditModal() {
  yield takeLatest(EDIT_MODAL, editModal);
}

export function* watchDeleteModal() {
  yield takeLatest(DELETE_MODAL, deleteModal);
}
